import { withStateHandlers, withHandlers, compose } from 'recompose';

export const withCollapsibleMenuHandlers = compose(
	withStateHandlers(
		{ menuExpanded: false },
		{
			collapseMenu: () => () => ({ menuExpanded: false }),
			toggleMenu: ({ menuExpanded }) => () => ({ menuExpanded: !menuExpanded })
		}
	),
	withHandlers(({ collapseMenu }) => ({
		onClickOutside: () => (ref) => (evt) => {
			if (!ref || !ref.contains(evt.target)) {
				collapseMenu();
			}
		}
	}))
);

export default withCollapsibleMenuHandlers;
