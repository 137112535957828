/* eslint-disable max-len */
import React from 'react';

// TODO: replace with MovingTruck
export default function TruckRental() {
	return (
		<svg width="25" height="20" viewBox="0 0 25 20" xmlns="http://www.w3.org/2000/svg" className="svg">
			<title>
				Truck Rental
			</title>
			<g fill="#000" fillRule="evenodd">
				<path d="M23.93 16.786h-1.073c-.043 0-.084.006-.124.016-.287-.96-1.176-1.66-2.227-1.66-1.045 0-1.93.693-2.222 1.644H16.43V5.356h3.927c.406 0 .783.337.86.77l1.045 5.58c.025.132.098.25.207.33l1.46 1.09v3.66zm-3.424 1.698c-.56 0-1.017-.456-1.017-1.018s.455-1.017 1.016-1.017c.562 0 1.018.454 1.018 1.016s-.456 1.018-1.018 1.018zm-5.15-1.698H7.93c-.162-1.372-1.33-2.44-2.743-2.44-1.414 0-2.58 1.068-2.742 2.44H1.07V1.07h12.216c1.2 0 2.07.948 2.07 2.25v13.466zM5.187 18.32c-.667 0-1.208-.543-1.208-1.21 0-.67.54-1.21 1.21-1.21.667 0 1.208.54 1.208 1.21 0 .667-.54 1.21-1.21 1.21zm19.598-5.892l-1.507-1.126-1.007-5.366c-.164-.94-.987-1.65-1.913-1.65H16.43V3.32C16.43 1.43 15.077 0 13.285 0H.536C.24 0 0 .24 0 .536V17.32c0 .297.24.537.536.537H2.5l.03-.003c.325 1.162 1.392 2.018 2.657 2.018 1.264 0 2.33-.855 2.657-2.015H18.218c.186 1.096 1.14 1.934 2.288 1.934 1.15 0 2.106-.84 2.29-1.94.02.003.04.007.06.007h1.608c.296 0 .536-.24.536-.536v-4.463c0-.17-.08-.328-.216-.43z" />
				<path d="M18.214 10.357V7.5h1.18c.195 0 .363.14.398.333l.46 2.524h-2.038zm2.632-2.716c-.127-.7-.738-1.21-1.453-1.21H17.68c-.297 0-.537.24-.537.534v3.93c0 .295.24.535.536.535h3.213c.158 0 .31-.072.41-.194.103-.122.146-.283.117-.44l-.574-3.155z" />
			</g>
		</svg>
	);
}
