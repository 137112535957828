import { get } from 'lodash';
import { setExperimentsBucket } from '../experiment/actions';

export default (store) => (next) => (action = {}) => {
	const { user } = store.getState();
	const experiments = get(action, 'payload.experiments') || get(action, 'payload.result.experiments');
	if (experiments && user.visitId !== 'SELENIUM') {
		store.dispatch(setExperimentsBucket(experiments));
	}

	return next(action);
};
