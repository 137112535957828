import { API } from 'store/middleware/api';
import { unitSchema } from 'store/schema/units';
import { facilitySchema } from 'store/schema/facilities';
import formatFacility from 'utils/store/formatFacility';
import { formatFacilityResponse } from 'utils/store/formatSearchFacilities';

export const FACILITIES = {
	REQUEST: 'SFDC/FACILITIES/REQUEST',
	SUCCESS: 'SFDC/FACILITIES/SUCCESS',
	FAILURE: 'SFDC/FACILITIES/FAILURE',
	BY_ID_REQUEST: 'SFDC/FACILITIES/BY_ID_REQUEST',
	BY_ID_SUCCESS: 'SFDC/FACILITIES/BY_ID_SUCCESS',
	BY_ID_FAILURE: 'SFDC/FACILITIES/BY_ID_FAILURE',
	BY_EMAIL_TOKEN_REQUEST: 'SFDC/FACILITIES/BY_EMAIL_TOKEN_REQUEST',
	BY_EMAIL_TOKEN_SUCCESS: 'SFDC/FACILITIES/BY_EMAIL_TOKEN_SUCCESS',
	BY_EMAIL_TOKEN_FAILURE: 'SFDC/FACILITIES/BY_EMAIL_TOKEN_FAILURE',
	FACILITY_PAGE_REQUEST: 'SFDC/FACILITIES/FACILITY_PAGE_REQUEST',
	FACILITY_PAGE_SUCCESS: 'SFDC/FACILITIES/FACILITY_PAGE_SUCCESS',
	FACILITY_PAGE_FAILURE: 'SFDC/FACILITIES/FACILITY_PAGE_FAILURE',
	FACILITY_HOURS_DIRECTION_REQUEST: 'SFDC/FACILITIES/FACILITY_HOURS_DIRECTION_REQUEST',
	FACILITY_HOURS_DIRECTION_SUCCESS: 'SFDC/FACILITIES/FACILITY_HOURS_DIRECTION_SUCCESS',
	FACILITY_HOURS_DIRECTION_FAILURE: 'SFDC/FACILITIES/FACILITY_HOURS_DIRECTION_FAILURE'
};

export function getFacilityByEmailToken(token) {
	return {
		[API]: {
			types: [
				FACILITIES.BY_EMAIL_TOKEN_REQUEST,
				FACILITIES.BY_EMAIL_TOKEN_SUCCESS,
				FACILITIES.BY_EMAIL_TOKEN_FAILURE
			],
			endpoint: `/facility/emailToken/${token}`,
			schema: { facility: facilitySchema },
			adapter: (payload, state) => ({
				...payload,
				facility: payload.facility && formatFacility(payload.facility, state)
			})
		}
	};
}

export function getFacilityPage(facilityId, city, state) {
	return {
		[API]: {
			types: [
				FACILITIES.FACILITY_PAGE_REQUEST,
				FACILITIES.FACILITY_PAGE_SUCCESS,
				FACILITIES.FACILITY_PAGE_FAILURE
			],
			endpoint: `/page/facility/${facilityId}`,
			adapter: formatFacilityResponse,
			schema: { facility: facilitySchema, units: [unitSchema] },
			meta: { city, state }
		}
	};
}

export function getFacilityHoursAndDirection(data) {
	return {
		[API]: {
			method: 'POST',
			types: [
				FACILITIES.FACILITY_HOURS_DIRECTION_REQUEST,
				FACILITIES.FACILITY_HOURS_DIRECTION_SUCCESS,
				FACILITIES.FACILITY_HOURS_DIRECTION_FAILURE
			],
			endpoint: '/consumer/hours-and-directions',
			data
		}
	};
}
