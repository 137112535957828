/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ChevronDown.scss';

export default function ChevronDown({ reverse }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="10"
			viewBox="0 0 15 10"
			className={classnames('svg chevron-down', { reverse })}
		>
			<path d="M7.5 10a1.5 1.5 0 0 1-1.14-.52l-6-7a1.5 1.5 0 1 1 2.28-2L7.5 6.2 12.36.52a1.5 1.5 0 1 1 2.28 2l-6 7A1.5 1.5 0 0 1 7.5 10z" />
		</svg>
	);
}


ChevronDown.propTypes = {
	reverse: PropTypes.bool
};
