import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Row.scss';

export default function Row({ children, className, reverse }) {
	return (
		<div className={classnames('page-row', className, { reverse })}>
			{children}
		</div>
	);
}

Row.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	reverse: PropTypes.bool
};
