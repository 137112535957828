import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text, Paragraph } from 'components/core/Typography';
import holidayMessaging from 'config/holidayMessaging';

import './holidayBanner.scss';

function HolidayBanner({ holiday }) {
	return (
		<div className={classnames('holiday-banner', `theme-${holidayMessaging[holiday].primaryColor}`)}>
			<Paragraph className="holiday-message" color="white" weight="standard" last>
				{holidayMessaging[holiday].message}
				{' '}
				<Text className="message" color="white" size="smaller" weight="standard" last>
					Search for the best priced storage and make a free reservation in minutes.
				</Text>
			</Paragraph>
		</div>
	);
}

HolidayBanner.propTypes = {
	holiday: PropTypes.string
};

export default HolidayBanner;
