import React from 'react';

import { CoreDay } from 'components/core/library/calendar';

import './day.scss';

export default function Day(props) {
	return (
		<CoreDay {...props} />
	);
}
