import { createAction } from 'redux-actions';
import { get, pick } from 'lodash';
import { PageTypes } from '@sparefoot/segment-react';
import { searchResponseSchema } from 'store/schema/search';
import formatSearchFacilities from 'utils/store/formatSearchFacilities';
import { API } from 'store/middleware/api';
import { allowedSearchParams } from 'lib/search';

export const SEARCH = {
	REQUEST: 'SFDC/SEARCH/SEARCH_REQUEST',
	SUCCESS: 'SFDC/SEARCH/SEARCH_SUCCESS',
	FAILURE: 'SFDC/SEARCH/SEARCH_FAILURE',
	DEALS_REQUEST: 'SFDC/SEARCH/SEARCH_REQUEST',
	DEALS_SUCCESS: 'SFDC/SEARCH/SEARCH_SUCCESS',
	DEALS_FAILURE: 'SFDC/SEARCH/SEARCH_FAILURE',
	HIGHLIGHT_FACILITY: 'SFDC/SEARCH/HIGHLIGHT_FACILITY',
	PAGE_REQUEST: 'SFDC/SEARCH/SEARCH_PAGE_REQUEST',
	PAGE_SUCCESS: 'SFDC/SEARCH/SEARCH_PAGE_SUCCESS',
	PAGE_FAILURE: 'SFDC/SEARCH/SEARCH_PAGE_FAILURE',
	SEARCH_LANDING_REQUEST: 'SFDC/SEARCH/SEARCH_LANDING_REQUEST',
	SEARCH_LANDING_SUCCESS: 'SFDC/SEARCH/SEARCH_LANDING_SUCCESS',
	SEARCH_LANDING_FAILURE: 'SFDC/SEARCH/SEARCH_LANDING_FAILURE',
	STORAGE_DEALS_REQUEST: 'SFDC/PAGES/STORAGE_DEALS_REQUEST',
	STORAGE_DEALS_SUCCESS: 'SFDC/PAGES/STORAGE_DEALS_SUCCESS',
	STORAGE_DEALS_FAILURE: 'SFDC/PAGES/STORAGE_DEALS_FAILURE',
	MAP_REDO_SEARCH: 'SFDC/SEARCH/MAP_REDO_SEARCH'
};

export const highlightFacility = createAction(
	SEARCH.HIGHLIGHT_FACILITY,
	(facilityId) => ({ facilityId })
);
export const clearHighlightedFacility = createAction(
	SEARCH.HIGHLIGHT_FACILITY,
	() => ({ facilityId: null })
);

export const mapRedoSearchAction = createAction(
	SEARCH.MAP_REDO_SEARCH,
	(mapRedoSearch) => ({ mapRedoSearch })
);

export function getSearchPage(query) {
	const params = pick(query, allowedSearchParams);

	return {
		[API]: {
			types: [
				SEARCH.PAGE_REQUEST,
				SEARCH.PAGE_SUCCESS,
				SEARCH.PAGE_FAILURE
			],
			endpoint: '/page/search',
			adapter: formatSearchFacilities,
			schema: searchResponseSchema,
			query: params
		}
	};
}

export function getSearchLandingPage(query, meta = {}) {
	return {
		[API]: {
			types: [
				SEARCH.SEARCH_LANDING_REQUEST,
				SEARCH.SEARCH_LANDING_SUCCESS,
				SEARCH.SEARCH_LANDING_FAILURE
			],
			endpoint: '/page/searchLanding',
			adapter: formatSearchFacilities,
			schema: searchResponseSchema,
			meta,
			query: {
				...pick(query, allowedSearchParams)
			}
		}
	};
}

export function getCityLandingPage(params, query) {
	return getSearchLandingPage(
		{
			city: params.city || '',
			state: params.state || '',
			location: `${params.city || ''}, ${params.state || ''}`,
			subPageType: PageTypes.CITY_LANDING,
			...query
		},
		{ pageType: PageTypes.CITY_LANDING }
	);
}

export function getVehicleLandingPage(params, query) {
	return getSearchLandingPage(
		{
			city: params.city || '',
			state: params.state || '',
			location: `${params.city || ''}, ${params.state || ''}`,
			searchType: 'vehicle',
			vehicle: params.vehicle,
			subPageType: PageTypes.VEHICLE_LANDING,
			...query
		},
		{ pageType: PageTypes.VEHICLE_LANDING }
	);
}

export function getAmenityLandingPage(params, query) {
	return getSearchLandingPage(
		{
			city: params.city || '',
			state: params.state || '',
			location: `${params.city || ''}, ${params.state || ''}`,
			amenity: params.amenity,
			subPageType: PageTypes.AMENITY_LANDING,
			...query
		},
		{ pageType: PageTypes.AMENITY_LANDING }
	);
}

export function getNeighborhoodLandingPage(params, query) {
	return getSearchLandingPage(
		{
			city: params.city,
			state: params.state,
			location: `${params.neighborhood || ''}, ${params.city || ''}, ${
				params.state || ''
			}`,
			neighborhood: params.neighborhood || '',
			subPageType: PageTypes.NEIGHBORHOOD_LANDING,
			...query
		},
		{ pageType: PageTypes.NEIGHBORHOOD_LANDING }
	);
}

export function getStorageDealsPage(params) {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					SEARCH.STORAGE_DEALS_REQUEST,
					SEARCH.STORAGE_DEALS_SUCCESS,
					SEARCH.STORAGE_DEALS_FAILURE
				],
				endpoint: '/page/storageDealsLanding',
				query: {
					searchType: 'storage-only',
					ip: get(user, 'user_ip'),
					...params
				},
				meta: {
					page: 'storage',
					baseURL: get(app, 'baseUrl', '')
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema
			}
		});
	};
}

export function fetchStorageDealsResults(query) {
	return (dispatch, getState) => {
		const { app } = getState();
		return dispatch({
			[API]: {
				types: [
					SEARCH.DEALS_REQUEST,
					SEARCH.DEALS_SUCCESS,
					SEARCH.DEALS_FAILURE
				],
				endpoint: '/search/storage-deals',
				query: pick(query, allowedSearchParams),
				meta: {
					baseURL: get(app, 'baseUrl', '')
				}
			}
		});
	};
}

export function fetchSearchResults(query) {
	return (dispatch, getState) => {
		const { app } = getState();

		return dispatch({
			[API]: {
				types: [SEARCH.REQUEST, SEARCH.SUCCESS, SEARCH.FAILURE],
				endpoint: '/search',
				query: pick(query, allowedSearchParams),
				meta: {
					baseURL: get(app, 'baseUrl', '')
				}
			}
		});
	};
}

export function fetchSearchPageResults(query) {
	return {
		[API]: {
			types: [SEARCH.REQUEST, SEARCH.SUCCESS, SEARCH.FAILURE],
			endpoint: '/page/search',
			query: pick(query, allowedSearchParams)
		}
	};
}

export function getFiveByFiveLandingPage(params, query) {
	const response = getSearchLandingPage(
		{
			city: params.city || '',
			state: params.state || '',
			location: `${params.city || ''}, ${params.state || ''}`,
			landingPageSize: '5x5',
			subPageType: 'city_5x5_landing_page',
			...query
		},
		{ pageType: 'city_5x5_landing_page' }
	);

	return response;
}

export function getTenByTenLandingPage(params, query) {
	const response = getSearchLandingPage(
		{
			city: params.city || '',
			state: params.state || '',
			location: `${params.city || ''}, ${params.state || ''}`,
			landingPageSize: '10x10',
			subPageType: 'city_10x10_landing_page',
			...query
		},
		{ pageType: 'city_10x10_landing_page' }
	);

	return response;
}
