import PropTypes from 'prop-types';
import React, { Children } from 'react';
import classnames from 'classnames';
import { CoreText } from 'components/core/library/text';
import './text.scss';

/**
 * Styled text component. Allows wrapping text in a span with class names for strong, light, tiny, and small.
 */
export default function Text({
	className,
	size,
	color,
	strong,
	light,
	strikethrough,
	family,
	onClick,
	children
}) {
	const componentClassName = classnames(
		className,
		size,
		color,
		{
			strong,
			light: !color && light,
			strikethrough
		},
		(family ? `family-${family}` : ''),
	);

	return (
		<CoreText className={componentClassName} onClick={onClick}>
			{Children.toArray(children)}
		</CoreText>
	);
}

Text.propTypes = {
	/** Makes the text bold */
	strong: PropTypes.bool,
	/** Renders light gray text instead of black -- DEPRECATED: use color="gray" */
	light: PropTypes.bool,
	/** Renders white text instead of black. options: `gray`, `white`, `yellow`, `light-gray` */
	color: PropTypes.oneOf(['gray', 'white', 'yellow', 'green', 'light-gray']),
	/** Boolean flag for the smallest font size. Either `tiny`, `small`, `medium` or `large` can be provided */
	size: PropTypes.oneOf(['tiny', 'small', 'medium', 'standard', 'large', 'extra-large', 'huge', 'giant']),
	family: PropTypes.oneOf(['standard', 'rounded']),
	/** Boolean flag for if the text should be displayed with a strikethrough */
	strikethrough: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func
};

Text.defaultProps = {
	strong: false,
	light: false,
	strikethrough: false,
	family: 'standard'
};
