/* eslint-disable max-len */
import React from 'react';

export default function ChevronRight() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" viewBox="0 0 8 17" className="svg chevron-right">
			<path
				fillRule="evenodd"
				d="M7.446 6.46L3.917 1.0115C3.1853-.1184 1.8155-.3344.8577.5293c-.9578.8635-1.141 2.48-.4088 3.61l2.541 3.9238-2.573 4.1812c-.7084 1.151-.492 2.7617.4836 3.5975.3874.3317.8362.4916 1.2808.4916.675 0 1.3408-.3685 1.768-1.0624l3.5286-5.734c.567-.9212.5542-2.1724-.032-3.077"
			/>
		</svg>
	);
}
