import { createSelector } from 'reselect';
import { get, filter, concat } from 'lodash';

import { getScarcityFlag } from 'utils/store/getScarcityFlag';
import { isFacilityInactive } from 'utils/facility';
import { PageTypes } from '@sparefoot/segment-react';

// assumes all parking spaces are outdoor
// always false if it's a storage type
export const isOutside = (unit) => (unit.unitType === 'outdoor' || unit.unitType === 'parking_space');

const vehiclesPermitted = (unit) => (get(unit, 'vehicleDetails.permitted', null) === true);
const allowsThingsBesidesVehicles = (unit) => (get(unit, 'vehicleDetails.only', null) === false);

// is outside or allows vehicles
// vehicle storage has to be outdoor, or a parking space OR needs to permit vehicles
export const isVehicle = (unit) => (isOutside(unit) || vehiclesPermitted(unit));

// is inside and allows things beyond vehicles
// storage units can't be outdoor or parking spaces and have to allow things besides vehicles
// some storage units (basically gargages) also allow vehicles -- but they're enclosed
export const isSelfStorageUnit = (unit) => !isOutside(unit) && allowsThingsBesidesVehicles(unit);


export const mapFacilityWithUnits = (facilities, units) => (facilityId, index) => {
	const facility = facilities[facilityId];
	const facilityUnits = facility.units.map((unit) => units[unit]);
	return {
		...facility,
		index: index + 1,
		position: index + 1,
		units: facilityUnits
	};
};


export const getNearbyFacilities = createSelector(
	(state, props) => get(props, 'facilityIds', []),
	(state) => state.facilities.byId,
	(state) => state.units.byId,
	(nearbyFacilityIds, facilities, units) => ({
		nearbyFacilities: nearbyFacilityIds.map(mapFacilityWithUnits(facilities, units))
	})
);


export const getFacilityIdFromRoute = createSelector(
	(state, props) => get(props, 'match.params.facilityId'),
	(facilityId) => facilityId
);

export const getReviewPageQueryParam = createSelector(
	(state, props) => get(props, 'queryParams.reviewPage', 1),
	(reviewPage) => parseInt(reviewPage)
);

export const getFullFacilityUrl = createSelector(
	getFacilityIdFromRoute,
	(state) => state.facilities.byId,
	(state) => state.app.baseUrl,
	(facilityId, facilities, baseUrl) => {
		const facilityUrl = get(facilities[facilityId], 'url.facility');
		return facilityUrl ? `${baseUrl}${facilityUrl}` : null;
	}
);

export const getFacilityUnitsFromRoute = createSelector(
	getFacilityIdFromRoute,
	(state) => state.facilities.byId,
	(state) => state.units.byId,
	(facilityId, facilities, units) => (
		facilities[facilityId] && facilities[facilityId].units ?
			facilities[facilityId].units.map((unitId) => units[unitId]) :
			[]
	)
);

// OON selector gathers additional props for eCommerce events
export const oonFacilityPageSelector = createSelector(
	getFacilityIdFromRoute,
	(state) => state.facilities.byId,
	(facilityId, facilities) => {
		const facility = facilities[facilityId];
		const nearby = get(facility, 'nearby', {});
		const city = get(nearby, 'location.city');
		const state = get(nearby, 'location.state');
		return {
			page: 1,
			searchId: nearby.searchId,
			searchTerm: get(nearby, 'location.text'),
			searchLocation: get(nearby, 'location', {}),
			locationLabel: `${city}${state ? `, ${state}` : ''}`
		};
	}
);

export const facilityPageSelector = createSelector(
	getFacilityIdFromRoute,
	getFacilityUnitsFromRoute,
	getReviewPageQueryParam,
	getFullFacilityUrl,
	(state) => state.app,
	(state) => state.pageMeta.phone,
	(state) => state.facilities.byId,
	(state) => state.units.byId,
	(state) => state.pageMeta.tags,
	(facilityId, facilityUnits, reviewPage, fullFacilityUrl, app, phone, facilities, units, tags) => {
		const facility = facilities[facilityId];
		const { cloudfrontUrl, googleMapsKey } = app;
		const images = get(facility, 'images', []).map((image) => ({
			src: `${cloudfrontUrl}/large-compress/${image}`,
			alt: `${facility.name} - ${get(facility, 'location.streetAddress', '')}`
		}));

		const streetView = get(facility, 'location.streetView');
		if (streetView && streetView.active && googleMapsKey) {
			const image = streetView.image.replace(/\s/g, '');
			images.push({ src: `${image}&key=${googleMapsKey}` });
		}

		const nearbyFacilityIds = get(facility, 'nearby.facilities', []);
		const nearbyFacilities = nearbyFacilityIds.map(mapFacilityWithUnits(facilities, units));
		const nearbySearchId = get(facility, 'nearby.searchId');
		const vehicleUnits = filter(facilityUnits, isVehicle);
		const storageUnits = filter(facilityUnits, (unit) => isSelfStorageUnit(unit));
		const scarcityShow = getScarcityFlag(concat(vehicleUnits, storageUnits));
		const facilityIsInactive = isFacilityInactive(facility);
		const pageType = PageTypes.FACILITY;
		const hasOnlineMoveIns = get(facility, 'hasOnlineMoveins');

		return {
			facility,
			phone,
			images,
			nearbyFacilities,
			facilityIsInactive,
			reviewPage,
			fullFacilityUrl,
			vehicleUnits,
			storageUnits,
			nearbySearchId,
			scarcityShow,
			pageType,
			metaTitle: get(tags, 'title', ''),
			metaDescription: get(tags, 'meta.description', ''),
			canonicalUrl: get(tags, 'links[0].href', ''),
			hasOnlineMoveIns
		};
	}
);
