import { handleActions } from 'redux-actions';
import { USER } from './actions';

export const initialState = {
	siteId: null,
	visitId: null,
	traffic_source_id: null,
	cid: null,
	ctid: null,
	user_ip: null
};

export default handleActions({
	[USER.SAVE_GA_REQUEST]: (state, action) => ({
		...state,
		gaClientId: action.data.meta.value
	})
}, initialState);
