/* eslint-disable max-len */
import React from 'react';

export default function Plus() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 18 18" className="svg plus">
			<path d="M15.5,6.5h-4v-4a2.5,2.5,0,0,0-5,0v4h-4a2.5,2.5,0,0,0,0,5h4v4a2.5,2.5,0,0,0,5,0v-4h4a2.5,2.5,0,0,0,0-5Z" />
		</svg>
	);
}
