/* eslint-disable max-len */
import React from 'react';

export default function NoCredit() {
	return (
		<svg width="55" height="55" viewBox="0 0 55 55" xmlns="http://www.w3.org/2000/svg" className="svg no-credit">
			<g fill="none" fillRule="evenodd">
				<path opacity=".529" d="M-5-5h65v65H-5z" />
				<g transform="translate(1 1)" strokeWidth="2">
					<circle stroke="#000" cx="26.5" cy="26.5" r="26.5" />
					<g transform="translate(9 15)">
						<circle stroke="#000" cx="20.5" cy="12.5" r="3.5" />
						<circle stroke="#555" fill="#555" cx="14.5" cy="12.5" r="3.5" />
						<rect stroke="#000" x="1" y="1" width="33" height="22" rx="2" />
						<path stroke="#000" d="M1 5h33v1H1zM7 20h2v1H7zM5 13h1v1H5zM29 13h1v1h-1zM13 20h2v1h-2zM19 20h2v1h-2zM25 20h2v1h-2z" />
					</g>
					<path stroke="#000" d="M5.618 43.315l41.764-32.63.616.788-41.765 32.63z" />
				</g>
			</g>
		</svg>
	);
}
