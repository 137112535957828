/**
 * Flattens SFAPIGW responses with nested 'collection' field containing desired resource
 * Returns original payload if no collection is found
 * @param data
 * @returns object
 */

export const flattenCollection = (data = {}) => {
	if (data && data.collection) {
		return data.collection;
	}
	return data;
};

export default flattenCollection;
