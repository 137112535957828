import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CoreIcon } from '../library/icon';

import './sfIcon.scss';

export function SFIcon({
	icon, size, handleClick, className
}) {
	return (
		<CoreIcon
			className={classnames('ph-icon', `sf-${icon}`, size, className)}
			onClick={handleClick}
		/>
	);
}

SFIcon.propTypes = {
	/** The icon to be shown that is in the custom font */
	icon: PropTypes.oneOf([
		'chevron-down',
		'chevron-up',
		'chevron-right',
		'chevron-left',
		'facebook',
		'instagram',
		'pinterest',
		'twitter',
		'close',
		'phone',
		'circle',
		'circle-o',
		'vehicle-storage',
		'storage',
		'filter',
		'full-service-storage',
		'check',
		'coupon',
		'calendar',
		'clock',
		'phone-o',
		'checkmark',
		'car',
		'map-marker',
		'tooltip',
		'star-full',
		'star-half',
		'star-empty',
		'plus',
		'minus'
	]).isRequired,
	size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'extra-large', 'huge', 'giant']),
	className: PropTypes.string,
	handleClick: PropTypes.func
};
export default SFIcon;
