import { handleActions, combineActions } from 'redux-actions';
import { get, has, reduce, omit } from 'lodash';

import mergeEntities from 'utils/store/mergeEntities';

import { SEARCH } from 'store/search/actions';
import { PAGES } from 'store/pages/actions';
import { REVIEWS } from 'store/reviews/actions';
import { UNITS } from 'store/units/actions';
import { BOOKINGS } from 'store/bookings/actions';
import { FACILITIES } from 'store/facilities/actions';

export const initialState = {
	loading: false,
	error: false,
	errorMessage: null,
	faq: [],
	byId: {}
};

const successAction = combineActions(
	FACILITIES.SUCCESS,
	FACILITIES.FACILITY_PAGE_SUCCESS,
	SEARCH.SUCCESS,
	SEARCH.SEARCH_LANDING_SUCCESS,
	SEARCH.STORAGE_DEALS_SUCCESS,
	SEARCH.PAGE_SUCCESS,
	PAGES.BOOKING_PAGE_SUCCESS,
	PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS,
	BOOKINGS.RECENT_BOOKING_SUCCESS,
	BOOKINGS.FROM_CONFIRMATION_CODE_SUCCESS,
	UNITS.LOOKUP_SUCCESS,
	REVIEWS.GET_REVIEW_FACILITY_SUCCESS,
	PAGES.CAR_PSP_PAGE_SUCCESS,
	PAGES.STORAGE_PAGE_SUCCESS,
	PAGES.BOAT_PSP_PAGE_SUCCESS,
	PAGES.RV_PSP_PAGE_SUCCESS,
	PAGES.UNIT_PAGE_SUCCESS,
	PAGES.MOVING_TRUCK_PAGE_SUCCESS,
	PAGES.MOVING_TRUCK_CLP_SUCCESS
);

// TODO: move
export const isInactiveFacilityError = (error) => (
	error &&
		error.message === 'No Facility Available' &&
		error.data.context.reason === 'inactive'
);

export function removeFacilityServiceAreas(facilities) {
	return reduce(
		facilities,
		(allFacilities, facility, key) => ({ ...allFacilities, [key]: omit(facility, ['serviceArea']) }),
		{}
	);
}

const reducer = handleActions({
	[successAction]: (state, action) => {
		if (has(action, 'payload.entities.facilities')) {
			let facilities = get(action, 'payload.entities.facilities');
			const result = {
				...state,
				faq: get(action, 'payload.result.faq', [])
			};

			if (get(action, 'payload.result.search')) {
				const search = action.payload.entities.search[action.payload.result.search];
				facilities = removeFacilityServiceAreas(action.payload.entities.facilities);
				const storageCities = get(action, 'payload.result.storageCities');

				if (storageCities && search.searchType === 'storage-only') {
					result.storageCities = storageCities;
				}
			}
			result.byId = mergeEntities(state.byId, facilities);

			return result;
		}
		return state;
	},

	[FACILITIES.FACILITY_PAGE_FAILURE]: (state, { error }) => {
		if (isInactiveFacilityError(error)) {
			const context = get(error, 'data.context', {});
			return {
				...state,
				byId: {
					...state.byId,
					[context.facilityId]: {
						isInactive: true,
						location: context.location
					}
				}
			};
		}
		return state;
	},

	// Inject the best review id into each facility
	[REVIEWS.BEST_REVIEWS_SUCCESS]: (state, action) => ({
		...state,
		byId: {
			...state.byId,
			...reduce(action.payload.entities.reviews, (updatedFacilities, review) => ({
				...updatedFacilities,
				[review.facilityId]: {
					...state.byId[review.facilityId],
					bestReviewId: review.id
				}
			}), {})
		}
	})
}, initialState);

export default reducer;
