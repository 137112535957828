import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentContext } from 'components/core/library/segment';
import FooterColumn from './FooterColumn';

const links = [
	{ href: 'http://marketing.sparefoot.com', text: 'Market your facility' },
	{ href: 'https://my.sparefoot.com', rel: 'nofollow', text: 'Owner login' }
];

const enhance = withSegmentContext;

export function ForOwners({ className }) {
	return (
		<FooterColumn
			className={className}
			links={links}
			title="For facility owners"
		/>
	);
}

ForOwners.propTypes = {
	className: PropTypes.string
};

export default enhance(ForOwners);
