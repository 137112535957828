import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withSegmentInputTracking } from 'components/core/library/segment';


/**
 * Core input box component. This component has [Segment](#segment-tracking) input tracking built in.
 *
 * **Base CSS Class:** `ph-input`
 */

const enhance = withSegmentInputTracking;

export function CoreInput(props) {
	return (
		<input
			className={classnames('ph-input', props.className)}
			disabled={props.disabled}
			id={props.id}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			onChange={props.onChange}
			name={props.name}
			placeholder={props.placeholder}
			type={props.type}
			value={props.value}
			ref={props.getRef}
		/>
	);
}

CoreInput.propTypes = {
	/** The value for the input box content */
	value: PropTypes.string,
	/** Function will be called with the ref of the final <input> element */
	getRef: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,
	name: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string
};

export default enhance(CoreInput);
