/* eslint-disable prefer-destructuring */
import reduxListener from './reduxListener';
import { PAGE_META } from '../pageMeta/actions';

let pageType = null;
// TODO: refactor for SPA
const pageTypeReduxListener = reduxListener(PAGE_META.SET_PAGE_TYPE, (action) => {
	pageType = action.payload;
});

const trackMiddleware = (props) => {
	if (pageType && props.category && props.category.indexOf(`-${pageType}`) < 0 && props.category !== 'phlagTest') {
		return {
			...props,
			category: `${props.category}-${pageType}`
		};
	}
	return props;
};


const pageTypeSegmentMiddleware = ({ getState }) => {
	pageType = getState().pageMeta.pageType;
	return trackMiddleware;
};

export {
	pageTypeReduxListener,
	pageTypeSegmentMiddleware
};
