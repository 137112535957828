import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import isAfter from 'date-fns/is_after';
import isSameDay from 'date-fns/is_same_day';
import isSameMonth from 'date-fns/is_same_month';
import { find, isUndefined } from 'lodash';
import CoreDay from './day';

const Today = new Date();

function isInPast(date) {
	return !isSameDay(date, Today) && isAfter(Today, date);
}

export default class CoreCalendarWeek extends PureComponent {
	getDays() {
		const days = [];
		for (let i = 0; i < 7; i += 1) {
			const date = addDays(this.props.start, i);
			days.push(
				React.createElement(
					this.props.dayComponent,
					{
						key: format(date, 'YYMD'),
						date,
						closed: this.isClosed(date),
						today: isSameDay(date, Today),
						past: !this.props.allValid && isInPast(date),
						open: this.isOpen(date),
						invalid: this.isInvalid(date),
						onDateClick: this.props.onDateClick,
						notInCurrentMonth: !isSameMonth(this.props.currentMonth, date)
					}
				)
			);
		}
		return days;
	}

	isClosed(date) {
		if (this.props.allValid) {
			return false;
		}
		return !isInPast(date) && !!find(this.props.closures, (closure) => (isSameDay(date, closure)));
	}

	isOpen(date) {
		const closed = !this.isClosed(date);
		const after = !isInPast(date);
		const validWindow = (isUndefined(this.props.resWindow)) || isAfter(this.props.resWindow, date);
		return this.props.allValid || (closed && after && validWindow);
	}

	isInvalid(date) {
		if (this.props.allValid) {
			return false;
		}
		return (!isUndefined(this.props.resWindow)) && isAfter(date, this.props.resWindow);
	}

	render() {
		return (
			<tr className="ph-calendar-week">
				{this.getDays()}
			</tr>
		);
	}
}

CoreCalendarWeek.propTypes = {
	dayComponent: PropTypes.func,
	closures: PropTypes.arrayOf(PropTypes.any),
	currentMonth: PropTypes.instanceOf(Date),
	onDateClick: PropTypes.func,
	resWindow: PropTypes.instanceOf(Date),
	start: PropTypes.instanceOf(Date),
	allValid: PropTypes.bool
};

CoreCalendarWeek.defaultProps = {
	dayComponent: CoreDay,
	closures: []
};
