/* eslint-disable max-len */
import React from 'react';

export default function Pinterest() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="244" height="244" viewBox="0 0 243.98 243.98" className="svg pinterest">
			<title>Pinterest</title>
			<path fill="#ffcd05" d="M122 0a122 122 0 0 0-44.47 235.59c-1.07-9.65-2-24.46.42-35C80.17 191.08 92.26 140 92.26 140a44 44 0 0 1-3.65-18.11c0-17 9.83-29.63 22.07-29.63 10.41 0 15.44 7.81 15.44 17.18 0 10.47-6.66 26.12-10.1 40.62-2.87 12.14 6.09 22 18.07 22 21.68 0 38.35-22.86 38.35-55.86 0-29.21-21-49.63-51-49.63-34.71 0-55.08 26-55.08 52.94 0 10.48 4 21.73 9.08 27.84a3.65 3.65 0 0 1 .85 3.5c-.93 3.85-3 12.14-3.39 13.83-.53 2.23-1.77 2.71-4.08 1.63-15.21-7.08-24.73-29.31-24.73-47.25 0-38.48 28-73.82 80.6-73.82 42.32 0 75.21 30.16 75.21 70.46 0 42-26.51 75.88-63.3 75.88-12.36 0-24-6.42-28-14 0 0-6.12 23.29-7.6 29-2.75 10.6-10.19 23.88-15.16 32A122 122 0 1 0 122 0z" />
		</svg>
	);
}
