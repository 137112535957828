import { handleActions } from 'redux-actions';
import { omit } from 'lodash';
import { FILE_UPLOADS } from './actions';

export const initialState = {
	uploadProgress: {},
	uploadUrls: {}
};

export default handleActions({
	[FILE_UPLOADS.UPLOAD_PROGRESS]: (state, action) => ({
		...state,
		uploadProgress: {
			...state.uploadProgress,
			[action.payload.fileName]: action.payload.uploadProgress
		}
	}),
	[FILE_UPLOADS.SET_UPLOAD_URL]: (state, action) => ({
		...state,
		uploadUrls: {
			...state.uploadUrls,
			[action.payload.fileName]: action.payload.uploadUrl
		},
		documentKeys: {
			...state.documentKeys,
			[action.payload.fileName]: action.payload.key
		}
	}),
	[FILE_UPLOADS.REMOVE_FILE]: (state, action) => ({
		uploadProgress: {
			...omit(state.uploadProgress, action.payload)
		},
		uploadUrls: {
			...omit(state.uploadUrls, action.payload)
		},
		documentKeys: {
			...omit(state.documentKeys, action.payload)
		}
	})
}, initialState);
