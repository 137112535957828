/* eslint-disable max-len */
import React from 'react';

export default function Filters() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className="svg filters">
			<g data-name="Layer 1">
				<path d="M3 2V1a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM13 2V1a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM8 9V1a1 1 0 0 0-2 0v8a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zM1 13a1 1 0 0 0 2 0V5H1zM6 13a1 1 0 0 0 2 0v-1H6zM11 13a1 1 0 0 0 2 0V5h-2z" />
			</g>
		</svg>
	);
}
