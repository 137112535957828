import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { range } from 'lodash';
import { compose, withProps, defaultProps, withStateHandlers, withHandlers } from 'recompose';

import { Label } from 'components/core/Label';
import StarButton from './StarButton';

import './StarInput.scss';


export const enhance = compose(
	defaultProps({ totalStars: 5 }),
	withProps((props) => {
		const numElements = props.halfStep ? props.totalStars * 2 : props.totalStars;
		return {
			stars: range(1, numElements + 1).map((index) => (props.halfStep ? index / 2 : index))
		};
	}),
	withHandlers({
		onSelect: (props) => (value) => props.input.onChange(`${value}`)
	}),
	withStateHandlers(
		(props) => ({ activeRating: parseFloat(props.input.value) }),
		{
			onMouseEnter: () => (value) => ({ activeRating: value }),
			onMouseLeave: (state, props) => () => ({ activeRating: parseFloat(props.input.value) })
		}
	)
);

export function StarInput({
	activeRating, classsName, halfStep, stars, onMouseEnter, onMouseLeave, onSelect, label, size, segmentLabel, input
}) {
	return (
		<Label
			text={label}
			htmlFor={input.id || input.name}
			className={classnames('star-input', size, classsName)}
			textAfterChildren={false}
		>
			<div className="input-container">
				{Children.toArray(stars.map((value) => (
					<StarButton
						className={
							classnames(
								size,
								{
									half: halfStep,
									active: activeRating >= value
								}
							)
						}
						segmentLabel={segmentLabel}
						segmentProperties={{
							rating: value
						}}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						onClick={onSelect}
						value={value}
					/>
				)))}
			</div>
		</Label>
	);
}

StarInput.propTypes = {
	activeRating: PropTypes.number,
	classsName: PropTypes.string,
	halfStep: PropTypes.bool,
	stars: PropTypes.arrayOf(PropTypes.number),
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onSelect: PropTypes.func,
	label: PropTypes.string,
	size: PropTypes.string,
	segmentLabel: PropTypes.string,
	input: PropTypes.object
};

export default enhance(StarInput);
