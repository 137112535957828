/* eslint-disable max-len */
import React from 'react';

export default function PenskeRental() {
	return (
		<svg width="34" height="37" viewBox="0 0 34 37" xmlns="http://www.w3.org/2000/svg" className="svg">
			<title>
				Penske Icon
			</title>
			<g fill="none" fillRule="evenodd">
				<path d="M19.71 30.1l1.052.375c.928.35.415 1.05.415 1.05l-.318.625c-.318.575-2.004.5-2.004.5-2.906 0-2.27-1-2.27-1l.292-.6h1.954l-.27.525s-.12.25.246.25c0 0 .146.025.22-.1l.195-.375s.195-.275-.147-.4l-1.148-.4c-.806-.275-.44-.8-.44-.8l.343-.675c.367-.7 2.1-.7 2.1-.7 2.908 0 2.273 1 2.273 1l-.196.4h-1.954l.17-.35s.148-.25-.243-.225c0 0-.17.025-.244.15l-.17.325s-.22.3.145.425zm6.132 2.4h-1.855l-.22-1.2-.32.225-.487.975h-1.98l1.98-4h1.978l-.61 1.25 1.587-1.25h1.832l-2.32 1.65.415 2.35zm4.543-1.75H28.87l-.512 1.025h1.514l-.342.725h-3.468l1.978-4h3.444l-.342.75h-1.514l-.39.775h1.513l-.365.725zM2.81 32.5h1.855l.586-1.2h1.833c.49 0 .758-.275.93-.575l.756-1.55c.023-.05.023-.1.023-.15 0-.3-.317-.525-.708-.525H4.787l-1.978 4zm3.505-3.35h.182c.17 0 .27 0 .244.125 0 .025 0 .05-.023.1l-.513 1.05c-.073.125-.17.225-.366.225h-.27l.747-1.5zm9.635-.65h1.734l-1.978 4H13.85v-1.7l-.83 1.7h-1.613l1.978-4h1.808v1.525l.757-1.525zm-4.128 2.25h-1.514l-.513 1.025h1.514l-.367.725H7.5l1.977-4h3.444l-.34.75h-1.515l-.39.775h1.513l-.366.725zm19.662 4.6H2.517c-.514 0-.93-.425-.93-.95v-7.8c0-.525.416-.95.93-.95h28.967c.513 0 .928.425.928.95v7.825c0 .5-.415.925-.928.925zm0-11.35H2.516C1.124 24 0 25.175 0 26.6v7.8C0 35.825 1.124 37 2.516 37h28.968C32.877 37 34 35.85 34 34.425V26.6c0-1.425-1.123-2.6-2.516-2.6z" fill="#231F20" />
				<g fill="#000">
					<path d="M26.886 17.625H25.77c-.043 0-.086.007-.128.017-.298-1.007-1.223-1.743-2.315-1.743-1.087 0-2.008.727-2.312 1.725h-1.93v-12h4.086c.424 0 .816.352.895.81l1.088 5.856c.025.14.102.264.214.348l1.52 1.144v3.843zm-3.56 1.783c-.584 0-1.058-.478-1.058-1.068 0-.59.474-1.07 1.06-1.07.583 0 1.057.48 1.057 1.07 0 .59-.474 1.068-1.058 1.068zm-5.355-1.783h-7.724c-.167-1.44-1.38-2.56-2.852-2.56-1.47 0-2.684 1.12-2.85 2.56h-1.43v-16.5h12.703c1.25 0 2.154.993 2.154 2.362v14.138zm-10.576 1.61c-.694 0-1.257-.57-1.257-1.27 0-.7.563-1.27 1.257-1.27.695 0 1.258.57 1.258 1.27 0 .7-.563 1.27-1.258 1.27zm20.382-6.186l-1.568-1.182-1.047-5.635c-.17-.988-1.025-1.733-1.99-1.733h-4.084V3.487C19.086 1.5 17.68 0 15.816 0H2.557C2.25 0 2 .252 2 .563v17.625c0 .31.25.562.557.562H4.6l.03-.003c.34 1.22 1.45 2.118 2.764 2.118 1.315 0 2.423-.897 2.764-2.115h10.789c.194 1.15 1.186 2.03 2.38 2.03 1.197 0 2.19-.883 2.382-2.037.02.003.042.007.063.007h1.673c.307 0 .557-.252.557-.563V13.5c0-.178-.083-.345-.224-.45z" />
					<path d="M20.943 10.875v-3h1.226c.203 0 .377.147.414.35l.477 2.65h-2.117zm2.737-2.85c-.133-.74-.768-1.275-1.51-1.275h-1.784c-.308 0-.557.252-.557.563v4.125c0 .31.248.562.556.562h3.343c.163 0 .32-.074.426-.202.106-.128.15-.297.12-.46l-.596-3.314z" />
				</g>
			</g>
		</svg>
	);
}
