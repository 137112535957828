import { reduxForm, SubmissionError } from 'redux-form';
import { branch, compose, renderComponent, withState, withHandlers } from 'recompose';
import { SuccessMessage } from 'components/form';
import { withSegmentContext } from 'components/core/library/segment';

// On Submission replaces the form with a success message or thows an error to the form
export default (config = {}) => compose(
	withSegmentContext,
	withState('successMessage', 'setSuccessMessage', null),
	withHandlers({
		onSubmit: (props) => (formData) => props.onSubmit(formData)
		.then((response) => {
			if (response.error) {
				throw new SubmissionError({ _error: response.error.message });
			} else {
				props.setSuccessMessage(response.payload.message);
				return response;
			}
		})
	}),
	reduxForm(config),
	branch((props) => props.submitSucceeded, renderComponent(SuccessMessage))
);
