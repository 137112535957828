/* eslint-disable max-len */
import React from 'react';

export default function PointerArrow() {
	return (
		<svg width="11" height="11" xmlns="http://www.w3.org/2000/svg" className="svg pointer-arrow">
			<path d="M.488.482l10.07 3.353-3.025 1.592 2.685 3.662-1.186 1.178-2.888-2.885-2.415 2.847L.488.482z" stroke="#FFF" strokeWidth="1.2" fill="none" fillRule="evenodd" strokeLinejoin="round" />
		</svg>
	);
}
