import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


function CoreIcon(props) {
	return (
		<i {...props} className={classnames('ph-icon', props.className)} />
	);
}

CoreIcon.propTypes = {
	className: PropTypes.string
};

export default CoreIcon;
