import Wkt from 'wicket/wicket';
import { omit, get, reduce, isArray, union } from 'lodash';
import { schema } from 'normalizr';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import getTime from 'date-fns/get_time';
import isBefore from 'date-fns/is_before';
import parse from 'date-fns/parse';
import unitSchema from './units';

// Deep maps all numeric pairs to lat lng object
function mapCoords(coordinate) {
	return isArray(coordinate[0]) ? coordinate.map(mapCoords) : ({ lng: coordinate[0], lat: coordinate[1] });
}

export function getOfficeClosureArray(facility, today) {
	// Get the max res window or 2 weeks, whichever is greater.
	const twoWeeks = getTime(addDays(today, 14));
	const maxDateTimestamp = reduce(facility.units, (maxBookingDate, unit) => {
		const unitTimestamp = (get(unit, 'maxBookingDate.format.timestamp') || 0) * 1000;
		return unitTimestamp > maxBookingDate ? unitTimestamp : maxBookingDate;
	}, twoWeeks);

	const maxDate = parse(maxDateTimestamp);
	const officeClosures = [];

	// From now through the maxDate, check to see if the office is closed on the given day
	const officeHours = get(facility, 'hours.office.text') || {};
	let date = today;
	while (isBefore(date, maxDate)) {
		const day = format(date, 'dddd').toLowerCase();
		if (officeHours[day] === 'Closed') {
			officeClosures.push(format(date, 'YYYY-MM-DD'));
		}
		date = addDays(date, 1);
	}

	return officeClosures;
}

function getToday() {
	return Date.now();
}

export const facilitySchema = new schema.Entity(
	'facilities',
	{
		units: [unitSchema],
		recommendedUnit: unitSchema
	},
	{
		// Convert Service Areas into WKT
		processStrategy: (facility, result = {}) => {
			const omitFields = [];

			// Get dates where the facility is closed
			const facilityClosures = getOfficeClosureArray({
				...facility,
				units: facility.units || [result.unit]
			}, getToday());


			const closures = union(facility.closures, facilityClosures);

			const processedFacility = {
				...(omit(facility, omitFields)),
				closures,
				description: encodeURIComponent(facility.description)
			};

			if (!processedFacility.serviceArea) { return processedFacility; }

			const wicket = new Wkt.Wkt();
			try {
				wicket.read(processedFacility.serviceArea);
			} catch (e) {
				throw e;
			}

			const polygon = wicket.toJson();

			polygon.coordinates = polygon.coordinates.map(mapCoords);

			return {
				...processedFacility,
				serviceArea: polygon
			};
		}
	}
);

// sf_5198_oon_facility_page / Normalize any nearby facilities
facilitySchema.define({
	nearby: {
		facilities: [facilitySchema]
	}
});

export default facilitySchema;
