/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Phone({ size }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.9 16.06" width="12" height="16" className={classnames('svg', 'phone', size)}>
			<path d="M11.4 11.66l-2.15-2a1.53 1.53 0 0 0-1.69-.27l-.36.17a36.08 36.08 0 0 1-1.56-3.39L6 6a1.53 1.53 0 0 0 .89-1.46l-.12-2.92A1.57 1.57 0 0 0 5.52.14a4.6 4.6 0 0 0-2.77.15A4.38 4.38 0 0 0 0 4.22 10.7 10.7 0 0 0 1.46 10l.05.11v.06a14 14 0 0 0 3 4.43 5 5 0 0 0 .56.44 5 5 0 0 0 2.8 1 4.58 4.58 0 0 0 2-.48 4.59 4.59 0 0 0 1.91-2 1.57 1.57 0 0 0-.38-1.9zM9 13.76a2.44 2.44 0 0 1-2.8-.34 2.93 2.93 0 0 1-.33-.26 12.19 12.19 0 0 1-2.5-3.82l-.9.44.87-.5-.05-.11A9 9 0 0 1 2 4.46a2.44 2.44 0 0 1 1.59-2.35A2.31 2.31 0 0 1 4.78 2l.1 2.28-.45.21a1.57 1.57 0 0 0-.8 2 38.16 38.16 0 0 0 2 4.35 1.57 1.57 0 0 0 2 .68l.45-.21 1.67 1.55a2.31 2.31 0 0 1-.75.9z" />
		</svg>
	);
}

Phone.propTypes = {
	size: PropTypes.string
};
