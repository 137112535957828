import React, { Children } from 'react';
import { withSegmentContext } from 'components/core/library/segment';
import { Row, Column, Group } from 'components/core/Page';
import { Text, Paragraph } from 'components/core/Typography';
import { Link } from 'components/core/Link';
import FooterStates from './FooterStates';

const links = [
	{ href: '/Atlanta-GA-self-storage.html', text: 'Atlanta' },
	{ href: '/Austin-TX-self-storage.html', text: 'Austin' },
	{ href: '/Bronx-NY-self-storage.html', text: 'Bronx' },
	{ href: '/Brooklyn-NY-self-storage.html', text: 'Brooklyn' },
	{ href: '/Chicago-IL-self-storage.html', text: 'Chicago' },
	{ href: '/Columbus-OH-self-storage.html', text: 'Columbus' },
	{ href: '/Dallas-TX-self-storage.html', text: 'Dallas' },
	{ href: '/Denver-CO-self-storage.html', text: 'Denver' },
	{ href: '/Houston-TX-self-storage.html', text: 'Houston' },
	{ href: '/Jacksonville-FL-self-storage.html', text: 'Jacksonville' },
	{ href: '/Las-Vegas-NV-self-storage.html', text: 'Las Vegas' },
	{ href: '/Los-Angeles-CA-self-storage.html', text: 'Los Angeles' },
	{ href: '/Miami-FL-self-storage.html', text: 'Miami' },
	{ href: '/New-York-NY-self-storage.html', text: 'New York' },
	{ href: '/Orlando-FL-self-storage.html', text: 'Orlando' },
	{ href: '/Philadelphia-PA-self-storage.html', text: 'Philadelphia' },
	{ href: '/Phoenix-AZ-self-storage.html', text: 'Phoenix' },
	{ href: '/Queens-NY-self-storage.html', text: 'Queens' },
	{ href: '/Sacramento-CA-self-storage.html', text: 'Sacramento' },
	{ href: '/San-Antonio-TX-self-storage.html', text: 'San Antonio' },
	{ href: '/San-Diego-CA-self-storage.html', text: 'San Diego' },
	{ href: '/San-Francisco-CA-self-storage.html', text: 'San Francisco' },
	{ href: '/San-Jose-CA-self-storage.html', text: 'San Jose' },
	{ href: '/Tampa-FL-self-storage.html', text: 'Tampa' },
	{ href: '/Washington-DC-self-storage.html', text: 'Washington' }
];

const enhance = withSegmentContext;

export function PopularLocations() {
	return (
		<Group className="popular-locations">
			<Row>
				<Column
					xlarge={9}
					medium={12}
				>
					<Group>
						<Paragraph
							size="small"
							color="white"
						>
							Popular storage locations in the country
						</Paragraph>
						<ul className="popular-cities-links">
							{Children.toArray(links.map(({ href, text, rel }) => (
								<li className="popular-city">
									<Link href={href} rel={rel} color="white" segmentLabel={text}>
										<Text color="white" size="small">
											{text}
										</Text>
									</Link>
								</li>
							)))}
						</ul>
					</Group>
				</Column>
				<Column medium={12} xlarge={3} className="footer-states-container">
					<FooterStates
						className="footer-col"
						segmentPrefix="footer states"
					/>
				</Column>
			</Row>
		</Group>
	);
}

export default enhance(PopularLocations);
