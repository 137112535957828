import React from 'react';

export default function ThumbsUp() {
	return (
		<svg width="16" height="16" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg" className="svg thumbs-up">
			<g fillRule="evenodd">
				<path d="M19.939 9.339L16.304 16H9V7.887l2.98-5.316c.26-.465.817-.681 1.326-.516v6.538h6.184a.507.507 0 01.449.746zM2 16h5V9H2v7zm19.645-8.148a2.487 2.487 0 00-2.145-1.19h-4.38V.969l-.366-.29c-1.341-1.058-3.308-.856-4.393.453a3.054 3.054 0 00-.301.435L7.156 6.663H0V18h17.401l4.294-7.74a2.365 2.365 0 00-.05-2.408z" />
				<path d="M5 15a1 1 0 100-2 1 1 0 000 2" />
			</g>
		</svg>
	);
}
