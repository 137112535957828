import { get } from 'lodash';

/* eslint-disable import/prefer-default-export */

export function RequestError(error) {
	this.data = get(error, 'response.data', {});
	this.message = get(error, 'response.data.message', '');
	this.status = get(error, 'response.status', null);
}
/* eslint-enable */

/* eslint-disable new-parens */
RequestError.prototype = new Error;
/* eslint-enable */
RequestError.prototype.toJSON = function toJSON() {
	return {
		data: this.data,
		status: this.status,
		message: this.message
	};
};
