/* eslint-disable max-len */
import React from 'react';

export default function ReserveOnline() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 63" className="svg reserve-online">
			<path d="M72.2 0H3C1.1 0 0 2 0 4v45.5C0 51.4 1.1 53 3 53h21v3h-4.5c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h36.3c1.9 0 3.5-1.6 3.5-3.5S57.7 56 55.8 56H51v-3h21.2c2 0 3.7-1.5 3.8-3.5V4c-.1-2.1-1.7-3.8-3.8-4zM44 56H31v-3h13v3zm25-10H7V7h62v39z" />
			<path d="M32.1 35.8c1.9 0 3.5-1.6 3.5-3.5v-2.1l4.9 4.9c.7.7 1.6 1 2.5 1s1.8-.3 2.5-1c1.4-1.4 1.4-3.6 0-5l-4.9-4.9h1.6c1.9 0 3.5-1.6 3.5-3.5s-1.6-3.5-3.5-3.5H32.1c-.2 0-.4 0-.6.1-.1 0-.2.1-.3.1-.1 0-.2.1-.3.1-.1 0-.2.1-.3.2-.1 0-.2.1-.3.1-.2.1-.3.3-.5.4-.1.2-.3.3-.4.5-.1.1-.1.2-.2.3-.1.1-.1.2-.2.3 0 .1-.1.2-.1.4 0 .1-.1.2-.1.3 0 .2-.1.5-.1.7v10.5c-.1 2.1 1.5 3.6 3.4 3.6z" />
		</svg>
	);
}
