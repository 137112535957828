import { reduce } from 'lodash';
import flattenCollection from 'utils/store/flattenCollection';
import formatFacility from 'utils/store/formatFacility';

export default function formatTruckResponse(payload, state) {
	const results = reduce(['truckResults', 'penskeResults'], (acc, key) => {
		if (payload[key]) {
			return {
				...acc,
				[key]: {
					...payload[key].search,
					facilities: flattenCollection(payload[key].search.facilityCollection)
					.map((facility) => formatFacility(facility, state))
				}
			};
		}

		return acc;
	}, {});

	return {
		...payload,
		...results
	};
}
