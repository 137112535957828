import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Component for text.
 *
 * **Base CSS Class:** `ph-text`
 */

// TODO: remove text on clicks, use a button
export default function CoreText({ className, children }) {
	return (
		<span
			className={classnames(className, 'ph-text inner-accordion-safe')}
		>
			{Children.toArray(children)}
		</span>
	);
}

CoreText.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};
