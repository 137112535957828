import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from 'components/core/Typography';
import { Image } from 'components/core/Image';
import { Button } from 'components/core/Button';

import couponImage from 'assets/images/icons/icon-coupon.svg';

export default function DealsLink({ className }) {
	return (
		<Button
			href="/storage-deals.html"
			segmentLabel="page header deals link"
			className={classnames('deals', className)}
			link
			preContent={
				<Image src={couponImage} alt="Coupon Icon" className="coupon-icon" />
			}
		>
			<Text
				className="deals-text"
				color="black"
				weight="bold"
				last
			>
				Deals
			</Text>
		</Button>
	);
}

DealsLink.propTypes = {
	className: PropTypes.string
};
