import Segment from '@sparefoot/segment-react';
import { lifecycle, compose } from 'recompose';

export default function withSegmentPageViewTracking(page) {
	return compose(
		lifecycle({
			// Use props.pageType if the same page template is used for different page types, e.g. search/clp
			// props.pageType is set within the page's selector
			componentWillMount() {
				Segment.trackPageView(page || this.props.pageType);
			}
		})
	);
}
