import { withStateHandlers } from 'recompose';
import { upperFirst, isFunction } from 'lodash';

export default (name, initialState = false) => withStateHandlers((props) => ({
	[`${name}Showing`]: isFunction(initialState) ? initialState(props) : initialState
}), {
	[`onShow${upperFirst(name)}`]: () => () => ({ [`${name}Showing`]: true }),
	[`onHide${upperFirst(name)}`]: () => () => ({ [`${name}Showing`]: false }),
	[`onToggle${upperFirst(name)}`]: (state) => () => ({ [`${name}Showing`]: !state[`${name}Showing`] })
});
