import { createSelector } from 'reselect';
import Cookies from 'universal-cookie';
import { parseQueryParams } from 'utils/url';
import { includes, pick, get } from 'lodash';


export const getStorageCalcInfo = createSelector(
	(state) => state.app.storageCalculatorUrl,
	(state) => state.app,
	(storageCalculatorUrl) => {
		return storageCalculatorUrl;
	}
);

export const phoneSelector = createSelector(
	(state) => state.pageMeta.phone,
	(phone) => pick(phone, [
		'localSparephoneUnformatted',
		'localSparephone',
		'nationalSparephone',
		'nationalSparephoneUnformatted'
	])
);

export default createSelector(
	phoneSelector,
	(state) => state.app,
	(state) => state.pageMeta,
	(state, props) => props.location,
	(state) => state.experiments.bucket, // sf_12289_sfdc_holiday_messaging
	(phone, app, pageMeta, location, experimentsBucket) => {
		const queryParams = parseQueryParams(location.search);
		const cookies = new Cookies();
		const holidaysExperiment = get(experimentsBucket, 'sf_12289_sfdc_holiday_messaging') === 'variation';
		return {
			location,
			phone,
			tags: pageMeta.tags,
			isLandingPage: pageMeta.isLandingPage,
			statusCode: pageMeta.statusCode,
			redirectURL: pageMeta.redirectURL,
			noindex: pageMeta.noindex,
			sftest: includes(['on', 'active'], queryParams.sftest) || cookies.get('sftest') === 'on',
			holiday: holidaysExperiment && 'holidays' // sf_12289_sfdc_holiday_messaging
		};
	}
);
