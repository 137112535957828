import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/core/Typography';
import { Image } from 'components/core/Image';

import './iconText.scss';

export function IconText({
	className, textClassName, centerText, src, icon, children, size, color, type, weight, lazyLoad, alt, height, width
}) {
	return (
		<div className={classnames('icon-text', className, type, color, {
			'center-text': centerText
		})}
		>
			{!!src &&
				<Image src={src} lazyLoad={lazyLoad} alt={alt} width={width} height={height}/>
			}
			{!!icon && React.createElement(icon)}
			<div className={classnames(textClassName, 'text')}>
				<Text weight={weight} size={size} color={color} last>
					{children}
				</Text>
			</div>
		</div>
	);
}


IconText.propTypes = {
	className: PropTypes.string,
	textClassName: PropTypes.string,
	centerText: PropTypes.bool,
	children: PropTypes.node.isRequired,
	size: PropTypes.string,
	color: PropTypes.string,
	src: PropTypes.string,
	icon: PropTypes.func,
	type: PropTypes.oneOf(['alert', 'urgent', 'info', 'success', 'standard', 'dark', 'medical', 'inline-text']),
	weight: PropTypes.string,
	lazyLoad: PropTypes.bool,
	alt: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
};

IconText.defaultProps = {
	size: 'medium',
	type: 'standard',
	weight: 'bold'
};

export default IconText;
