import { handleActions, combineActions } from 'redux-actions';
import { get } from 'lodash';
import { SEARCH } from '../search/actions';
import { REVIEWS } from './actions';

export const initialState = {
	loading: false,
	error: false,
	errorMessage: null,
	reviewEmail: {}, // Review email object User is reviewing
	reviewFacility: null, // Facility ID User is reviewing
	byId: {} // Normalized Reviews
};

const SUCCESS = combineActions(SEARCH.SUCCESS, REVIEWS.BEST_REVIEWS_SUCCESS);

const reducer = handleActions({
	[SUCCESS]: (state, { payload }) => ({
		...state,
		byId: {
			...state.byId,
			...payload.entities.reviews
		}
	}),
	[REVIEWS.GET_REVIEW_FACILITY_SUCCESS]: (state, { payload }) => ({
		...state,
		reviewEmail: get(payload, 'result.email', {}),
		reviewFacility: get(payload, 'result.facility')
	})
}, initialState);


export default reducer;
