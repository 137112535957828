/* eslint-disable max-len */
import React from 'react';

export default function Calendar() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64" className="svg calendar">
			<defs>
				<style
					dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
						__html: '.cls-1,.cls-2{fill:none;stroke:#000;stroke-width:8px;}.cls-1{stroke-linecap:round;stroke-linejoin:round;}.cls-2{stroke-miterlimit:10;}'
					}}
				/>
			</defs>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_2-2" data-name="Layer 2">
					<rect className="cls-1" x="4" y="8" width="56" height="52" rx="6.2" ry="6.2" />
					<path className="cls-2" d="M7 24h50" />
					<path className="cls-1" d="M21 12V4m24 8V4" />
					<rect x="13" y="31" width="8" height="8" rx="1.18" ry="1.18" />
					<rect x="29" y="31" width="8" height="8" rx="1.18" ry="1.18" />
					<rect x="45" y="31" width="8" height="8" rx="1.18" ry="1.18" />
					<rect x="13" y="44" width="8" height="8" rx="1.18" ry="1.18" />
					<rect x="29" y="44" width="8" height="8" rx="1.18" ry="1.18" />
					<rect x="45" y="44" width="8" height="8" rx="1.18" ry="1.18" />
				</g>
			</g>
		</svg>
	);
}
