import qs, { parse } from 'querystring';
import { isUndefined, pickBy, merge } from 'lodash';

export const queryRegex = /\?([^#]*)/;

export function mergeUrlQueryParams(urlString = '', params) {
	if (!urlString) {
		return `?${qs.stringify(params)}`;
	}
	const queryMatch = urlString.match(queryRegex);
	let query = {};
	if (queryMatch) {
		query = qs.parse(queryMatch[1]);
	}

	query = pickBy(merge(query, params), (p) => !isUndefined(p));
	const newQuery = qs.stringify(query);

	if (queryMatch) {
		return urlString.replace(queryRegex, `?${newQuery}`);
	}

	return `${urlString}?${newQuery}`;
}

export function parseQueryParams(queryStr = '') {
	const paramStr = queryStr.substring(queryStr.indexOf('?') + 1);
	return parse(paramStr);
}

function cleanStringForUrl(str) {
	let cleanStr = str.replace(/[ ]+/g, '-');
	cleanStr = cleanStr.replace(/[^a-z0-9-]/ig, '');
	return cleanStr.replace(/-+/g, '-');
}

export function buildFacilityUrl(id, name, city, state) {
	if (!city || !state || !name) {
		return `/facility/${id}`;
	}

	// Example: /Austin-TX-self-storage/A-Mini-Storage-155246.html
	const cityState = `${cleanStringForUrl(`${city}-${state}`)}-self-storage`;
	const facilityStr = cleanStringForUrl(`${name}-${id}`);
	return `/${cityState}/${facilityStr}.html`;
}
