import reduce from 'lodash/reduce';

export const breakpoints = {
	0: 'small',
	576: 'medium',
	767: 'large',
	992: 'xlarge',
	1200: 'xxlarge',
	1600: 'xxxlarge'
};

export const breakpointsByName = reduce(
	breakpoints, (result, value, key) => ({ ...result, [value]: parseInt(key) }), {}
);
