export default function mergeEntities(bag = {}, entities = {}) {
	const newBag = { ...bag };

	Object.keys(entities).forEach((id) => {
		newBag[id] = {
			...newBag[id],
			...entities[id]
		};
	});

	return newBag;
}
