import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { clamp } from 'lodash';

import './Column.scss';

export default function Column({ className, children, medium, large, xlarge }) {
	return (
		<div className={
			classnames(
				className,
				'page-column',
				!!medium && `md-${clamp(medium, 1, 12)}`,
				!!large && `lg-${clamp(large, 1, 12)}`,
				!!xlarge && `xlg-${clamp(xlarge, 1, 12)}`
			)}
		>
			{children}
		</div>
	);
}

Column.propTypes = {
	children: PropTypes.node,
	medium: PropTypes.number,
	large: PropTypes.number,
	xlarge: PropTypes.number,
	className: PropTypes.string
};
