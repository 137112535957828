import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'querystring';
import { Route } from 'react-router-dom';

export const wrapComponent = (Component, rest) => (renderProps) => {
	const queryParams = parse(renderProps.location.search.substr(1));
	return (
		<Component
			{...renderProps}
			queryParams={queryParams}
			pageType={rest.pageType}
		/>
	);
};

export function RouteWithQueryAndProps({ component: Component, ...rest }) {
	return (
		<Route {...rest} render={wrapComponent(Component, rest)} />
	);
}

RouteWithQueryAndProps.propTypes = {
	component: PropTypes.func
};

export default RouteWithQueryAndProps;
