
import { isNumber, isString } from 'lodash';

const hasWindow = () => typeof window !== 'undefined';

export const scrollTo = (offset, behavior = 'smooth') => {
	if (hasWindow()) {
		window.scroll({
			top: offset,
			behavior
		});
	}
};

export const scrollToElement = (element, options = {}) => {
	if (!element) {
		return;
	}

	let scrollElement = element;

	if (isString(element)) {
		scrollElement = document.querySelector(element);
	}

	if (!scrollElement) {
		return;
	}

	let offset = scrollElement.offsetTop;
	if (isNumber(options.offset)) {
		offset += options.offset;
	}

	scrollTo(offset, options.behavior);
};

export const setLocation = (path, timeout = 300) => {
	if (typeof window !== 'undefined') {
		// Wait 300ms for any tracking events to flush
		setTimeout(() => { window.location.href = path; }, timeout);
	}
};

export const cleanUrl = (url) => url.replace(/\s+/g, '-');
