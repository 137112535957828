import { compose, withHandlers } from 'recompose';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from '../context/getSegmentContext';

export default compose(
	getSegmentContext,
	withHandlers({
		handleFacilityCardVisible: (props) => (segmentEventProps) => {
			const { cardType } = segmentEventProps;

			let facilityLabel = 'facility card-facility card impression';
			let facilityEvent = 'facility_card_impression';

			if (cardType === 'topYielderCard') {
				facilityLabel = 'pty-facility card';
				facilityEvent = 'pty_facility_card_impression';
			}

			const segmentProperties = {
				category: props.segmentCategory,
				search_id: props.searchId,
				...segmentEventProps
			};
			const segmentProps = SegmentConfig.buildTrackingProps(
				props.segmentConfig,
				{ segmentLabel: facilityLabel, segmentProperties }
			);
			Segment.track(facilityEvent, segmentProps);
		}
	})
);
