/* eslint-disable max-len */
import React from 'react';

export default function Clock() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.69 27.69" width="28" height="28" className="svg clock">
			<g data-name="Layer 1">
				<path d="M13.84,0A13.84,13.84,0,1,0,27.69,13.84,13.86,13.86,0,0,0,13.84,0Zm0,24.65A10.81,10.81,0,1,1,24.65,13.84,10.82,10.82,0,0,1,13.84,24.65Z" />
				<path d="M16.56,10.38l-1.06,1.5V6.45a1.5,1.5,0,0,0-3,0V16s0,.08,0,.13,0,0,0,.07a1.37,1.37,0,0,0,.14.45l0,.06A1.39,1.39,0,0,0,13,17l0,0,0,0a1.4,1.4,0,0,0,.36.2h.07a1.36,1.36,0,0,0,.4.06h0a1.4,1.4,0,0,0,.3,0l.07,0,.21-.07.09,0,.16-.1.09-.07.14-.14,0,0,3.7-4.71a1.4,1.4,0,0,0-.17-2A1.34,1.34,0,0,0,16.56,10.38Z" />
			</g>
		</svg>
	);
}
