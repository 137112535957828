import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components/core/Button';
import PhoneNumber from './PhoneNumber';

import './PhoneNumberButton.scss';

export function PhoneNumberButton({
	className, phoneNumber, unformattedPhoneNumber, primary, dark, outline, size, fullWidth, hideIcon, link
}) {
	return (phoneNumber && unformattedPhoneNumber) ? (
		<Button
			className={classnames('phone-number-button', className)}
			fullWidth={fullWidth}
			href={`tel:${unformattedPhoneNumber}`}
			primary={primary}
			dark={dark}
			outline={outline}
			segmentLabel="click to call"
			link={link}
		>
			<PhoneNumber
				phoneNumber={phoneNumber}
				unformattedPhoneNumber={unformattedPhoneNumber}
				size={size}
				hideIcon={hideIcon}
			/>
		</Button>
	) : null;
}

PhoneNumberButton.propTypes = {
	fullWidth: PropTypes.bool,
	phoneNumber: PropTypes.string,
	unformattedPhoneNumber: PropTypes.string,
	primary: PropTypes.bool,
	dark: PropTypes.bool,
	outline: PropTypes.bool,
	className: PropTypes.string,
	size: PropTypes.string,
	hideIcon: PropTypes.bool,
	link: PropTypes.bool
};

export default PhoneNumberButton;
