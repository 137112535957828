import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'components/core/DatePicker';

// Wrapper for date picker to udpate redux state
export default function ReduxDatePicker({
	id,
	input,
	label,
	selectedDate,
	reservationWindow,
	allValid
}) {
	return (
		<DatePicker
			id={id}
			label={label}
			selectedDate={selectedDate}
			reservationWindow={reservationWindow}
			allValid={allValid}
			{...input}
		/>
	);
}


ReduxDatePicker.propTypes = {
	id: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.node,
	selectedDate: PropTypes.object,
	reservationWindow: PropTypes.instanceOf(Date),
	allValid: PropTypes.bool
};
