import { omit } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import { PAGES } from 'store/pages/actions';
import { SEARCH } from './actions';

export const initialState = {
	loading: false,
	error: false,
	errorMessage: null,
	highlightedFacility: null,
	page: 1,
	numPages: 0,
	totalFacilities: 0,
	lastInOriginalSearch: null,
	totalUnits: 0,
	facilitiesPerPage: 15,
	facilities: [],
	landingFacilities: [],
	landingLocation: {},
	irmMarket: null,
	headlines: {},
	isBellhopsMarket: false,
	staticMapUrl: null,
	location: {},
	polygons: {},
	phone: {},
	meta: {},
	url: {},
	searchType: null,
	content: {},
	searchId: null,
	searchParams: {
		location: null,
		searchType: null,
		moveInDate: null,
		sqft: null,
		order: 'recommended'
	},
	mapRedoSearch: false,
	promoCounts: {},
	facilityBadges: []
};

const SUCCESS_ACTIONS = combineActions(
	SEARCH.SUCCESS,
	SEARCH.SEARCH_LANDING_SUCCESS,
	SEARCH.STORAGE_DEALS_SUCCESS,
	SEARCH.PAGE_SUCCESS,
);

const reducer = handleActions({
	[SEARCH.REQUEST]: (state) => ({
		...state,
		loading: true
	}),
	[SEARCH.FAILURE]: (state) => ({
		...state,
		loading: false
	}),
	[SUCCESS_ACTIONS]: (state, { payload }) => {
		const { entities, result } = payload;
		const search = entities.search[result.search];

		if (state?.content?.tpReviews) {
			result.content.tpReviews = state.content.tpReviews;
		}

		return {
			...state,
			canonicalUrl: result.seo && result.seo.canonical,
			meta: result.meta,
			url: search.url,
			loading: false,
			facilitiesPerPage: search.facilitiesPerPage,
			content: result.content ?
				omit(result.content, ['housingMetrics', 'drillDownLinks.zips', 'drillDownLinks.sizes']) :
				{},
			location: search.location,
			polygons: search.polygons,
			irmMarket: search.irmMarket,
			headlines: search.headlines,
			facetCounts: search.facets,
			isBellhopsMarket: search.isBellhopsMarket,
			page: search.page,
			numPages: search.numPages,
			staticMapUrl: search.staticMapUrl,
			totalFacilities: search.totalFacilities,
			lastInOriginalSearch: search.lastInOriginalSearch,
			totalUnits: search.totalUnits,
			facilities: search.facilities || [],
			badgedFacilities: search.badgedFacilities || {},
			phone: result.phone,
			localPhone: state.localPhone || search.localPhone || {},
			searchType: search.searchType,
			searchParams: {
				...search.searchParams,
				searchType: search.searchType
			},
			searchId: search.searchId,
			promoCounts: search.promoCounts
		};
	},
	[SEARCH.HIGHLIGHT_FACILITY]: (state, { payload }) => ({
		...state,
		highlightedFacility: payload.facilityId
	}),
	[combineActions(
		PAGES.CAR_PSP_PAGE_SUCCESS,
		PAGES.STORAGE_PAGE_SUCCESS,
		PAGES.BOAT_PSP_PAGE_SUCCESS,
		PAGES.RV_PSP_PAGE_SUCCESS

	)]: (state, { payload }) => {
		const search = payload.entities.search[payload.result.search];
		return {
			...state,
			landingFacilities: (search && search.facilities) || [],
			landingLocation: search && search.location,
			searchId: search && search.searchId
		};
	},
	[SEARCH.MAP_REDO_SEARCH]: (state, { payload }) => ({
		...state,
		mapRedoSearch: payload.mapRedoSearch
	})
}, initialState);


export default reducer;
