import { find, get, keys, includes } from 'lodash';

const getSizeBucketForSquareFootage = (sqft, unitType = 'storage') => {
	const parkingBuckets = [
		{ sqft: 425, name: '10x50' },
		{ sqft: 375, name: '10x40' },
		{ sqft: 325, name: '10x35' },
		{ sqft: 275, name: '10x30' },
		{ sqft: 225, name: '10x25' },
		{ sqft: 175, name: '10x20' },
		{ sqft: 0, name: '10x15' }
	];

	const buckets = {
		locker: [
			{ sqft: 10, name: '3x5' },
			{ sqft: 0, name: '2.5x2.5' }
		],
		wine_storage: [
			{ sqft: 0, name: '2.5x2.5' }
		],
		workspace: [
			{ sqft: 0, name: '2.5x2.5' }
		],
		storage: [
			{ sqft: 250, name: '10x30' },
			{ sqft: 175, name: '10x20' },
			{ sqft: 125, name: '10x15' },
			{ sqft: 87, name: '10x10' },
			{ sqft: 62, name: '5x15' },
			{ sqft: 37, name: '5x10' },
			{ sqft: 0, name: '5x5' }
		],
		parking_space: parkingBuckets,
		outdoor: parkingBuckets
	};

	if (!includes(keys(buckets), unitType)) {
		return undefined;
	}

	const bucket = find(buckets[unitType], (size) => sqft >= size.sqft);
	return get(bucket, 'name');
};


const formatUnit = (unit) => {
	const { regularPrice } = unit;
	return {
		...unit,
		sizeBucket: getSizeBucketForSquareFootage(unit.squareFootage, unit.unitType),
		priceDetails: unit.priceDetails || {
			regular: regularPrice,
			meta: regularPrice
		}
	};
};

export default formatUnit;
