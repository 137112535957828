import React from 'react';
import PropTypes from 'prop-types';
import { CoreLabel } from 'components/core/library/label';
import './label.scss';

export default function Label({
	children, text, helpText, htmlFor, textAfterChildren, textClassName,
	className
}) {
	return (
		<CoreLabel
			text={text}
			helpText={helpText}
			htmlFor={htmlFor}
			textAfterChildren={textAfterChildren}
			textClassName={textClassName}
			className={className}
		>
			{children}
		</CoreLabel>
	);
}

Label.propTypes = {
	/** Text for the label. Text will usually be a string, but could also be another component */
	text: PropTypes.node.isRequired,
	/** Additional help text to display near the label */
	helpText: PropTypes.string,
	/** id of the associated input */
	htmlFor: PropTypes.string.isRequired,
	/** If true, the text for the label is rendered after the children as opposed to before */
	textAfterChildren: PropTypes.bool,
	/** Additional class to be applied to label text */
	textClassName: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string
};

Label.defaultProps = {
	textAfterChildren: true
};
