import withHandlers from 'recompose/withHandlers';
import { buildSearchLink } from 'lib/search';
import { setLocation } from 'lib/helpers';
import format from 'date-fns/format';

/**
 * Basic Search Handler for search box that generates location type and date
 */
export default withHandlers({
	handleSearch: (props) => ({ locationParams, date }) => {
			let params = {
				...locationParams,
				searchType: props.searchType,
			moveInDate: date && format(date, 'MM/DD/YYYY')
			};

			if (props.searchParams) {
				params.order = props.searchParams.order;
				params.vehicle = props.searchParams.vehicle;
				params.searchType = props.searchParams.searchType;
				if (props.searchParams.amenity) {
					// allow for searching between amenity landing pages
					// api needs to see the amenity key to send back an
					// amenity canonical url to do a redirect
					params.amenity = props.searchParams.amenity;
				}
			}

			if (props.additionalSearchParams) {
				params = { ...params, ...props.additionalSearchParams };
			}

			setLocation(buildSearchLink(params, props.searchPath));
		}
});
