import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import toPairs from 'lodash/toPairs';

import { withSegmentContext } from 'components/core/library/segment';
import { Paragraph } from 'components/core/Typography';
import { Button } from 'components/core/Button';

import statesList from 'config/states';

const enhance = withSegmentContext;

export function FooterStates({ className, states }) {
	return (
		<div className={classnames('footer-states', className)}>
			<Paragraph size="small" color="white">Storage by state</Paragraph>
			<ul className="state-list">
				{toPairs(states).map((state) => {
					const [abbv, name] = state;
					return (
						<li className="state" key={abbv}>
							<Button segmentLabel={`state ${abbv}`} secondary href={`/${name.toLowerCase().split(' ').join('-')}-storage.html`}>
								{abbv}
							</Button>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

FooterStates.propTypes = {
	className: PropTypes.string,
	states: PropTypes.object
};

FooterStates.defaultProps = {
	states: statesList
};

export default enhance(FooterStates);
