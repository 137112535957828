/* eslint-disable max-len */
import React from 'react';

export default function Checkmark() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.67 11.96" className="svg checkmark">
			<path d="M5.12 12a1.71 1.71 0 0 1-1.21-.5L.5 8a1.71 1.71 0 0 1 2.42-2.37L5 7.69 10.62.64a1.71 1.71 0 1 1 2.67 2.13l-6.83 8.55a1.71 1.71 0 0 1-1.24.68z" />
		</svg>
	);
}
