import { isNaN, includes } from 'lodash';

export const getWindowWidth = () => window.innerWidth;
export const getWindowHeight = () => window.innerHeight;
export const getScrollHeight = (el) => el.scrollHeight;
export const getScrollWidth = (el) => el.scrollWidth;
export const getOffsetHeight = (el) => el.offsetHeight;
export const getOffsetWidth = (el) => el.offsetWidth;

export const getBoundingClientRect = (el) => el.getBoundingClientRect();

export const addEventListener = (element, events, callback) => {
	if (typeof events === 'string') {
		element.addEventListener(events, callback);
	} else {
		events.forEach((evt) => {
			element.addEventListener(evt, callback);
		});
	}
};

export const removeEventListener = (element, events, callback) => {
	if (typeof events === 'string') {
		element.removeEventListener(events, callback);
	} else {
		events.forEach((evt) => {
			element.removeEventListener(evt, callback);
		});
	}
};

export const on = (events, callback) => {
	addEventListener(window, events, callback);
};

export const off = (events, callback) => {
	removeEventListener(window, events, callback);
};

export const preventDefaultNoop = (event) => {
	event.stopPropagation();
	event.preventDefault();
};

export function getOffset(el) {
	let left = 0;
	let top = 0;
	let modal = false;
	let parent = el;
	while (parent && !isNaN(parent.offsetLeft) && !isNaN(parent.offsetTop)) {
		left += parent.offsetLeft - parent.scrollLeft;
		top += parent.offsetTop - parent.scrollTop;
		modal = modal || includes(parent.className, 'ph-modal');
		parent = parent.offsetParent;
	}
	return { top, left, modal };
}

export function isElementVisible(el, percentVisible = 100) {
	if (!el) {
		return false;
	}

	const rect = getBoundingClientRect(el);
	const visibleThreshold = rect.height * percentVisible * 0.01;
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

	// If element is completely within the window, its visible
	if (rect.top >= 0 && rect.bottom <= windowHeight) {
		return true;
	}

	// If element starts above the window, check how much of its bottom is visible
	if (rect.top < 0 && rect.bottom <= windowHeight) {
		return rect.bottom > 0 && rect.bottom >= visibleThreshold;
	}

	// If element ends below the window, check how much of its top is visible
	if (rect.bottom >= windowHeight && rect.top >= 0) {
		return rect.top < windowHeight && (windowHeight - rect.top) >= visibleThreshold;
	}

	// If element is larger than the window (and in view), just count it as visible
	return rect.top < 0 && rect.bottom >= windowHeight;
}

export function createElementWithAttributes(tag, attributes) {
	const script = document.createElement(tag);
	// for each key value in attributes, assign the key to script and set its value to the value
	Object.keys(attributes).forEach((key) => {
		script[key] = attributes[key];
	});
	return script;
}

export function createStylesheetElement(attributes) {
	const el = createElementWithAttributes('link', {
		rel: 'stylesheet',
		...attributes
	});
	return el;
}

export function createScriptElement(attributes) {
	const el = createElementWithAttributes('script', {
		type: 'text/javascript',
		...attributes
	});
	return el;
}
