import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isSameMonth from 'date-fns/is_same_month';
import format from 'date-fns/format';

export default class CoreCalendarHeader extends PureComponent {
	static propTypes = {
		month: PropTypes.instanceOf(Date),
		onPreviousClick: PropTypes.func,
		onNextClick: PropTypes.func,
		nextComponent: PropTypes.func,
		prevComponent: PropTypes.func,
		allValid: PropTypes.bool
	};

	static defaultProps = {
		month: new Date()
	};

	_renderNextComponent() {
		if (!this.props.nextComponent) {
			return (<button className="fa fa-angle-right" type="button" onClick={this.props.onNextClick} />);
		}

		return (<this.props.nextComponent onClick={this.props.onNextClick} />);
	}

	_isPrevDisabled() {
		return !this.props.allValid && isSameMonth(new Date(), this.props.month);
	}

	_renderPrevComponent() {
		// Disable going back in time
		const className = this._isPrevDisabled() ? 'disabled' : '';
		if (!this.props.prevComponent) {
			return (
				<button
					className={`prev fa fa-angle-left ${className}`}
					type="button"
					onClick={this.props.onPreviousClick}
				/>);
		}

		return (
			<this.props.prevComponent
				className={`prev ${className}`}
				onClick={this.props.onPreviousClick}
			/>);
	}

	render() {
		return (
			<div className="ph-calendar-header">
				{this._renderPrevComponent()}
				{format(this.props.month, 'MMMM YYYY')}
				{this._renderNextComponent()}
			</div>
		);
	}
}
