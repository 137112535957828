import { merge, get } from 'lodash';

const formatFacilityData = (data) => {
	let cardTypePrefix = '';
	switch (get(data, 'cardType')) {
		case 'topYielderCard':
			cardTypePrefix = 'pty ';
			break;
		default:
			cardTypePrefix = '';
	}
	return {
		product_id: get(data, 'facilityId'),
		category: `${cardTypePrefix}${get(data, 'city')}, ${get(data, 'state')}`,
		name: `${get(data, 'facilityName')} - ${get(data, 'facilityId')}`,
		brand: `${get(data, 'facilityCorporationType')}`
	};
};

const formatUnitData = (data) => ({
	price: get(data, 'price', null),
	variant: get(data, 'unitSizeText', null)
});

const getProductViewedProps = (data) => {
	const facilityData = formatFacilityData(data);
	const unitData = formatUnitData(data);
	const standardData = {
		quantity: 1,
		currency: 'usd'
	};

	return merge(facilityData, unitData, standardData);
};

const getProductAddedProps = (data) => {
	const facilityData = formatFacilityData(data);
	const unitData = formatUnitData(data);
	const dimension = get(data, 'dimension');

	const standardData = { quantity: 1, dimension, unit_id: get(data, 'unitId') };

	return merge(facilityData, unitData, standardData);
};

const getProductClickedProps = (data) => {
	const facilityData = formatFacilityData(data);
	const unitData = formatUnitData(data);
	const list = `${get(data, 'pageRegion')}: ${get(data, 'searchTerm')}`;
	const position = get(data, 'position');
	const standardData = { quantity: 1, list, position, search_id: get(data, 'searchId') };
	return merge(facilityData, unitData, standardData);
};

const getProductListViewedProps = (data) => ({
	list_id: get(data, 'listId'),
	search_id: get(data, 'searchId'),
	page: get(data, 'page'),
	category: get(data, 'searchText'),
	searchTerm: get(data, 'searchTerm'),
	products: get(data, 'products'),
	productIds: get(data, 'productIds'),
	value: get(data, 'products', []).length
});

const getEcommerceProps = (event, data) => {
	switch (event) {
		case 'Product Viewed':
			return getProductViewedProps(data);
		case 'Product Added':
			return getProductAddedProps(data);
		case 'Product Clicked':
			return getProductClickedProps(data);
		case 'Product List Viewed':
			return getProductListViewedProps(data);
		default:
			return { category: 'unknown event type' };
	}
};

export default getEcommerceProps;
