import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withSegmentClickTracking } from 'components/core/library/segment';

/**
 * Base component for links. Has [Segment](#segment-tracking) click tracking built in.
 *
 * **Base CSS Class:** `ph-link`
 */

const enhance = withSegmentClickTracking;

export function CoreLink({
	children, href, to, handleClick, className, target, rel, getRef
}, context) {
	return to && context.router ? (
		<Link
			to={to}
			rel={rel}
			ref={getRef}
			onClick={handleClick}
			className={classnames(className, 'ph-link inner-accordion-safe')}
			target={target}
		>
			{children}
		</Link>
	) : (
		<a
			onClick={handleClick}
			href={href || to}
			rel={rel}
			ref={getRef}
			className={classnames(className, 'ph-link inner-accordion-safe')}
			target={target}
		>
			{children}
		</a>
	);
}

CoreLink.propTypes = {
	/** Target of the link */
	href: PropTypes.string,
	to: PropTypes.string,
	rel: PropTypes.string,
	getRef: PropTypes.func,
	children: PropTypes.node,
	handleClick: PropTypes.func,
	className: PropTypes.string,
	target: PropTypes.string
};

CoreLink.contextTypes = {
	router: PropTypes.object
};

export default enhance(CoreLink);
