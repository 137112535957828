import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import BodyClassName from 'react-body-classname';
import { get, includes } from 'lodash';
import { NewFourOhFourPage } from 'pages/Bundles';
import Routes from 'routes';

import mapDispatchPropsToActions from 'store/helpers/mapDispatchToActions';
import applicationSelector from 'store/selectors/app';
import { Header } from 'components/brand/Header';
import { Footer } from 'components/brand/Footer';
import { ExperimentModal } from 'components/experiment/ExperimentModal';
import { PageMetadata } from 'pages/ApplicationWrapper/PageMetadata';
import { initializeApp, appReady } from 'store/app/actions';

import { getTollfreePhoneNumber } from 'store/pageMeta/actions';

import './applicationWrapper.scss';

export const enhance = compose(
	withRouter,
	connect(
		applicationSelector,
		{ initializeApp, appReady, getTollfreePhoneNumber },
		mapDispatchPropsToActions
	),
	lifecycle({
		componentWillMount() {
			this.props.actions.initializeApp();
		},
		componentDidMount() {
			this.props.actions.appReady();

			const stateScriptTag = document.getElementById(
				'preloaded-state-script'
			);

			if (stateScriptTag) {
				stateScriptTag.parentNode.removeChild(stateScriptTag);
			}
		}
	})
);

export function ApplicationWrapper({
	tags,
	isLandingPage,
	phone,
	statusCode,
	noindex,
	staticContext,
	sftest,
	actions,
	redirectURL,
	holiday,
	location
}) {
	//  Pushes the app status code up into the server router
	if (staticContext) {
		staticContext.statusCode = statusCode; // eslint-disable-line no-param-reassign
	}
	if (staticContext && redirectURL) {
		staticContext.redirectURL = redirectURL; // eslint-disable-line no-param-reassign
	}
	return (
		<div className="application-wrapper">
			<BodyClassName className="reactfoot-body" />
			{!!tags && (
				<PageMetadata
					title={tags.title}
					og={tags.og}
					meta={tags.meta}
					links={tags.links}
					structuredData={tags.structuredData}
					noindex={noindex}
				/>
			)}
			<Header
				getTollfreePhoneNumber={actions.getTollfreePhoneNumber}
				hideMenu={isLandingPage}
				hideDealsLink={isLandingPage}
				formattedPhoneNumber={get(phone, 'nationalSparephone')}
				phoneNumber={get(phone, 'nationalSparephoneUnformatted')}
				segmentCategory="site header"
				holiday={holiday} // sf_12289_sfdc_holiday_messaging
			/>
			<main>
				{includes([500, 404, 410], statusCode) && (
					<NewFourOhFourPage statusCode={statusCode} />
				)}
				{!includes([500, 404, 410], statusCode) && <Routes />}
			</main>
			{sftest && <ExperimentModal />}
			<Footer
				getTollfreePhoneNumber={actions.getTollfreePhoneNumber}
				formattedPhoneNumber={get(phone, 'nationalSparephone')}
				phoneNumber={get(phone, 'nationalSparephoneUnformatted')}
				isLandingPage={isLandingPage}
				segmentCategory="site footer"
				location={location}
			/>
		</div>
	);
}

ApplicationWrapper.propTypes = {
	noindex: PropTypes.bool,
	statusCode: PropTypes.number,
	redirectURL: PropTypes.string,
	tags: PropTypes.object,
	isLandingPage: PropTypes.bool,
	phone: PropTypes.object,
	staticContext: PropTypes.object,
	sftest: PropTypes.bool,
	actions: PropTypes.shape({
		getTollfreePhoneNumber: PropTypes.func.isRequired
	}).isRequired,
	holiday: PropTypes.bool,
	location: PropTypes.object
};

export default enhance(ApplicationWrapper);
