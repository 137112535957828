import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Container.scss';

export default function Container({
	className, children, flush, narrow, gutters
}) {
	return (
		<div
			className={
				classnames(
					className,
					'page-container',
					!!gutters && `gutter-${gutters}`,
					{
						flush: flush || gutters,
						narrow
					}
				)
			}
		>
			{children}
		</div>
	);
}

Container.propTypes = {
	children: PropTypes.node.isRequired,
	/** Removes the gutters at all breakpoints */
	flush: PropTypes.bool,
	/** Narrow or Wide Container */
	narrow: PropTypes.bool,
	/** Breakpoint at which gutters should appear, showing by default */
	gutters: PropTypes.oneOf(['medium', 'large', 'xlarge']),
	className: PropTypes.string
};
