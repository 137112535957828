import { createAction } from 'redux-actions';
import axios from 'axios';

export const FILE_UPLOADS = {
	UPLOAD_START: 'SFDC/FILE_UPLOADS/UPLOAD_START',
	UPLOAD_SUCCESS: 'SFDC/FILE_UPLOADS/UPLOAD_SUCCESS',
	UPLOAD_FAILURE: 'SFDC/FILE_UPLOADS/UPLOAD_FAILURE',
	UPLOAD_PROGRESS: 'SFDC/FILE_UPLOADS/UPLOAD_PROGRESS',
	REMOVE_FILE: 'SFDC/FILE_UPLOADS/REMOVE_FILE',
	SET_UPLOAD_URL: 'SFDC/FILE_UPLOADS/SET_UPLOAD_URL'
};

export const uploadStart = createAction(FILE_UPLOADS.UPLOAD_START);
export const uploadProgress = createAction(FILE_UPLOADS.UPLOAD_PROGRESS);
export const uploadSuccess = createAction(FILE_UPLOADS.UPLOAD_SUCCESS);
export const uploadFailure = createAction(FILE_UPLOADS.UPLOAD_FAILURE);
export const removeFile = createAction(FILE_UPLOADS.REMOVE_FILE);
export const setUploadUrl = createAction(FILE_UPLOADS.SET_UPLOAD_URL, (fileName, uploadUrl, key) => ({
	fileName,
	uploadUrl,
	key
}));

export function uploadFile(fileName, file, uploadUrl) {
	return (dispatch) => {
		// Fire Start Action
		dispatch(uploadStart({ fileName, file }));

		const config = {
			onUploadProgress: (progressEvent) => {
				// Fire upload progress event
				const progress = ((progressEvent.loaded / progressEvent.total) * 100);
				dispatch(uploadProgress({ fileName, uploadProgress: progress }));
			},
			headers: {
				'Content-Type': file.type
			}
		};

		return axios.put(uploadUrl, file, config)
		.then(() => dispatch(uploadSuccess({ fileName, file })))
		.catch((err) => dispatch(uploadFailure({ fileName, file, err })));
	};
}
