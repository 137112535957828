import { compose, lifecycle } from 'recompose';
import Segment from '@sparefoot/segment-react';
import formatEcommerceProps from 'lib/segment/ecommerce';
import getSegmentContext from '../context/getSegmentContext';

export default compose(
	getSegmentContext,
	lifecycle({
		componentDidMount() {
			const { segmentConfig = {}, ecommerceProps } = this.props;
			const additionalProps = {};

			const props = formatEcommerceProps('Product Viewed', {
				...ecommerceProps,
				...segmentConfig.ecommerceProps,
				...additionalProps
			});
			Segment.track('Product Viewed', props);
		}
	})
);
