/* eslint-disable max-len */
import React from 'react';

export default function VehicleStorage() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="41.74" height="32.46" viewBox="0 0 41.74 32.46">
			<path d="M40.87,0H.87A.87.87,0,0,0,0,.87V31.59a.87.87,0,0,0,.87.87h40a.87.87,0,0,0,.87-.87V.87A.87.87,0,0,0,40.87,0ZM11.21,20.29h19.6a1.67,1.67,0,0,1,1.65,1.79v4H9.28v-4A1.92,1.92,0,0,1,11.21,20.29Zm1.66-1.74.74-5.68a.87.87,0,0,0,0-.11,2.91,2.91,0,0,1,2.91-2.91h8.68a2.91,2.91,0,0,1,2.91,2.91.87.87,0,0,0,0,.11l.74,5.68Zm1,10.43V27.83H27.83V29a1.74,1.74,0,0,0,1.74,1.74H12.17A1.74,1.74,0,0,0,13.91,29Zm15.65,1.74A1.74,1.74,0,0,0,31.3,29V27.83h2A.87.87,0,0,0,34.2,27V22.08a3.42,3.42,0,0,0-3.39-3.53h-.2l-.76-5.85a4.65,4.65,0,0,0-4.65-4.59H16.53a4.65,4.65,0,0,0-4.65,4.59l-.76,5.86a3.65,3.65,0,0,0-3.58,3.52V27a.87.87,0,0,0,.87.87h2V29a1.74,1.74,0,0,0,1.74,1.74H5.8V7.27A.78.78,0,0,1,6.38,7H35.65a.29.29,0,0,1,.29.29V30.72Zm10.43,0H37.68V7.25a2,2,0,0,0-2-2H6.38a2.28,2.28,0,0,0-2.32,2V30.72H1.74v-29H40Z" />
			<circle cx="12.17" cy="23.19" r="1.45" />
			<circle cx="29.57" cy="23.19" r="1.45" />
			<path d="M16.52,25.22a.87.87,0,0,0,.87-.87V22a.87.87,0,0,0-1.74,0v2.32A.87.87,0,0,0,16.52,25.22Z" />
			<path d="M19.42,25.22a.87.87,0,0,0,.87-.87V22a.87.87,0,0,0-1.74,0v2.32A.87.87,0,0,0,19.42,25.22Z" />
			<path d="M22.32,25.22a.87.87,0,0,0,.87-.87V22a.87.87,0,0,0-1.74,0v2.32A.87.87,0,0,0,22.32,25.22Z" />
			<path d="M25.22,25.22a.87.87,0,0,0,.87-.87V22a.87.87,0,0,0-1.74,0v2.32A.87.87,0,0,0,25.22,25.22Z" />
		</svg>
	);
}
