import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import format from 'date-fns/format';
import getDate from 'date-fns/get_date';
import { values, isFunction, pick } from 'lodash';

export const DateTypes = {
	PAST: 'past',
	TODAY: 'today',
	OPEN: 'open',
	CLOSED: 'closed',
	INVALID: 'invalid'
};

export default class CoreDay extends PureComponent {
	static propTypes = {
		date: PropTypes.instanceOf(Date),
		past: PropTypes.bool,
		today: PropTypes.bool,
		closed: PropTypes.bool,
		invalid: PropTypes.bool,
		notInCurrentMonth: PropTypes.bool,
		onDateClick: PropTypes.func,
		className: PropTypes.string
	};

	static defaultProps = {
		notInCurrentMonth: false
	};

	get type() {
		if (this.props.past) {
			return DateTypes.PAST;
		} if (this.props.closed) {
			return DateTypes.CLOSED;
		} if (this.props.today) {
			return DateTypes.TODAY;
		} if (this.props.invalid) {
			return DateTypes.INVALID;
		}
		return DateTypes.OPEN;
	}

	get dateObj() {
		return {
			string: format(this.props.date, 'MM/DD/YYYY'),
			type: pick(this.props, values(DateTypes)),
			date: this.props.date
		};
	}

	_handleClick = () => {
		if (isFunction(this.props.onDateClick)) {
			this.props.onDateClick(this.dateObj);
		}
	};

	render() {
		return (
			<td>
				<button
					type="button"
					className={classnames(
						'ph-calendar-day',
						this.type,
						this.props.className,
						{ 'next-month': this.props.notInCurrentMonth }
					)}
					onClick={this._handleClick}
				>
					<div className="ph-date-text">
						<div className="date-number-text">
							{getDate(this.props.date)}
						</div>
						{this.props.closed ? <div className="state-text">closed</div> : null}
					</div>
				</button>
			</td>
		);
	}
}
