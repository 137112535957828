import { isFunction } from 'lodash';
import { API } from '../middleware/api';

export const USER = {
	SAVE_GA_REQUEST: 'SFDC/USER/SAVE_GA_REQUEST',
	SAVE_GA_SUCCESS: 'SFDC/USER/SAVE_GA_SUCCESS',
	SAVE_GA_FAILURE: 'SFDC/USER/SAVE_GA_FAILURE',
	UNSUBSCRIBE_REQUEST: 'SFDC/USER/UNSUBSCRIBE_REQUEST',
	UNSUBSCRIBE_SUCCESS: 'SFDC/USER/UNSUBSCRIBE_SUCCESS',
	UNSUBSCRIBE_FAILURE: 'SFDC/USER/UNSUBSCRIBE_FAILURE'
};

export function unsubscribe(data) {
	return {
		[API]: {
			method: 'POST',
			types: [USER.UNSUBSCRIBE_REQUEST, USER.UNSUBSCRIBE_SUCCESS, USER.UNSUBSCRIBE_FAILURE],
			endpoint: '/unsubscribe',
			data
		}
	};
}

export function saveGAClientId(clientId) {
	return {
		[API]: {
			method: 'POST',
			types: [USER.SAVE_GA_REQUEST, USER.SAVE_GA_SUCCESS, USER.SAVE_GA_FAILURE],
			endpoint: '/visit/meta',
			data: {
				meta: {
					value: clientId,
					key: 'google_client_id'
				}
			}
		}
	};
}

export const getUserGAClientId = () => (dispatch) => {
	function _checkGALoaded() {
		/* eslint-disable no-undef */
		if (typeof ga !== 'undefined' && isFunction(ga)) {
			return new Promise((resolve) => ga(resolve));
		}
		/** eslint-enable */
		return new Promise((resolve) => setTimeout(resolve, 300)).then(_checkGALoaded);
	}

	return _checkGALoaded()
	.then((tracker) => {
		const gaClientId = tracker.get('clientId');
		dispatch(saveGAClientId(gaClientId));
	});
};
