import boatListIcon from 'assets/images/icons//how-it-works/boat.svg';
import carListIcon from 'assets/images/icons//how-it-works/car.svg';
import rvListIcon from 'assets/images/icons//how-it-works/rv.svg';
import trailerListIco from 'assets/images/icons//how-it-works/trailer.svg';

export const PPC_TYPE_TRAILER = 'Trailer';
export const PPC_TYPE_BOAT = 'Boat';
export const PPC_TYPE_CAR = 'Car';
export const PPC_TYPE_RV = 'RV';
export const PPC_TYPE_STORAGE = 'Self-Storage';

// Used in PaidTemplatePage & page actions
export const PaidLandingTypes = {
	storage: PPC_TYPE_STORAGE,
	boat: PPC_TYPE_BOAT,
	car: PPC_TYPE_CAR,
	rv: PPC_TYPE_RV,
	trailer: PPC_TYPE_TRAILER,
};

export const PaidTemplateHeaderImages = {
    Boat: 'boat-default.jpg',
    Car: 'car-default.jpg',
    RV: 'rv-default.jpg',
    Trailer: 'trailer-default.jpg',
    default: 'self-storage-default.jpg',
};

export const PaidTemplateHowItWorksIconList = {
    Boat: boatListIcon,
	Car: carListIcon, 
	RV: rvListIcon,
	Trailer: trailerListIco
}

export const formatPageType = {
	Boat: 'boat',
	Car: 'car',
	RV: 'RV',
	Trailer: 'trailer'
}