import thanksgivingIcon from 'assets/images/icons/holiday/thanksgiving.svg';
import veteransIcon from 'assets/images/icons/holiday/veterans.svg';
import giftIcon from 'assets/images/icons/holiday/gift.svg';
import newYearIcon from 'assets/images/icons/holiday/newYear.svg';
import flagIcon from 'assets/images/icons/holiday/flag.svg';
import fireworksIcon from 'assets/images/icons/holiday/fireworks.svg';
import laborIcon from 'assets/images/icons/holiday/labor.svg';

const holidayMessaging = {
	veteransDay: {
		message: 'We’re saluting our heroes this Veterans Day!',
		icon: veteransIcon,
		primaryColor: 'blue',
		secondaryColor: 'lightBlue',
		iconAlt: 'medal',
		startingTime: '',
		endingTime: ''
	},
	thanksgiving: {
		message: 'Wishing You a Happy Thanksgiving!',
		icon: thanksgivingIcon,
		primaryColor: 'orange',
		secondaryColor: 'lightOrange',
		iconAlt: 'turkey',
		startingTime: '',
		endingTime: ''
	},
	holidays: {
		message: 'Spreading the Holiday Cheer!',
		icon: giftIcon,
		primaryColor: 'red',
		secondaryColor: 'lightAqua',
		iconAlt: 'gift',
		startingTime: '',
		endingTime: ''
	},
	newYears: {
		message: 'Cheers to the New Year!',
		icon: newYearIcon,
		primaryColor: 'darkBlue',
		secondaryColor: 'lightYellow',
		iconAlt: 'wine bottle',
		startingTime: '',
		endingTime: ''
	},
	memorialDay: {
		message: 'Honoring Our Heroes this Memorial Day!',
		icon: flagIcon,
		primaryColor: 'red',
		secondaryColor: 'lightAqua',
		iconAlt: 'flag',
		startingTime: '',
		endingTime: ''
	},
	independenceDay: {
		message: 'Wishing You a Sparkling Independence Day!',
		icon: fireworksIcon,
		primaryColor: 'blue',
		secondaryColor: 'lightBlue',
		iconAlt: '',
		startingTime: '',
		endingTime: ''
	},

	laborDay: {
		message: 'Relax and Recharge this Labor Day!',
		icon: laborIcon,
		primaryColor: 'blue',
		secondaryColor: 'lightBlue',
		iconAlt: 'tools',
		startingTime: '',
		endingTime: ''
	}
};

export default holidayMessaging;
