import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withExperiment } from '@sparefoot/react-core/decorators';

import { withSegmentContext } from 'components/core/library/segment';
import { Link } from 'components/core/Link';
import { Image } from 'components/core/Image';

import { Logo } from 'components/svg';
import { ClickToShowNumberButton } from 'components/contact/ClickToShowNumberButton';
import DealsLink from 'components/brand/Header/DealsLink';
import HolidayBanner from 'components/brand/Header/HolidayBanner';

import holidayMessaging from 'config/holidayMessaging';
import HeaderMenu from './HeaderMenu';

import './header.scss';

export const enhance = compose(
	withExperiment('sf_12289_sfdc_holiday_messaging'),
	withSegmentContext
);

export function Header({
	hideMenu,
	hideDealsLink,
	getTollfreePhoneNumber,
	sf12289Variation,
	holiday,
	// sf_12700_sfdc_paid_landing_refresh
	parentPhoneNumber,
	setParentPhoneNumber,
	parentPhoneLoaded,
	setParentPhoneLoaded,
	className
}) {
	const holidayVariation = sf12289Variation === 'variation' && holiday;

	return (
		<header
			className={classnames(
				'ph-header',
				className,
				{ 'holiday-header': holidayVariation },
				holidayVariation && `header-theme-${holidayMessaging[holiday].secondaryColor}`
			)}
		>
			<div className="main-menu">
				<Link href="/" className="logo-link" hideBorder segmentLabel="logo">
					{holidayVariation && (
						<Image
							className="holiday-icon"
							src={holidayMessaging[holiday].icon}
							alt={holidayMessaging[holiday].iconAlt}
						/>
					)}
					<Logo titleID="sparefoot-header-title" color={holidayVariation && holidayMessaging[holiday].primaryColor} />
				</Link>
				{!hideMenu && <HeaderMenu />}
				{!hideDealsLink && <DealsLink className="hide-on-desktop" />}
				<ClickToShowNumberButton
					outline
					fetchPhoneNumber={getTollfreePhoneNumber}
					showAsLink
					size="small"
					segmentLabel="click to show number"
					parentPhoneNumber={parentPhoneNumber}
					setParentPhoneNumber={setParentPhoneNumber}
					parentPhoneLoaded={parentPhoneLoaded}
					setParentPhoneLoaded={setParentPhoneLoaded}
				/>
			</div>
			{holidayVariation && (
				<HolidayBanner holiday={holiday} />
			)}
		</header>
	);
}

Header.propTypes = {
	hideMenu: PropTypes.bool,
	hideDealsLink: PropTypes.bool,
	getTollfreePhoneNumber: PropTypes.func.isRequired,
	sf12289Variation: PropTypes.string,
	holiday: PropTypes.bool,
	// sf_12700_sfdc_paid_landing_refresh
	parentPhoneNumber: PropTypes.object,
	setParentPhoneNumber: PropTypes.func,
	parentPhoneLoaded: PropTypes.bool,
	setParentPhoneLoaded: PropTypes.func,
	className: PropTypes.string
};

export default enhance(Header);
