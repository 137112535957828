import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import getSegmentContext from '../context/getSegmentContext';

const trackModalEvent = (event, props) => {
	const segmentProps = SegmentConfig.buildTrackingProps(
		props.segmentConfig,
		{
			segmentLabel: props.segmentLabel,
			segmentProperties: props.segmentProperties
		}
	);
	Segment.track(`modal ${event}`, segmentProps);
};

export default compose(
	getSegmentContext,
	lifecycle({
		componentDidMount() {
			if (this.props.showing) {
				trackModalEvent('open', this.props);
			}
		},
		componentWillReceiveProps(nextProps) {
			/* istanbul ignore else */
			if (this.props.showing === false && nextProps.showing === true) {
				trackModalEvent('open', nextProps);
			} else if (this.props.showing === true && nextProps.showing === false) {
				trackModalEvent('close', nextProps);
			}
		}
	})
);
