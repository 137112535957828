/* eslint-disable max-len */
import React from 'react';

export default function Checkmark() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 24.5" className="svg checkmark-shield">
			<path d="M20.5 2c-.6-.4-1.3-.5-2-.3l-3 1L12 .4c-.7-.5-1.7-.5-2.4 0L6 2.7l-3-1c-.7-.2-1.5-.1-2 .3-.6.4-.9 1.1-.9 1.8v11c0 5.4 4.1 9.3 10.2 9.6h.7c3.1 0 5.9-1 7.8-2.8 1.7-1.7 2.7-3.9 2.7-6.5V3.8c0-.7-.4-1.4-1-1.8zm-2.2 13.1c0 1.7-.6 3.1-1.7 4.2-1.4 1.3-3.7 2-6.2 1.9-3.5-.2-7.1-1.9-7.1-6.4V5.2l2.2.7c.6.2 1.3.1 1.8-.2l3.4-2.3 3.4 2.3c.6.4 1.3.5 1.9.3l2.2-.7v9.8z" /><path d="M13.3 8.3L9 12.6l-.8-.6c-.6-.6-1.6-.6-2.3 0-.6.6-.6 1.6 0 2.3l1.8 1.8c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5l5.5-5.5c.6-.6.6-1.6 0-2.3-.5-.6-1.5-.6-2.1 0z" />
		</svg>
	);
}
