import { reduce, get, map, omit, isNaN } from 'lodash';
import flattenCollection from './flattenCollection';

const flattenProsCons = (prosCons) => reduce(prosCons, (list, val, key) => {
	list.push({
		name: key,
		reviewCount: val
	});
	return list;
}, []);

export const formatFacility = (facility = {}) => {
	const formattedFacility = { ...facility };
	formattedFacility.reviewMetadata = omit(get(facility, 'reviews'), ['collection']);
	formattedFacility.reviewMetadata.pros = flattenProsCons(formattedFacility.reviewMetadata.pros);
	formattedFacility.reviewMetadata.cons = flattenProsCons(formattedFacility.reviewMetadata.cons);
	const reviews = get(facility, 'reviews', {});
	if (reviews.collection) {
		formattedFacility.reviews = map(flattenCollection(reviews), (review) => {
			const formattedReview = { ...review };
			formattedReview.ratingRecommended = review.ratingRecommended === 1;
			return formattedReview;
		});
	}

	formattedFacility.amenities = flattenCollection(get(facility, 'amenities'));

	const fees = {};
	if (get(facility, 'fees.admin')) {
		fees.admin = facility.fees.admin;
	}

	fees.deposit = get(facility, 'fees.depositValue', {});
	fees.lock = get(facility, 'fees.lockFee', {});
	fees.totalFees = get(facility, 'fees.totalFees', {});

	const percent = parseInt(get(facility, 'fees.depositPercent'));
	fees.deposit.percent = !isNaN(percent) ? percent / 100 : 0;
	fees.deposit.isRefundable = get(facility, 'fees.isDepositRefundable');
	fees.deposit.hasDeposit = get(facility, 'fees.deposit');

	fees.insurance = !!get(facility, 'fees.isInsuranceRequired');

	formattedFacility.fees = fees;

	return formattedFacility;
};

export default formatFacility;
