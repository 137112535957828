import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withCoreWebVitalMetrics } from '@sparefoot/react-core/decorators';

import './Page.scss';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getTrackingPixelData } from 'store/selectors/getTrackingPixelData';
import TrackingPixel from '../../tracking/TrackingPixel';

export const enhance = compose(withCoreWebVitalMetrics, connect((state) => ({
	trackingData: getTrackingPixelData(state)
})));

export function Page({ children, className, landingPage, trackingData }) {
	return (
		<div className={classnames('page', className, {
			'landing-page': landingPage
		})}
		>
			<TrackingPixel id="pagePixelSrc" trackingData={trackingData} />
			<TrackingPixel id="visitVerifySrc" trackingData={trackingData} />
			{children}
		</div>
	);
}

Page.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	landingPage: PropTypes.bool,
	trackingData: PropTypes.object.isRequired
};

export default enhance(Page);
