/* eslint-disable max-len */
import React from 'react';

export default function Dollar() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.94 102.94" className="svg dollar">
			<g data-name="Layer 1">
				<path d="M52.94 46.12V38c3 0 4.33 2.13 6.46 2.13 2.36 0 3.79-2.13 3.79-4.31 0-4.83-6.25-6-10.25-6.1v-2.64c0-1.09-.17-1.73-1.5-1.73s-1.5.63-1.5 1.73v2.64c-7 .63-12.83 5.52-12.83 13.23 0 5.29 2.83 8.86 12.83 11.68v8.92c-4 0-6.94-2.47-9-2.47-2.36 0-4.09 2.36-4.09 4.77 0 5.41 9 6.44 13 6.67V75c0 1.09.18 1.73 1.5 1.73s1.5-.63 1.5-1.73v-2.48c6-.34 13-4.6 13-13.57C65.94 51 59.94 48 52.94 46.12zm-3-1c-2-.4-3.16-1.5-3.16-3.34A3.61 3.61 0 0 1 49.94 38zm3 18.41v-8c2 .63 3.34 1.67 3.34 4.08 0 1.98-1.34 3.94-3.34 3.94z" />
				<path d="M51.47 0a51.47 51.47 0 1 0 51.47 51.47A51.53 51.53 0 0 0 51.47 0zm0 89.22a37.74 37.74 0 1 1 37.75-37.75 37.79 37.79 0 0 1-37.75 37.75z" />
			</g>
		</svg>
	);
}
