import { compose, withContext } from 'recompose';
import PropTypes from 'prop-types';
import { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from './getSegmentContext';

const withSegmentEcommerceProps = (filterFunc) => compose(
	getSegmentContext,
	withContext({
		segmentConfig: PropTypes.object
	}, ({ segmentConfig, searchId, ...props }) => {
		const ecommerceProps = filterFunc({ ...props, searchId });
		const segmentProperties = { ...props.segmentProperties, searchId };
		return { segmentConfig: SegmentConfig.from(segmentConfig, { ecommerceProps, segmentProperties }) };
	})
);

export default withSegmentEcommerceProps;
