import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withSegmentContext } from 'components/core/library/segment';

import PopularLocations from './PopularLocations';
import LowerContent from './LowerContent';

import './footer.scss';

export const enhance = compose(
	withSegmentContext
);

export function Footer({ year, getTollfreePhoneNumber, segmentCategory, location }) {
	return (
		<footer className="ph-footer">
			<PopularLocations
				segmentCategory={segmentCategory}
			/>
			<LowerContent
				year={year}
				getTollFreePhoneNumber={getTollfreePhoneNumber}
				segmentCategory={segmentCategory}
				location={location}
			/>
		</footer>
	);
}

Footer.propTypes = {
	year: PropTypes.number,
	getTollfreePhoneNumber: PropTypes.func,
	segmentCategory: PropTypes.string,
	location: PropTypes.object
};

Footer.defaultProps = {
	year: new Date().getFullYear()
};

export default enhance(Footer);
