/* eslint-disable max-len */
import React from 'react';

export default function QuestionMark() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="svg question-mark">
			<title>question icon</title>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h16v16H0z" />
				<circle cx="8" cy="8" r="7" strokeWidth="2" stroke="currentColor" />
				<path fill="currentColor" d="M7.139 10.531h1.746v1.694H7.139V10.53zM5 6.312c.047-1.113.436-1.902 1.166-2.367.461-.297 1.027-.445 1.7-.445.882 0 1.615.21 2.2.633.583.422.875 1.047.875 1.875 0 .508-.127.935-.38 1.283-.149.211-.434.48-.856.809l-.416.322a1.26 1.26 0 0 0-.451.615c-.047.149-.072.379-.076.692H7.18c.023-.66.086-1.117.187-1.369.102-.252.363-.542.785-.87l.428-.334c.14-.105.254-.22.34-.345.156-.215.234-.452.234-.71 0-.296-.087-.567-.26-.811-.174-.244-.492-.366-.953-.366-.453 0-.774.15-.963.451-.19.3-.285.613-.285.938H5z" />
			</g>
		</svg>
	);
}
