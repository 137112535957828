/* eslint-disable max-len */
import React from 'react';

export default function FreeTruckRental() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30" className="svg">
			<title>
				Free Truck
			</title>
			<g id="truck">
				<path id="Fill-1" d="M24.9 16.8h-1.1-.1c-.3-1-1.2-1.7-2.2-1.7-1 0-1.9.7-2.2 1.6h-1.9V5.4h3.9c.4 0 .8.3.9.8l1 5.6c0 .1.1.3.2.3l1.5 1.1v3.6zm-3.4 1.7c-.6 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .5 1 1s-.4 1-1 1zm-5.1-1.7H8.9c-.2-1.4-1.3-2.4-2.7-2.4s-2.6 1.1-2.7 2.4H2.1V1.1h12.2c1.2 0 2.1.9 2.1 2.2v13.5zM6.2 18.3c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.5 1.2-1.2 1.2zm19.6-5.9l-1.5-1.1-1-5.4c-.2-.9-1-1.7-1.9-1.7h-3.9v-1c0-1.9-1.4-3.3-3.1-3.3H1.5c-.3.1-.5.3-.5.6v16.8c0 .3.2.5.5.5h2c.3 1.2 1.4 2 2.7 2 1.3 0 2.3-.9 2.7-2h10.3c.2 1.1 1.1 1.9 2.3 1.9 1.2 0 2.1-.8 2.3-1.9H25.5c.3 0 .5-.2.5-.5v-4.5c0-.1-.1-.3-.2-.4z" />
				<path id="Fill-4" d="M19.2 10.4V7.5h1.2c.2 0 .4.1.4.3l.5 2.5h-2.1zm2.6-2.8c-.1-.7-.7-1.2-1.5-1.2h-1.7c-.3 0-.5.2-.5.5v3.9c0 .3.2.5.5.5h3.2c.2 0 .3-.1.4-.2.1-.1.1-.3.1-.4l-.5-3.1z" />
			</g>
			<g>
				<path d="M5.1 24.1H2.8v1.3h2.1V27H2.8v3h-2v-7.5h4.3v1.6zM13.1 30h-2.4l-1.9-2.9V30h-2v-7.5h3c.4 0 .8.1 1.1.2.3.1.6.3.8.5.2.2.4.5.5.7.1.3.2.6.2.9 0 .6-.1 1-.4 1.4-.3.4-.7.6-1.2.7l2.3 3.1zm-4.3-4.2h.4c.4 0 .7-.1.9-.2.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.5-.2-.9-.2h-.4v1.8zM18.9 24.1h-2.3v1.3h2.2V27h-2.2v1.3h2.3V30h-4.3v-7.5h4.3v1.6z" />
			</g>
			<path d="M25.2 24.1h-2.3v1.3H25V27h-2.2v1.3h2.3V30h-4.3v-7.5h4.3v1.6z" />
		</svg>
	);
}
