import React, { PureComponent, Fragment } from 'react'; 
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuItem from 'components/brand/Header/MenuItem';
import DealsLink from 'components/brand/Header/DealsLink';
import { ChevronDown } from 'components/svg';
import withCollapsibleMenuHandlers from 'components/brand/Header/withCollapsibleMenuHandlers';
const links = [
    { href: '/storage.html', text: 'Find Self-Storage' },
    {
        text: 'Storage Types',
        innerLinks: [
            { href: '/car-storage.html', text: 'Car Storage' },
            { href: '/rv-storage.html', text: 'RV Storage' },
            { href: '/boat-storage.html', text: 'Boat Storage' },
            { href: '/college-storage.html ', text: 'College Storage' },
            { href: '/portable-storage.html', text: 'Portable Storage' },
            { href: '/business-storage.html', text: 'Business Storage' },
            {
                href: '/climate-controlled-storage.html',
                text: 'Climate Controlled'
            }
        ]
    },
    {
        text: 'Storage 101',
        innerLinks: [
            {
                href: 'https://storageguide.sparefoot.com/self-storage-amenities/ ',
                text: 'Storage Unit Amenities'
            },
            {
                href: 'https://storageguide.sparefoot.com/the-basics/ ',
                text: 'Storage FAQ'
            },
            {
                href: 'https://storageguide.sparefoot.com/storage-unit-safety-and-security/  ',
                text: 'Storage Security'
            },
            {
                href: 'https://www.sparefoot.com/storage-unit-size-guide.html ',
                text: 'Storage Size Guide'
            },
            {
                href: 'https://sparefootblogs.wpengine.com/category/industry-trends/',
                text: 'Storage Beat'
            }
        ]
    },
    {
        text: 'Learn',
        innerLinks: [
            {
                href: 'https://sparefootblogs.wpengine.com/category/general-storage-advice/',
                text: 'General Storage Advice'
            },
            {
                href: 'https://sparefootblogs.wpengine.com/category/how-to-store/',
                text: 'How to Store It'
            },
            {
                href: 'https://sparefootblogs.wpengine.com/category/moving-tips/',
                text: 'Moving Tips'
            },
            {
                href: 'https://sparefootblogs.wpengine.com/category/getting-organized/',
                text: 'Getting Organized'
            },
            {
                href: 'https://sparefootblogs.wpengine.com/category/city-guides/',
                text: 'City Guides'
            }
        ]
    },
    { href: 'https://storageguide.sparefoot.com/', text: 'Help Center' },
    { href: 'https://about.sparefoot.com/ ', text: 'About Us' },
    {
        customItem: <DealsLink className="menu-secondary" />
    },
    {
        href: 'https://marketing.sparefoot.com',
        text: 'Add Your Facility',
        rightAlign: true
    }
];
export const enhance = withCollapsibleMenuHandlers;
export class HeaderMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.menuId = 'mobile-menu-toggle';
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        // if event.target is outside of the dropdown, close the dropdown
        const { className } = event.target;
        const element = document.getElementById(this.menuId);
        // Prevents links in menu or dropdown icons from counting as a "click outside"
        const isLinkOrText = !!className && typeof className === 'string' && className?.includes('inner-accordion-safe');
        // Needs typeof check in case of closing menu by top arrow on mobile
        if (!isLinkOrText && typeof (className) !== 'object') {
            if (element.checked) {
                element.checked = false;
            }
        }
    }
    render() {
        return (
            <nav
                className={classnames('header-menu', {
                    expanded: this.props.menuExpanded
                })}
                ref={this.bindRef}
            >
                <div className="menu-toggle-button">
                    <label htmlFor="mobile-menu-toggle">
                        <span className="hidden-text">Show Menu</span>
                        <input
                            name="mobile-menu-toggle"
                            type="checkbox"
                            id="mobile-menu-toggle"
                            className="visually-hidden"
                        />
                        <ChevronDown />
                    </label>
                </div>
                <div className="link-container">
                    {links.map((link, idx) => (link.customItem ? (
                        <Fragment key={link.customItem}>
                            {link.customItem}
                        </Fragment>
                    ) : (
                        <MenuItem {...link} key={link.text} id={idx} />
                    )))}
                </div>
            </nav>
        );
    }
}
HeaderMenu.propTypes = {
    menuExpanded: PropTypes.bool,
    onClickOutside: PropTypes.func,
    toggleMenu: PropTypes.func,
    viewportWidth: PropTypes.number
};
export default enhance(HeaderMenu);