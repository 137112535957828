import { get } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import { PAGES } from 'store/pages/actions';

export const initialState = {};

// Content is basically just a dumping ground for unstructured static content,
// beware of collisions
export default handleActions({
	[PAGES.HOMEPAGE_SUCCESS]: (state, { payload }) => ({
		...state,
		featuredCities: payload.featuredCities
	}),
	[PAGES.STATE_LANDING_PAGE_SUCCESS]: (state, { payload }) => ({
		...state,
		stateLanding: {
			contentId: payload.content.contentId,
			fullState: payload.location.fullState,
			state: payload.location.state,
			allCities: get(payload, 'content.allCities', []).map((city) => ({ ...city, city: `${city.city} Self-Storage` })),
			featuredCities: get(payload, 'content.featuredCities', []).map((city) => ({ ...city, city: `${city.city} Self-Storage` })),
			bodyContent: payload.meta.body ? encodeURIComponent(payload.meta.body) : ''
		}
	}),
	[combineActions(
		PAGES.MOVING_TRUCK_PAGE_SUCCESS,
		PAGES.MOVING_TRUCK_CLP_SUCCESS
	)]: (state, { payload }) => {
		const truckResults = payload.entities.search[payload.result.truckResults];
		const penskeResults = payload.entities.search[payload.result.penskeResults];

		return {
			...state,
			truckResultsSearchId: truckResults.searchId,
			penskeResultsSearchId: penskeResults.searchId,
			movingTruckPSP: {
				city: get(truckResults, 'location.city'),
				state: get(truckResults, 'location.state'),
				bellhopsURL: get(payload.result, 'bellhopsURL'),
				penskeURL: get(payload.result, 'penskeURL'),
				isBellhopsMarket: get(payload.result, 'isBellhopsMarket'),
				truckResultsURL: get(payload.result, 'truckResultsURL'),
				penskeResultsURL: get(payload.result, 'penskeResultsURL'),
				truckResults: get(truckResults, 'facilities', []),
				penskeResults: get(penskeResults, 'facilities', []),
				canonicalUrl: get(payload, 'result.seo.canonical', ''),
				content: get(payload.result, 'content')
			}
		};
	},
	[PAGES.PORTABLE_STORAGE_CLP_SUCCESS]: (state, { payload }) => ({
		...state,
		portableStoragePSPCLP: {
			location: get(payload, 'location'),
			canonicalUrl: get(payload, 'seo.canonical', ''),
			content: get(payload, 'content')
		}
	}),
	[PAGES.PORTABLE_STORAGE_PAGE_SUCCESS]: (state, { payload }) => ({
		...state,
		portableStoragePSPCLP: {
			canonicalUrl: get(payload, 'seo.canonical', '')
		}
	})

}, initialState);
