import { get } from 'lodash';
import { stringify } from 'querystring';

function makeTrackingPixelSrc(type, params = {}) {
	const query = stringify(params);
	return `${type}${query ? `?${query}` : ''}`;
}

export function getIndexViewPixel() {
	return makeTrackingPixelSrc('index');
}

export function getStaticViewPixel() {
	return makeTrackingPixelSrc('static');
}

export function getBookingViewPixel(confirmationCode) {
	return makeTrackingPixelSrc('confirmation', {
		cc: confirmationCode
	});
}

export function getPaidLandingViewPixel(paidType) {
	return makeTrackingPixelSrc(`${paidType}Paid`);
}

export function getPSPLandingViewPixel(pageType) {
	return makeTrackingPixelSrc(`${pageType}PSP`);
}

export function getLandingViewPixel({ result = {}, content = {} }) {
	return makeTrackingPixelSrc('siteContent', 	{
		searchId: get(result, 'searchId', ''),
		facilityIds: get(result, 'facilities', []).join(','),
		searchWeightId: -1,
		scid: get(content, 'contentId', '')
	});
}

export function getErrorViewPixel(errorType) {
	return makeTrackingPixelSrc(errorType);
}

export function getUnitViewPixel(unitId, facilityId) {
	return makeTrackingPixelSrc('unitConfirmation', {
		uid: unitId,
		fid: facilityId
	});
}

export function getSearchViewPixel({ searchId = '', facilities = [] } = {}) {
	return makeTrackingPixelSrc('search', {
		searchId,
		facilityIds: facilities.join(','),
		searchWeightId: -1
	});
}

export function getSearchLandingViewPixel(data) {
	const search = data.entities.search[data.result.search];
	return makeTrackingPixelSrc('siteContent', 	{
		searchId: get(search, 'searchId', ''),
		facilityIds: get(search, 'facilities', []).join(','),
		searchWeightId: -1,
		scid: get(data.result.content, 'contentId', '')
	});
}

export function getFacilityViewPixel({ facility }) {
	return makeTrackingPixelSrc('facilityDetail', {
		fid: facility
	});
}

export function getHiddenFacilityViewPixel({ facility }) {
	return makeTrackingPixelSrc('hiddenFacilityDetail', {
		fid: facility
	});
}
