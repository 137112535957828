/* eslint-disable import/prefer-default-export */
import { stringify } from 'querystring';
import { pick, omit, pickBy } from 'lodash';

export const allowedSearchParams = [
	'city',
	'state',
	'location',
	'searchType',
	'moveInDate',
	'order',
	'page',
	'latitude',
	'longitude',
	'boundary',
	'sqft',
	'listingsPerPage',
	'vehicle',
	'amenity',
	'neighborhood',
	'zip',
	'allTruckRental',
	'truckRental',
	'freeTruckRental',
	'isPenske',
	'minLength',
	'maxLength',
	'vehicleAccommodation',
	'twentyFourHourAccess',
	'eGateAccess',
	'driveUp',
	'kiosk',
	'alarm',
	'fencedAndLit',
	'cameras',
	'pullthrough',
	'isPenske',
	'getOpenNow',
	'hasSpecial',
	'vehicleTitleNotRequired',
	'vehicleRegistrationNotRequired',
	'vehicleInsuranceNotRequired',
	'vehicleDrivableNotRequired',
	'size',
	'subPageType',
	'informedSearch',
	'firstFloor',
	'climateControlled',
	'hasContactlessMoveins',
	'includeBadgedFacilities', // sf_9463_sfdc_tpp_badging
	'landingPageSize' // added to allow 5x5/10x10 landing pages
];



// Builds a search URL based off filtered search params object
export function buildSearchLink(searchParams, searchPath) {
	const searchBase = searchPath || '/search.html';
	let queryParams = pick(searchParams, allowedSearchParams);

	const omitConditions = [
		// Default Params
		{ condition: queryParams.page === 1, params: ['page'] },
		{ condition: queryParams.listingsPerPage === '15', params: ['listingsPerPage'] },
		{ condition: queryParams.searchType === 'storage', params: ['searchType'] },
		{ condition: ['recommended', 'distance_top_5_dumpling'].includes(queryParams.order), params: ['order'] },
		// Conditional Params
		{ condition: queryParams.twentyFourHourAccess || queryParams.climateControlled, params: ['amenity'] },
		{ condition: queryParams.landingPageSize, params: ['landingPageSize'] },
		{ condition: queryParams.location, params: ['city', 'state'] },
		{ condition: queryParams.latitude && queryParams.longitude, params: ['city', 'state'] },
		{ condition: queryParams.boundary, params: ['location'] }
	  ];

	  omitConditions.forEach(({ condition, params }) => {
		if (condition) {
		  queryParams = omit(queryParams, params);
		}
	  });

	queryParams = pickBy(queryParams);

	return `${searchBase}?${stringify(queryParams)}`;
}

export function buildStartHereLink(searchParams) {
	let queryParams = pick(searchParams, allowedSearchParams);

	if (queryParams.boundary) {
		queryParams = omit(queryParams, ['location']);
	}

	if (queryParams.latitude && queryParams.longitude) {
		queryParams = omit(queryParams, ['city', 'state']);
	}

	// Filter false values (for truck params)
	queryParams = pickBy(queryParams);

	let { city, state } = queryParams;
	const { informedSearch } = queryParams;

	if (city && state) {
		const tempCity = city.split(' ').join('-');

		return `/${tempCity}-${state}-self-storage.html?city=${encodeURIComponent(
			city
		)}&state=${state}&informedSearch=${informedSearch}`;
	}

	if (!city && !!queryParams.location) {
		const locationParts = queryParams.location.split(',');
		city = locationParts[0].trim().split(' ').join('-');
		state = locationParts[1].trim();

		return `/${city}-${state}-self-storage.html?city=${encodeURIComponent(
			locationParts[0].trim()
		)}&state=${state}&informedSearch=${informedSearch}`;
	}

	return '';
}
