import { compose, withHandlers, lifecycle } from 'recompose';

export default compose(
	withHandlers({
		handleClickOutside: ({ outsideClickHandler, outsideParent, outsideCondition }) => (event) => {
			if (outsideCondition) {
				const isChild = event.target?.closest(outsideParent);
				// Check if clicked element is not a link or button inside the component
				const isClickTagInsideComponent = isChild &&
					(event.target?.tagName === 'BUTTON' || event.target?.parentNode?.tagName === 'A');

				if (!isClickTagInsideComponent) {
					outsideClickHandler();
				}

				if (!isChild) {
					outsideClickHandler();
				}
			}
		}
	}),
	lifecycle({
		componentDidMount() {
			document.addEventListener('mousedown', this.props.handleClickOutside);
		},
		componentWillUnmount() {
			document.removeEventListener('mousedown', this.props.handleClickOutside);
		}
	})
);
