import { isFunction } from 'lodash';
import { withHandlers, compose } from 'recompose';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from '../context/getSegmentContext';

const withSegmentSelectTracking = compose(
	getSegmentContext,
	withHandlers(({
		onChange, onFocus, segmentConfig, segmentProperties, segmentLabel
	}) => ({
		onChange: () => (evt) => {
			const segmentProps = SegmentConfig.buildTrackingProps(
				segmentConfig,
				{
					segmentProperties,
					segmentLabel: `${evt.target.value}-selected`
				}
			);

			Segment.trackClick(segmentProps);

			if (isFunction(onChange)) {
				onChange(evt);
			}
		},
		onFocus: () => (evt) => {
			const segmentProps = SegmentConfig.buildTrackingProps(
				segmentConfig,
				{ segmentProperties, segmentLabel }
			);

			Segment.track('focus', segmentProps);

			if (isFunction(onFocus)) {
				onFocus(evt);
			}
		}
	}))
);

export default withSegmentSelectTracking;
