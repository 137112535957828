import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, lifecycle, withStateHandlers, withPropsOnChange } from 'recompose';
import { addEventListener, removeEventListener } from 'utils/dom/dom';

const TRANSPARENT_GIF = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export function inViewport(el) {
	if (!el) {
		return false;
	}

	const rect = el.getBoundingClientRect();

	return (
		rect.bottom >= -200 &&
		rect.right >= 0 &&
		rect.top <= ((window.innerHeight || document.documentElement.clientHeight) + 200) &&
		rect.left <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

export function lazyLoad(WrappedComponent) {
	function LazyLoadContainer({ setRef, ...props }) {
		return (
			<span ref={setRef} className="ph-image-wrapper">
				<WrappedComponent {...props} />
			</span>
		);
	}

	LazyLoadContainer.propTypes = {
		setRef: PropTypes.func
	};

	LazyLoadContainer.defaultProps = {
		setRef: noop
	};

	return LazyLoadContainer;
}

const windowEvents = ['scroll', 'resize'];

export default compose(
	withStateHandlers({
		isVisible: false,
		el: null
	}, {
		setRef: () => (el) => ({ el }),
		handleViewportChange: ({ el, isVisible }) => () => (isVisible ? null : ({
			isVisible: inViewport(el)
		}))
	}),
	withPropsOnChange(
		['src', 'isVisible'],
		({ src, isVisible }) => ({ src: isVisible ? src : TRANSPARENT_GIF })
	),
	lifecycle({
		componentDidMount() {
			addEventListener(window, windowEvents, this.props.handleViewportChange);
			setTimeout(this.props.handleViewportChange, 75);
		},
		componentWillUnmount() {
			removeEventListener(window, windowEvents, this.props.handleViewportChange);
		}
	}),
	lazyLoad
);
