import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { overwriteDisplayName } from 'decorators/setWrappedDisplayName';
import { withSegmentContext } from 'components/core/library/segment';

import './Section.scss';

const enhance = compose(
	overwriteDisplayName('Section'),
	withSegmentContext
);

export function Section({ children, className }) {
	return (
		<div className={classnames('page-section', className)}>
			{children}
		</div>
	);
}

Section.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	className: PropTypes.string
};

export default enhance(Section);
