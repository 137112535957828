
// Namespaces Dispatch Actions to an actions object
export default (stateProps, dispatchProps, ownProps) => ({
	...ownProps,
	...stateProps,
	actions: {
		...dispatchProps,
		...ownProps.actions
	}
});
