/* eslint-disable max-len */
import React from 'react';

export default function Instagram() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="504" height="504" viewBox="0 0 503.84 503.84" className="svg instagram">
			<title>Instagram</title>
			<g fill="#fc0">
				<path d="M251.92 45.39c67.27 0 75.23.26 101.8 1.47 24.56 1.12 37.9 5.22 46.78 8.67a78.05 78.05 0 0 1 29 18.84 78.05 78.05 0 0 1 18.84 29c3.45 8.88 7.55 22.22 8.67 46.78 1.21 26.56 1.47 34.53 1.47 101.8s-.26 75.23-1.47 101.8c-1.12 24.56-5.22 37.9-8.67 46.78a83.43 83.43 0 0 1-47.81 47.81c-8.88 3.45-22.22 7.55-46.78 8.67-26.56 1.21-34.53 1.47-101.8 1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78.05 78.05 0 0 1-29-18.84 78.05 78.05 0 0 1-18.84-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.56-1.47-34.53-1.47-101.8s.26-75.23 1.47-101.8c1.12-24.56 5.22-37.9 8.67-46.78a78.05 78.05 0 0 1 18.84-29 78.05 78.05 0 0 1 29-18.84c8.88-3.45 22.22-7.55 46.78-8.67 26.56-1.21 34.53-1.47 101.8-1.47m0-45.39c-68.42 0-77 .29-103.87 1.52S102.93 7 86.9 13.23a123.49 123.49 0 0 0-44.62 29.05A123.49 123.49 0 0 0 13.23 86.9C7 102.93 2.74 121.24 1.52 148.05S0 183.5 0 251.92s.29 77 1.52 103.87S7 400.91 13.23 416.94a123.49 123.49 0 0 0 29.06 44.62 123.49 123.49 0 0 0 44.61 29.06c16 6.23 34.34 10.49 61.15 11.71s35.45 1.52 103.87 1.52 77-.29 103.87-1.52 45.13-5.48 61.15-11.71a128.82 128.82 0 0 0 73.68-73.68c6.23-16 10.49-34.34 11.71-61.15s1.52-35.45 1.52-103.87-.29-77-1.52-103.87-5.48-45.13-11.71-61.15a123.49 123.49 0 0 0-29.06-44.62 123.49 123.49 0 0 0-44.62-29.06C400.91 7 382.6 2.74 355.79 1.52S320.34 0 251.92 0z" />
				<path d="M251.92 122.56a129.36 129.36 0 1 0 129.37 129.36 129.36 129.36 0 0 0-129.37-129.36zm0 213.34a84 84 0 1 1 84-84 84 84 0 0 1-84 83.99z" />
				<circle cx="386.4" cy="117.44" r="30.23" />
			</g>
		</svg>
	);
}
