/* eslint-disable max-len */
import React from 'react';

export default function SelfStorage() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40.22" height="32.75" viewBox="0 0 40.22 32.75" className="svg self-storage">
			<path d="M38.21,0H2A2,2,0,0,0,0,2v4.6a2,2,0,0,0,2,2H2.3V31.89a.86.86,0,0,0,.86.86h33.9a.86.86,0,0,0,.86-.86V8.62h.29a2,2,0,0,0,2-2V2A2,2,0,0,0,38.21,0ZM8,12.38a.78.78,0,0,1,.58-.31H31.89a.29.29,0,0,1,.29.29v1.44H8Zm24.14,8.31H8V19H32.18Zm0,1.72v1.72H8V22.41Zm0-5.17H8V15.51H32.18ZM8,25.86H32.18v1.72H8ZM8,31V29.31H32.18V31ZM36.2,31H33.9V12.35a2,2,0,0,0-2-2H8.62a2.26,2.26,0,0,0-2.3,2V31H4V8.62H36.2ZM38.5,6.61a.29.29,0,0,1-.29.29H2a.29.29,0,0,1-.29-.29V2A.29.29,0,0,1,2,1.72h36.2A.29.29,0,0,1,38.5,2Z" />
		</svg>
	);
}
