import { map, get } from 'lodash';
import flattenCollection from './flattenCollection';
import formatFacility from './formatFacility';
import formatUnit from './formatUnit';

const formatSearchFacilities = (payload, state) => {
	if (!payload.search) { return payload; }

	const formattedPayload = { ...payload };
	const facilities = flattenCollection(payload.search.facilityCollection);

	formattedPayload.search.facilities =
	map(facilities, (facility) => ({
		...formatFacility(facility, state),
		units: map(facility.units, (unit) => ({
			...formatUnit(unit),
			facilityUrl: facility.url.facility
		}))
	}));

	return formattedPayload;
};


export function formatFacilityResponse(payload, state) {
	// sf_5198_oon_facility_page / format nearby facilities for OON Pges
	const nearbyFacilities = get(payload, 'facility.nearby.facilities', []);

	return {
		...payload,
		facility: {
			...formatFacility(payload.facility, state),
			units: flattenCollection(payload.units).map((unit) => ({
				...formatUnit(unit),
				facilityUrl: payload.facility.url.facility
			})),
			nearby: {
				...(payload.facility.nearby || {}),
				facilities: nearbyFacilities.map((nearbyFacility) => ({
					...formatFacility(nearbyFacility, state),
					units: get(nearbyFacility, 'units', [])
					.map((unit) => ({ ...unit, facilityUrl: nearbyFacility.url.facility }))
				}))
			}
		}
	};
}

export default formatSearchFacilities;
