import { createAction } from 'redux-actions';
import { API } from '../middleware/api';

export const UNITS = {
	LOOKUP_REQUEST: 'SFDC/UNITS/LOOKUP_REQUEST',
	LOOKUP_SUCCESS: 'SFDC/UNITS/LOOKUP_SUCCESS',
	LOOKUP_FAILURE: 'SFDC/UNITS/LOOKUP_FAILURE'
};

export const unitLookupSuccess = createAction(UNITS.LOOKUP_SUCCESS);
export const unitLookupFailure = createAction(UNITS.LOOKUP_FAILURE);

export function lookupUnit(facilityId, unitId) {
	return {
		[API]: {
			types: [UNITS.LOOKUP_REQUEST, UNITS.LOOKUP_SUCCESS, UNITS.LOOKUP_FAILURE],
			endpoint: `/unitPage/${facilityId}/${unitId}`
		}
	};
}
