import { handleActions, combineActions } from 'redux-actions';
import { PAGES } from 'store/pages/actions';
import { SEARCH } from 'store/search/actions';
import { BOOKINGS } from 'store/bookings/actions';
import { FACILITIES } from 'store/facilities/actions';
import { UNITS } from './actions';

export const initialState = {
	loading: false,
	error: false,
	errorMessage: null,
	byId: {}
};

const successAction = combineActions(
	FACILITIES.SUCCESS,
	FACILITIES.FACILITY_PAGE_SUCCESS,
	SEARCH.SUCCESS,
	SEARCH.SEARCH_LANDING_SUCCESS,
	SEARCH.STORAGE_DEALS_SUCCESS,
	SEARCH.PAGE_SUCCESS,
	PAGES.BOOKING_PAGE_SUCCESS,
	PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS,
	BOOKINGS.RECENT_BOOKING_SUCCESS,
	BOOKINGS.FROM_CONFIRMATION_CODE_SUCCESS,
	UNITS.LOOKUP_SUCCESS,
	PAGES.CAR_PSP_PAGE_SUCCESS,
	PAGES.STORAGE_PAGE_SUCCESS,
	PAGES.UNIT_PAGE_SUCCESS,
	PAGES.MOVING_TRUCK_PAGE_SUCCESS,
	PAGES.MOVING_TRUCK_CLP_SUCCESS
);

const reducer = handleActions({
	[successAction]: (state, action) => ({
		...state,
		byId: {
			...state.byId,
			...action.payload.entities.units
		}
	})
}, initialState);


export default reducer;
