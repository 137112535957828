import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, isFunction } from 'lodash';
import classnames from 'classnames';
import { CoreInput } from 'components/core/library/input';
import { Label } from 'components/core/Label';
import './input.scss';

/**
 * SFDC Styled Input component [DEPRICATED]. Extends the [CoreInput](#coreinput) component, which automagically handles
 * Segment](#segment-tracking) tracking for `focus`, `valid`, and `invalid` events.
 */
export default class Input extends PureComponent {
	static propTypes = {
		/** Renders a larger input box */
		large: PropTypes.bool,
		/** The type of the input box */
		type: PropTypes.string,
		/** Indicates whether the input box is disabled */
		disabled: PropTypes.bool,
		/** The label for the input box */
		label: PropTypes.node.isRequired,
		/** The id of the input box (used to set the label) */
		id: PropTypes.string.isRequired,
		/** Indicates whether the input is valid */
		hasError: PropTypes.bool,
		/** The error message to be displayed on failed input validation */
		errorMessage: PropTypes.node,
		/** A function that will receive the current input and should return `true` if the input is valid
		 * and `false` otherwise. */
		validator: PropTypes.func,
		/** Function called on each change to the input box */
		onChange: PropTypes.func,
		/** Function called on each focus to the input box */
		onFocus: PropTypes.func,
		/** Function returns the reference to the DOM input element */
		onBlur: PropTypes.func,
		getRef: PropTypes.func,
		/** Initial value for the input */
		value: PropTypes.string,
		className: PropTypes.string,
		segmentLabel: PropTypes.string,
		name: PropTypes.string,
		placeholder: PropTypes.string
	};

	static defaultProps = {
		large: false,
		type: 'text',
		disabled: false,
		value: '',
		hasError: false
	};

	constructor(props) {
		super(props);

		this.state = {
			hasError: props.hasError || false,
			value: props.value || ''
		};
	}

	componentWillReceiveProps(nextProps) {
		const nextState = {};

		if (this.state.value !== nextProps.value) {
			nextState.value = get(nextProps, 'value', this.state.value) || '';
		}
		if (this.state.hasError !== nextProps.hasError) {
			nextState.hasError = get(nextProps, 'hasError', this.state.hasError);
		}

		this.setState(nextState);
	}

	_onChange = (evt) => {
		const { value } = evt.target;
		const newState = { value };

		if (isFunction(this.props.validator)) {
			newState.hasError = this.props.validator(value);
		}

		this.setState(newState);
		if (isFunction(this.props.onChange)) {
			this.props.onChange(evt);
		}
	};

	_onFocus = (event) => {
		if (isFunction(this.props.onFocus)) {
			this.props.onFocus(event);
		}
	};


	render() {
		return (
			<Label
				className={this.props.className}
				htmlFor={this.props.id}
				text={this.props.label}
				textClassName="input"
			>
				<CoreInput
					className={classnames({
						[`${this.props.type}-input-large`]: this.props.large,
						[`${this.props.type}-input-standard`]: !this.props.large,
						disabled: this.props.disabled,
						error: this.state.hasError
					})}
					disabled={this.props.disabled}
					id={this.props.id}
					onChange={this._onChange}
					onBlur={this.props.onBlur}
					onFocus={this._onFocus}
					name={this.props.name}
					value={this.state.value}
					valid={this.state.value && this.state.value.length ? !this.state.hasError : undefined}
					type="text"
					segmentLabel={this.props.segmentLabel}
					getRef={this.props.getRef}
					placeholder={this.props.placeholder}
				/>
				{!!this.state.hasError &&
					<div className="input-error" key="input-error">
						{this.props.errorMessage}
					</div>
				}
			</Label>
		);
	}
}
