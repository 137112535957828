/* eslint-disable max-len */
import React from 'react';

export default function CrossedCircle() {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" className="svg crossed-circle" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 19.067c-1.694 0-3.268-.528-4.567-1.426L17.64 6.434c.9 1.3 1.427 2.872 1.427 4.567 0 4.447-3.62 8.067-8.067 8.067M2.933 11c0-4.448 3.62-8.067 8.067-8.067 1.694 0 3.268.528 4.567 1.426L4.36 15.566c-.9-1.3-1.427-2.873-1.427-4.567M11 0C4.934 0 0 4.934 0 11s4.934 11 11 11 11-4.934 11-11S17.066 0 11 0" fill="#9B9B9B" fillRule="evenodd" />
		</svg>
	);
}
