/* eslint-disable max-len */
import React from 'react';

export default function X() {
	return (
		<svg className="svg x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
			<g id="Layer_2-2" data-name="Layer 2">
				<path d="M10.83,8l4.59-4.59A2,2,0,0,0,12.59.59L8,5.17,3.41.59A2,2,0,0,0,.59,3.41L5.17,8,.59,12.59a2,2,0,1,0,2.83,2.83L8,10.83l4.59,4.59a2,2,0,0,0,2.83-2.83Z" />
			</g>
		</svg>
	);
}
