import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isBefore from 'date-fns/is_before';
import subMonths from 'date-fns/sub_months';
import addMonths from 'date-fns/add_months';
import addWeeks from 'date-fns/add_weeks';
import startOfMonth from 'date-fns/start_of_month';
import endOfMonth from 'date-fns/end_of_month';
import startOfWeek from 'date-fns/start_of_week';
import endOfWeek from 'date-fns/end_of_week';
import format from 'date-fns/format';

import CalendarWeek from './week';
import CalendarHeader from './calendarHeader';
import CalendarDay from './day';

export default class CoreCalendar extends PureComponent {
	static propTypes = {
		today: PropTypes.instanceOf(Date),
		closures: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		reservationWindow: PropTypes.instanceOf(Date),
		headerComponent: PropTypes.func,
		dayComponent: PropTypes.func,
		calendarRef: PropTypes.func,
		headerLabel: PropTypes.node,
		onCloseClick: PropTypes.func,
		onDateClick: PropTypes.func,
		headerSegmentLabel: PropTypes.string,
		className: PropTypes.string,
		children: PropTypes.node,
		allValid: PropTypes.bool
	};

	static defaultProps = {
		today: new Date(),
		closures: [],
		headerComponent: CalendarHeader,
		dayComponent: CalendarDay,
		calendarRef: () => {},
		onCloseClick: () => {}
	};

	constructor(props) {
		super(props);

		this.state = {
			startMoment: this.props.today
		};
	}

	previousMonth = () => {
		this.setState((state) => ({ startMoment: subMonths(state.startMoment, 1) }));
	};

	nextMonth = () => {
		this.setState((state) => ({ startMoment: addMonths(state.startMoment, 1) }));
	};

	renderWeeks() {
		const today = this.state.startMoment;
		const firstDate = startOfWeek(startOfMonth(today));
		const lastDate = endOfWeek(endOfMonth(today));
		let tmp = firstDate;
		const weeks = [];
		while (isBefore(tmp, lastDate)) {
			weeks.push(<CalendarWeek
				key={format(tmp, 'YYMD')}
				start={tmp}
				resWindow={this.props.reservationWindow}
				closures={this.props.closures}
				dayComponent={this.props.dayComponent}
				onDateClick={this.props.onDateClick}
				currentMonth={startOfMonth(today)}
				allValid={this.props.allValid}
			/>);
			tmp = addWeeks(tmp, 1);
		}

		return weeks;
	}

	render() {
		return (
			<div ref={this.props.calendarRef} className={classnames('ph-calendar', this.props.className)}>
				{
					React.createElement(
						this.props.headerComponent,
						{
							month: this.state.startMoment,
							onPreviousClick: this.previousMonth,
							onNextClick: this.nextMonth,
							headerLabel: this.props.headerLabel,
							onCloseClick: this.props.onCloseClick,
							today: this.props.today,
							segmentLabel: this.props.headerSegmentLabel,
							allValid: this.props.allValid
						}
					)
				}
				<table className="ph-calendar-body">
					<thead>
						<tr className="ph-calendar-week">
							<th key="sun">Su</th>
							<th key="mon">Mo</th>
							<th key="tue">Tu</th>
							<th key="wed">We</th>
							<th key="thu">Th</th>
							<th key="fri">Fr</th>
							<th key="sat">Sa</th>
						</tr>
					</thead>
					<tbody>
						{this.renderWeeks()}
					</tbody>
				</table>
				{this.props.children}
			</div>
		);
	}
}
