/* eslint-disable max-len */
import React from 'react';

export default function TruckRentalText() {
	return (
		<svg viewBox="0 0 24 26">
			<title>
				ic-truck-rental
			</title>
			<path d="M8.4 23.6h1.8v-1.3H8.4v-1h1.8V20H6.8v6h3.5v-1.4H8.4v-1M18.5 5.8H17a.47.47 0 0 0-.5.5v3.5a.47.47 0 0 0 .5.5h2.9a.76.76 0 0 0 .4-.2.6.6 0 0 0 .1-.4l-.6-2.8a1.38 1.38 0 0 0-1.3-1.1zm-1.1 3.6V6.8h1.1c.2 0 .3.1.4.3l.4 2.3zM4.7 22.9a1.8 1.8 0 0 0 .3-1.1 1.7 1.7 0 0 0-.1-.7 2.09 2.09 0 0 0-.4-.6.92.92 0 0 0-.6-.4A2.77 2.77 0 0 0 3 20H.6v6h1.5v-2.3L3.6 26h2l-1.9-2.5a1.44 1.44 0 0 0 1-.6zm-1.5-.4a1.45 1.45 0 0 1-.7.2h-.3v-1.6h.3a1.45 1.45 0 0 1 .7.2 1.07 1.07 0 0 1 .2.6c0 .3 0 .5-.2.6z" />
			<path d="M23.3 11.2l-1.4-1-.9-4.9a1.81 1.81 0 0 0-1.7-1.5h-3.5V3A2.85 2.85 0 0 0 13 0H1.5a.47.47 0 0 0-.5.5v15.1a.47.47 0 0 0 .5.5h1.8a2.58 2.58 0 0 0 2.4 1.8 2.58 2.58 0 0 0 2.4-1.8h9.4a2.06 2.06 0 0 0 2.1 1.7 2.25 2.25 0 0 0 2.1-1.7H23a.47.47 0 0 0 .5-.5v-4a.52.52 0 0 0-.2-.4zM5.7 16.5a1.1 1.1 0 1 1 1.1-1.1 1.11 1.11 0 0 1-1.1 1.1zm9.2-1.4H8.1a2.5 2.5 0 0 0-2.5-2.2 2.58 2.58 0 0 0-2.5 2.2H2V1h11a1.92 1.92 0 0 1 1.9 2zm4.6 1.5a.9.9 0 1 1 .9-.9.9.9 0 0 1-.9.9zm2.9-1.5h-1a2.08 2.08 0 0 0-4 0h-1.7V4.8h3.5a.85.85 0 0 1 .8.7l.9 5c0 .1.1.2.2.3l1.3 1zM19 21.3h1.3V26h1.6v-4.7h1.2V20H19v1.3M16.3 23.7L13.4 20h-1.6v6h1.6v-3.7l2.9 3.7h1.6v-6h-1.6v3.7" />
		</svg>
	);
}
