import { getOffsetHeight } from 'utils/dom/dom';

export default function adBlockerCheck(Segment) {
	const testDiv = document.createElement('div');
	testDiv.innerHTML = '&nbsp;';
	testDiv.className = 'adsbox';
	document.body.appendChild(testDiv);

	setTimeout(() => {
		// If this element has been removed/hidden, then assume an adblocker is responsible
		if (getOffsetHeight(testDiv) === 0) {
			Segment.track('active', { category: 'Ad Blocker' });

		}
		document.body.removeChild(testDiv);
	}, 100);
}
