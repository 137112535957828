import PropTypes from 'prop-types';
import { withContext, compose } from 'recompose';
import { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from '../context/getSegmentContext';

export default (filterFunc) => compose(
	getSegmentContext,
	withContext({
		segmentConfig: PropTypes.object
	}, (props) => {
		const { segmentConfig } = props;
		const ecommerceProps = filterFunc(props);
		const newConfigProps = { ecommerceProps, trackEcommerceClick: true };
		return { segmentConfig: SegmentConfig.from(segmentConfig, newConfigProps) };
	})
);
