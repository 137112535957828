import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import { omit } from 'lodash';
import { compose, withHandlers, mapProps } from 'recompose';
import getSegmentContext from '../context/getSegmentContext';

const withSegmentFormTracking = compose(
	getSegmentContext,
	withHandlers({
		handleSubmit: (props) => (evt) => {
			evt.stopPropagation();
			evt.preventDefault();
			return Promise.resolve(props.onSubmit(evt))
			.then((payload) => {
				// Handle Redux-Form Submission Errors that resolve with an error
				const segmentLabel = (
					payload._error || props.invalid ?
						props.failureLabel :
						props.successLabel
				);

				const payloadSegmentConfig = payload.segmentConfig || {};

				const segmentProps = SegmentConfig.buildTrackingProps(
					props.segmentConfig,
					{
						segmentLabel,
						segmentProperties: {
							...props.segmentProperties,
							...payloadSegmentConfig.segmentProperties
						}
					}
				);
				Segment.track('submit', segmentProps);
			})
			.catch(() => {
				const segmentProps = SegmentConfig.buildTrackingProps(
					props.segmentConfig,
					{
						segmentLabel: props.failureLabel,
						segmentProperties: props.segmentProperties
					}
				);
				Segment.track('submit', segmentProps);
			});
		}
	}),
	mapProps((props) => ({
		...(omit(props, [
			'handleSubmit',
			'invalid',
			'segmentConfig',
			'successLabel',
			'failureLabel',
			'segmentProperties'
		])),
		onSubmit: props.handleSubmit
	}))
);

export default withSegmentFormTracking;
