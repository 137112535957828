import { pick } from 'lodash';

export default class BotDetectedError {
	constructor(message) {
		this.message = message || 'Please submit the additional field';
		this.type = 'BotDetectedError';
	}

	toJSON() {
		return pick(this, Object.getOwnPropertyNames(this));
	}
}
