import PropTypes from 'prop-types';
import {
	compose,
	defaultProps,
	mapProps,
	componentFromProp,
	setDisplayName,
	setPropTypes
} from 'recompose';
import classnames from 'classnames';

import './Typography.scss';

const withTypography = (element) => compose(
	setDisplayName(element.toUpperCase()),
	setPropTypes({
		size: PropTypes.oneOf(['tiny', 'small', 'smaller', 'standard', 'medium', 'tall', 'semiLarge', 'large', 'xlarge', 'huge']),
		color: PropTypes.oneOf(['red', 'gray', 'white', 'black', 'yellow', 'green', 'darkBlue', 'orange']),
		weight: PropTypes.oneOf(['light', 'standard', 'medium', 'bold', 'ultraBold']),
		highlight: PropTypes.oneOf(['yellow', 'green']),
		family: PropTypes.oneOf(['standard', 'rounded'])
	}),
	defaultProps({
		typographyComponent: element
	}),
	mapProps(
		(props) => (props.eyebrow ? ({ ...props, color: props.color || 'gray', size: 'tiny' }) : props)
	),
	mapProps(({
		children, className, eyebrow, size, color, typographyComponent, last, squareBackground, underline,
		strikethrough, id, weight, highlight, circleBackground, hasIcon, rightSpacing, topSpacing, family
	}) => ({
		id,
		children,
		typographyComponent,
		className: classnames(
			className,
			'sf-type',
			!!highlight && `sf-type-highlight ${highlight}`,
			!!strikethrough && 'sf-type-strike',
			!!underline && 'sf-type-underline',
			!!last && 'sf-type-last',
			!!size && `sf-type-${size}`,
			!!color && `sf-type-${color}`,
			!!eyebrow && 'sf-type-eyebrow',
			!!weight && `sf-type-weight-${weight}`,
			!!circleBackground && 'sf-type-circle-background',
			!!squareBackground && `sf-type-square-background ${squareBackground}`,
			!!rightSpacing && 'sf-type-right-spacing',
			!!topSpacing && 'sf-type-top-spacing',
			!!hasIcon && 'sf-type-has-icon',
			!!family && `sf-type-family-${family}`,
		)
	}))
)(componentFromProp('typographyComponent'));

export const H1 = withTypography('h1');
export const H2 = withTypography('h2');
export const H3 = withTypography('h3');
export const H4 = withTypography('h4');
export const H5 = withTypography('h5');
export const H6 = withTypography('h6');
export const Paragraph = withTypography('p');
export const Text = withTypography('span');
