/* eslint-disable max-len */
import React from 'react';

export default function MovingTruck() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="55.32" height="35.96" viewBox="0 0 55.32 35.96" className="svg moving-truck">
			<path fill="none" d="M.92 31h51.15a2.33 2.33 0 0 0 2.33-2.33v-4.1a5.08 5.08 0 0 0-1.07-3.12L50 17.12l-2.54-8.86A10.09 10.09 0 0 0 37.75.92H4A2.18 2.18 0 0 0 1.83 3.1v24.24l-.92.32z" />
			<path d="M52.07 31.9H.92A.92.92 0 0 1 0 31v-3.34a.92.92 0 0 1 .61-.86l.31-.11V3.1A3.11 3.11 0 0 1 4 0h33.75a11.05 11.05 0 0 1 10.6 8l2.46 8.68 3.25 4.18a6 6 0 0 1 1.26 3.68v4.1a3.25 3.25 0 0 1-3.25 3.26zM1.83 30.06h50.24a1.41 1.41 0 0 0 1.41-1.41v-4.1a4.18 4.18 0 0 0-.87-2.55l-3.36-4.32a.91.91 0 0 1-.16-.31l-2.51-8.86a9.21 9.21 0 0 0-8.83-6.68H4A1.27 1.27 0 0 0 2.75 3.1v24.24a.92.92 0 0 1-.61.86l-.31.11z" />
			<path fill="none" d="M.92 31h51.15a2.33 2.33 0 0 0 2.33-2.33v-4.1a5.08 5.08 0 0 0-1.07-3.12L50 17.12l-2.54-8.86A10.09 10.09 0 0 0 37.75.92H4A2.18 2.18 0 0 0 1.83 3.1v24.24l-.92.32z" />
			<path d="M52.07 31.9H.92A.92.92 0 0 1 0 31v-3.34a.92.92 0 0 1 .61-.86l.31-.11V3.1A3.11 3.11 0 0 1 4 0h33.75a11.05 11.05 0 0 1 10.6 8l2.46 8.68 3.25 4.18a6 6 0 0 1 1.26 3.68v4.1a3.25 3.25 0 0 1-3.25 3.26zM1.83 30.06h50.24a1.41 1.41 0 0 0 1.41-1.41v-4.1a4.18 4.18 0 0 0-.87-2.55l-3.36-4.32a.91.91 0 0 1-.16-.31l-2.51-8.86a9.21 9.21 0 0 0-8.83-6.68H4A1.27 1.27 0 0 0 2.75 3.1v24.24a.92.92 0 0 1-.61.86l-.31.11z" />
			<path d="M45.87 17l-10.62-.16a1.13 1.13 0 0 1-1-1.14V4.49a1 1 0 0 1 1-1h2.5a7.47 7.47 0 0 1 7.19 5.37l1.92 6.71a1.24 1.24 0 0 1-.17 1 1.14 1.14 0 0 1-.82.43zm-9.57-2.3l8.18.06-1.54-5.37a5.37 5.37 0 0 0-5.18-3.85H36.3z" />
			<circle cx="15.51" cy="30.98" r="4.06" />
			<path d="M15.51 36a5 5 0 1 1 5-5 5 5 0 0 1-5 5zm0-8.12A3.14 3.14 0 1 0 18.65 31a3.14 3.14 0 0 0-3.14-3.16z" />
			<circle cx="47.6" cy="30.98" r="4.06" />
			<path d="M47.6 36a5 5 0 1 1 5-5 5 5 0 0 1-5 5zm0-8.12A3.14 3.14 0 1 0 50.74 31a3.14 3.14 0 0 0-3.14-3.16z" />
			<circle fill="#fff" cx="15.51" cy="30.98" r="2.18" />
			<circle fill="#fff" cx="47.6" cy="30.98" r="2.18" />
			<path d="M32.11.83v29.28M32.11 31.16c-.58 0-1 .53-1 0V1.83a1 1 0 0 1 2.09 0v29.28c-.04.58-.51.05-1.09.05zM7 27.84H4.74a.92.92 0 1 1 0-1.83H7a.92.92 0 1 1 0 1.83zM37.52 20.29h-2.64a.92.92 0 1 1 0-1.83h2.64a.92.92 0 1 1 0 1.83z" />
		</svg>
	);
}
