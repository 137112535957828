import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentContext } from 'components/core/library/segment';
import FooterColumn from './FooterColumn';

const links = [
	{ href: '/college-storage.html', text: 'College storage' },
	{ href: '/car-storage.html', text: 'Car storage' },
	{ href: '/boat-storage.html', text: 'Boat storage' },
	{ href: '/rv-storage.html', text: 'RV storage' },
	{ href: '/portable-storage.html', text: 'Portable storage' },
	{ href: '/business-storage.html', text: 'Business storage' },
	{
		href: '/climate-controlled-storage.html',
		text: 'Climate Controlled'
	}
];

const enhance = withSegmentContext;

export function ProductsByType({ className }) {
	return (
		<FooterColumn
			className={className}
			links={links}
			title="Storage by type"
		/>
	);
}

ProductsByType.propTypes = {
	className: PropTypes.string
};

export default enhance(ProductsByType);
