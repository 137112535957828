import { get } from 'lodash';
import { setTags } from 'store/pageMeta/actions';


// Converts Gateway SEO Response into page meta tags on the server
export default (store) => (next) => (action = {}) => {
	const meta = get(action, 'payload.meta') || get(action, 'payload.result.meta');
	const seo = get(action, 'payload.seo') || get(action, 'payload.result.seo');

	if (
		process.env.RENDER_ENV !== 'client' &&
		action.payload &&
		(meta || seo)
	) {
		const pageMeta = {
			title: null,
			meta: {},
			og: {},
			links: [],
			structuredData: null
		};

		const { app } = store.getState();
		const title = get(meta, 'title');
		const description = get(meta, 'description');
		const structuredData = get(meta, 'structuredData');
		const canonical = get(seo, 'canonical');
		const noindex = get(meta, 'noindex', false);

		if (title) {
			pageMeta.title = title;
			pageMeta.og.title = title;
		}

		if (description) {
			pageMeta.meta.description = description;
			pageMeta.og.description = description;
		}

		if (structuredData) {
			pageMeta.structuredData = structuredData;

			if (structuredData.description) {
				pageMeta.structuredData = {
					...pageMeta.structuredData,
					description: encodeURIComponent(structuredData.description || '')
				};
			}
		}

		if (canonical) {
			pageMeta.og.url = `${app.baseUrl}${canonical}`;
			pageMeta.links.push({ rel: 'canonical', href: `${app.baseUrl}${canonical}` });
		}

		store.dispatch(setTags(pageMeta, noindex));
	}

	return next(action);
};
