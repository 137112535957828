import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';

/**
 * Renders a label for another HTML element. The inner text for the label is rendered within a `<div>`
 * element that will have the class name of `ph-label-text`
 *
 * **Base CSS Class:** `ph-label`
 * **CSS Class for inner text:** `ph-label-text`
 */

export default class CoreLabel extends PureComponent {
	static propTypes = {
		/** Text for the label. Text will usually be a string, but could also be another component */
		text: PropTypes.node.isRequired,
		helpText: PropTypes.string,
		/** id of the associated input */
		htmlFor: PropTypes.string.isRequired,
		/** If true, the text for the label is rendered after the children as opposed to before */
		textAfterChildren: PropTypes.bool,
		/** Additional class to be applied to label text */
		textClassName: PropTypes.string,
		children: PropTypes.node,
		className: PropTypes.string
	};

	static defaultProps = {
		textAfterChildren: false
	};


	_renderLabelText() {
		const className = classnames(
			'ph-label-text',
			this.props.textClassName
		);

		return (
			<span className={className}>
				{this.props.text}
			</span>
		);
	}

	render() {
		return (
			<label // eslint-disable-line jsx-a11y/label-has-for
				htmlFor={this.props.htmlFor}
				className={
					classnames(
						'ph-label',
						this.props.className,
						{
							'with-help': this.props.helpText
						}
					)
				}
			>
				{ this.props.textAfterChildren ? null : this._renderLabelText() }
				{!!this.props.helpText &&
					<span className={classnames('help-text', this.props.textClassName)}>
						{this.props.helpText}
					</span>
				}
				{ this.props.children }
				{ this.props.textAfterChildren ? this._renderLabelText() : null }
			</label>
		);
	}
}
