import React from 'react';
import { withSegmentContext } from 'components/core/library/segment';
import { Link } from 'components/core/Link';
import { Twitter, Facebook, Instagram, Pinterest } from 'components/svg';

const enhance = withSegmentContext;

export function SocialIcons() {
	return (
		<div className="social-icons">
			<Link
				href="https://twitter.com/SpareFoot"
				target="_blank"
				rel="noreferrer noopener nofollow"
				className="social-link"
				segmentLabel="outbound twitter"
				hideBorder
			>
				<Twitter />
			</Link>
			<Link
				href="https://www.facebook.com/SpareFoot"
				target="_blank"
				rel="noreferrer noopener nofollow"
				className="social-link"
				segmentLabel="outbound facebook"
				hideBorder
			>
				<Facebook />
			</Link>
			<Link
				href="https://www.instagram.com/sparefoot/"
				target="_blank"
				rel="noreferrer noopener nofollow"
				className="social-link"
				segmentLabel="outbound instagram"
				hideBorder
			>
				<Instagram />
			</Link>
			<Link
				href="https://www.pinterest.com/sparefoot/"
				target="_blank"
				rel="noreferrer noopener nofollow"
				className="social-link"
				segmentLabel="outbound pinsterest"
				hideBorder
			>
				<Pinterest />
			</Link>
		</div>
	);
}

export default enhance(SocialIcons);
