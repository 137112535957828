import React from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import { ApplicationWrapper } from 'pages/ApplicationWrapper';

Bugsnag.start({
    apiKey: process.env.BUGSNAG_CLIENT_KEY,
    plugins: [new BugsnagPluginReact()],
	releaseStage: `SFDC - ${  process.env.NODE_ENV}`,
	onError(event) {
		event.addMetadata("Site - SFDC", {
			url: window.location.href
		});
	}
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

import '../main.scss';

export function App({ store }) {
	return (
		<Provider store={store}>
			<ErrorBoundary>
				<BrowserRouter>
					<ApplicationWrapper />
				</BrowserRouter>
			</ErrorBoundary>
		</Provider>
	);
}

App.propTypes = {
	store: PropTypes.object
};

export default hot(module)(App);
