import thunk from 'redux-thunk';
import { pageTypeReduxListener } from './segment';
import bookingCreateSuccessMiddleware from './bookings';
import { readyMiddleware } from './appReady';
import experimentsMiddleware from './experiments';
import pageMetaData from './pageMetaData';
import api from './api';

export default () => [
	thunk,
	api,
	experimentsMiddleware,
	pageMetaData,
	pageTypeReduxListener,
	bookingCreateSuccessMiddleware,
	readyMiddleware,
];
