import { withProps } from 'recompose';
import { get } from 'lodash';

export default withProps((props) => {
	const canonicalUrl = `${get(props, 'baseUrl', '')}${props.canonicalUrl}`;
	const pageSchema = {
		'@context': 'https://schema.org',
		'@graph':
			[
				{
					'@type': 'WebSite',
					'@id': 'https://www.sparefoot.com',
					url: 'https://www.sparefoot.com/',
					name: 'SpareFoot'
				},
				{
					'@type': 'WebPage',
					'@id': canonicalUrl,
					url: canonicalUrl,
					inLanguage: 'en',
					name: props.metaTitle,
					isPartOf: { '@id': 'https://www.sparefoot.com' },
					description: props.metaDescription
				}
			]
	};
	const stringifiedPageSchema = JSON.stringify(pageSchema);
	return { pageSchema: stringifiedPageSchema };
});
