import { setDisplayName, compose, withContext, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from './getSegmentContext';

const withSegmentContext = compose(
	getSegmentContext,
	withContext({
		segmentConfig: PropTypes.object
	}, ({ segmentConfig = {}, segmentCategory, segmentProperties, segmentPrefix }) => {
		const prevPrefix = segmentConfig.segmentPrefix || [];
		return {
			segmentConfig: SegmentConfig.from(segmentConfig, {
				segmentCategory,
				segmentProperties,
				segmentPrefix: segmentPrefix ? [...prevPrefix, segmentPrefix] : prevPrefix
			})
		};
	})
);

export const withFixedSegmentCategory = (category) => compose(
	withProps({ segmentCategory: category }),
	withSegmentContext
);

export const SegmentRegion = setDisplayName('SegmentRegion')(
	withSegmentContext(
		/* istanbul ignore next */
		({ children }) => children
	)
);

export default withSegmentContext;
