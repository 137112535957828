import { compose } from 'recompose';
import setWrappedDisplayName from 'decorators/setWrappedDisplayName';
import withSegmentEcommerceProps from '../context/withSegmentEcommerceProps';
import withProductAdded from './withProductAdded';
import withProductViewed from './withProductViewed';
import withProductListTracking from './withProductListTracking';

const trackingHOC = {
	'Product Added': withProductAdded,
	'Product Viewed': withProductViewed,
	'Product List Viewed': withProductListTracking
};

const ecommerceTracking = (events, filterFunc) => {
	const hook = trackingHOC[events];

	if (!hook) {
		return withSegmentEcommerceProps(() => ({ category: 'unknown ecommerce tracking' }));
	}
	return compose(
		withSegmentEcommerceProps(filterFunc),
		hook,
		setWrappedDisplayName('EcommerceTracking')
	);
};
export default ecommerceTracking;
