import { compose, withProps } from 'recompose';
import { get } from 'lodash';
import withSelfStorageSchema from '@sparefoot/react-core/decorators/withSelfStorageSchema';

export default compose(
	withProps(({
		facility, units, reviewCount, average, facilityUrl, baseUrl
	}) => {
		const { promotion, cloudfrontUrl, images, phone, location } = facility;
		let imagesWithUrl = [];
		let schemaPromotion = promotion;

		if (!promotion.length) {
			const unitPromotion = units?.find((unit) => unit.promotion.length);
			schemaPromotion = get(unitPromotion, 'promotion', '');
		}

		if (images.length) {
			imagesWithUrl = images.map((image) => `https:${cloudfrontUrl}/large-compress/${image}`);
		}

		return {
			facility: {
				...facility,
				location: {
					...location,
					streetAddress1: location.streetAddress
				},
				facilityPhone: phone,
				imagesWithUrl,
				reviews: {
					...facility.reviews,
					number: reviewCount,
					average
				},
				schemaUrl: `${baseUrl}${facilityUrl}`
			},
			schemaPromotion
		};
	}),
	withSelfStorageSchema
);
