import { createAction } from 'redux-actions';
import { API } from '../middleware/api';

export const REVIEWS = {
	BEST_REVIEWS_REQUEST: 'SFDC/REVIEWS/BEST_REVIEWS_REQUEST',
	BEST_REVIEWS_SUCCESS: 'SFDC/REVIEWS/BEST_REVIEWS_SUCCESS',
	BEST_REVIEWS_FAILURE: 'SFDC/REVIEWS/BEST_REVIEWS_FAILURE',
	SUBMIT_REVIEW_REQUEST: 'SFDC/REVIEWS/SUBMIT_REVIEW_REQUEST',
	SUBMIT_REVIEW_SUCCESS: 'SFDC/REVIEWS/SUBMIT_REVIEW_SUCCESS',
	SUBMIT_REVIEW_FAILURE: 'SFDC/REVIEWS/SUBMIT_REVIEW_FAILURE',
	GET_REVIEW_FACILITY_SUCCESS: 'SFDC/REVIEWS/GET_REVIEW_FACILITY_SUCCESS'
};

export const getReviewFacilitySuccess = createAction(REVIEWS.GET_REVIEW_FACILITY_SUCCESS);

export function fetchBestReviewsByFacilityIds(faciltiyIds = []) {
	return {
		[API]: {
			types: [REVIEWS.BEST_REVIEWS_REQUEST, REVIEWS.BEST_REVIEWS_SUCCESS, REVIEWS.BEST_REVIEWS_FAILURE],
			endpoint: '/reviews/best',
			query: {
				ids: faciltiyIds.join(',')
			}
		}
	};
}

export function fetchBestReviews(faciltiyIds = []) {
	return (dispatch) => {
		if (faciltiyIds.length) {
			return dispatch(fetchBestReviewsByFacilityIds(faciltiyIds));
		}
		return Promise.resolve();
	};
}


export function submitReview(data) {
	return {
		[API]: {
			types: [REVIEWS.SUBMIT_REVIEW_REQUEST, REVIEWS.SUBMIT_REVIEW_SUCCESS, REVIEWS.SUBMIT_REVIEW_FAILURE],
			endpoint: '/review',
			method: 'POST',
			data
		}
	};
}
