import PropTypes from 'prop-types';
import React, { Children } from 'react';
import classnames from 'classnames';
import CoreButton from '../library/button';
import './button.scss';
/**
 * This is the default button for SFDC.
 */
export default function Button({
	children,
	className,
	dark,
	disabled,
	fullWidth,
	onClick,
	href,
	gray,
	large,
	link,
	outline,
	loading,
	onMouseEnter,
	onMouseLeave,
	preContent,
	primary,
	secondary,
	productClick,
	rel,
	segmentLabel,
	target,
	type,
	pink
}) {
	return (
		<CoreButton
			className={
				classnames(
					className,
					{
						'ph-link': link,
						'button-large': large,
						'button-primary': !link && primary && !secondary,
						'button-secondary': !link && secondary,
						'button-dark': dark,
						'button-gray': gray,
						'button-pink': pink,
						'button-outline': outline,
						'button-full': fullWidth,
						'button-loading': loading
					}
				)
			}
			href={href}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			disabled={disabled}
			type={type}
			target={target}
			rel={rel}
			segmentLabel={segmentLabel}
			productClick={productClick}
		>
			{!!preContent &&
				<span className="pre-content">
					{Children.toArray(preContent)}
				</span>
			}
			<span className="button-content">
				{Children.toArray(children)}
			</span>
		</CoreButton>
	);
}

Button.propTypes = {
	/** Makes the button look like a button. */
	primary: PropTypes.bool,
	/** Makes the button look like a tighter button, like blog post tags. */
	secondary: PropTypes.bool,
	/** Makes the button larger. */
	large: PropTypes.bool,
	/** Changes the button to dark background with light text. */
	dark: PropTypes.bool,
	/** Changes the button to a gray background with light text. */
	gray: PropTypes.bool,
	/** Changes the button to look like a link. */
	link: PropTypes.bool,
	/** Changes the button to the outlined style. */
	outline: PropTypes.bool,
	/** Hides button content and shows loading gif. */
	loading: PropTypes.bool,
	/** Additional class name to be applied to the component */
	className: PropTypes.string,
	/** Content inside the button */
	children: PropTypes.node,
	/** Disables the button and applies disabled styles */
	disabled: PropTypes.bool,
	/** Action to fire when button is clicked */
	onClick: PropTypes.func,
	/** Optional URL that will convert the button into an anchor */
	href: PropTypes.string,
	/** Action to fire when button is hovered over */
	onMouseEnter: PropTypes.func,
	/** Action to fire when hover ends */
	onMouseLeave: PropTypes.func,
	/** Content to show before the inner content, such as an icon */
	preContent: PropTypes.node,
	/** adds a rel to the anchor, such as 'noindex' or 'noreferrer'. requires href */
	rel: PropTypes.string,
	/** Segment event label */
	segmentLabel: PropTypes.string.isRequired,
	/** Anchor target such as 'blank' or '_top'. requires href */
	target: PropTypes.string,
	/** Type of button to render. submit or plain button */
	type: PropTypes.oneOf(['submit', 'button']),
	/** Changes the with of button to go full width instead of inline */
	fullWidth: PropTypes.bool,
	/** Fires the Product Clicked event when paired with ecommerce tracking */
	productClick: PropTypes.bool,
	/** Changes the button to pink background with light text. */
	pink: PropTypes.bool
};

Button.defaultProps = {
	primary: true
};
