/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { withSegmentClickTracking } from 'components/core/library/segment';

/**
 * **TODO:** Link button should be updated to call Segment's trackLink function
 * to avoid navigating away from the page before the click is tracked.
 */
const enhance = withSegmentClickTracking;

export function CoreButton({
	href, children, disabled, handleClick, className, target, type, onMouseEnter, onMouseLeave, rel, getRef, segmentLabel
}) {
	if (href) {
		return (
			<a
				href={href}
				onClick={handleClick}
				className={classnames('ph-button', className)}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				target={target}
				rel={rel}
				ref={getRef}
			>
				{children}
			</a>
		);
	}
	return (
		<button
			onClick={handleClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			disabled={disabled}
			className={classnames('ph-button', className)}
			type={type}
			ref={getRef}
			aria-label={segmentLabel}
		>
			{children}
		</button>
	);
}

CoreButton.propTypes = {
	href: PropTypes.string,
	children: PropTypes.node,
	handleClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	target: PropTypes.string,
	type: PropTypes.string,
	rel: PropTypes.string,
	getRef: PropTypes.func,
	segmentLabel: PropTypes.string
};

CoreButton.defaultProps = {
	type: 'button'
};

export default enhance(CoreButton);
