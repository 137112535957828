/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Star.scss';

export default function Star({ size, value, color }) {
	return value > 0 && value < 1 ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			className={classnames('svg', 'star', size, color)}
		>
			<path
				d="M8 0l2.47 5.65 6.29.57-4.78 4.12L12.24 16 8 12.52 3.76 16l1.26-5.66-4.78-4.12 6.29-.57z"
				fill="#FC0"
				stroke="#FC0"
			/>
			<path
				d="M8 0l2.67 6.12 5.42.5-4.19 3.61L12.24 16 8 12.52V0z"
				stroke="#EBEBEB"
				fill="#EBEBEB"
				className="empty-half"
			/>
		</svg>
	) : (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			xmlns="http://www.w3.org/2000/svg"
			className={classnames('svg', 'star', size, { full: value >= 1 }, color)}
		>
			<path
				d="M9.293 3.595l-2.816-.41-.116-.236L5.22.635C5.177.553 5.09.5 5 .5s-.178.054-.22.136l-1.256 2.55-.26.037-2.557.372c-.09.013-.167.077-.196.167-.028.09-.004.185.063.25L2.61 5.995l-.045.26-.437 2.54c-.016.093.022.187.097.24.076.057.175.064.257.02L5 7.732l.233.122 2.284 1.2c.036.02.074.03.113.03.05 0 .102-.017.145-.048.074-.053.112-.147.096-.237l-.48-2.805.188-.183 1.848-1.8c.067-.065.09-.164.064-.248-.03-.092-.106-.156-.197-.17z"
				stroke="#EBEBEB"
				fill="#EBEBEB"
				fillRule="evenodd"
			/>
		</svg>
	);
}

Star.propTypes = {
	size: PropTypes.string,
	value: PropTypes.number,
	color: PropTypes.string
};
