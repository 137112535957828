import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { SegmentConfig } from '@sparefoot/segment-react';

import { getBoundingClientRect, on, off, getOffset } from 'utils/dom/dom';
import { createPortal } from 'react-dom';

import './ClickDebugger.scss';

export default (Component) => {
	class ClickDebugger extends PureComponent {
		constructor(props) {
			super(props);
			this.update = debounce(this.update, 250);
		}

		state = {
			top: 0,
			left: 0,
			width: 0,
			height: 0,
			modal: false
		}

		componentDidMount() {
			on(['resize', 'load', 'scroll'], this.update);
		}

		componentWillUnmount() {
			off(['resize', 'load', 'scroll'], this.update);
		}

		bindDomRef = (ref) => {
			this.instance = ref;
			this.update();
		}

		update = () => {
			if (this.instance) {
				const rect = getBoundingClientRect(this.instance);
				const offset = getOffset(this.instance);
				const offsetTop = offset.modal ? window.pageYOffset : 0;
				this.setState({
					top: (offset.top - 2) + offsetTop,
					left: offset.left - 2,
					width: rect.width ? rect.width + 4 : 0,
					height: rect.height ? rect.height + 4 : 0,
					modal: offset.modal
				});
			}
		}

		handleClick = () => {
			/* istanbul ignore else */
			if (this.instance) {
				this.instance.click();
			}
			setTimeout(this.update, 500);
		}


		render() {
			const { segmentConfig = {}, segmentLabel } = this.props;
			const {
				top, left, width, height, modal
			} = this.state;
			const segmentProps = SegmentConfig.buildTrackingProps(segmentConfig, { segmentLabel });
			return (
				<Fragment>
					<Component {...this.props} getRef={this.bindDomRef} />
					{!!height && typeof window !== 'undefined' &&
						createPortal(
							<div
								className={classnames(
									'segment-click-debug',
									{
										alert: !segmentConfig.segmentCategory || !segmentLabel,
										modal
									}
								)}
								style={{
									top, left, width, height
								}}
							>
								<button className="proxy" type="button" onClick={this.handleClick} />
								<div className="debug-info">
									<p color="white">
										<strong>Segment Label:</strong> {segmentProps.label}
									</p>
									<p color="white">
										<strong>Segment Category:</strong> {segmentProps.category}
									</p>
								</div>
							</div>,
							document.getElementById('js-debug')
						)
					}
				</Fragment>
			);
		}
	}

	ClickDebugger.propTypes = {
		segmentConfig: PropTypes.object,
		segmentLabel: PropTypes.string,
		className: PropTypes.string
	};

	return ClickDebugger;
};
