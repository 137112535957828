/* eslint-disable max-len */
import React from 'react';

export default function FileUpload() {
	return (
		<svg width="24" height="30" viewBox="0 0 24 30">
			<defs>
				<path d="M3.6 0h12.03L24 8.41V26.4c0 1.988-1.612 3.6-3.6 3.6H3.6C1.612 30 0 28.388 0 26.4V3.6C0 1.612 1.612 0 3.6 0z" id="a" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id="b" fill="#fff">
					<use href="#a" />
				</mask>
				<use fill="#EAEAEA" href="#a" />
				<path fill="#000" opacity=".2" mask="url(#b)" d="M15.6-21.6h24v30h-24" />
				<g fill="#555">
					<path d="M15.75 14.25h-2v-2c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25 1.25v2h-2c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25h2v2c0 .69.56 1.25 1.25 1.25s1.25-.56 1.25-1.25v-2h2c.69 0 1.25-.56 1.25-1.25s-.56-1.25-1.25-1.25" />
				</g>
			</g>
		</svg>
	);
}
