import { compose, lifecycle, withStateHandlers, defaultProps } from 'recompose';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getNearbyFacilities } from 'store/selectors/facilities';

export default compose(
	defaultProps({
		listingsPerPage: 5,
		searchType: 'storage-only' // no fss facilities (except in special snowflake markets)
	}),
	withStateHandlers(
		{
			facilityIds: [],
			loadingNearbyFacilities: true
		},
		{
			handleSearchResponse: () => ({ error, payload }) => {
				if (!error) {
					const search = payload.entities.search[payload.result.search];
					return {
						facilityIds: search.facilities,
						loadingNearbyFacilities: false,
						nearbySearchId: search.searchId
					};
				}
				return {
					loadingNearbyFacilities: false
				};
			}
		}
	),
	connect(getNearbyFacilities),
	lifecycle({
		componentDidMount() {
			// don't fetch more nearby facilities if the component already has them
			if (isEmpty(this.props.nearbyFacilities)) {
				const { latitude, longitude } = this.props.facility.location;
				this.props.actions.fetchSearchResults({
					latitude,
					longitude,
					listingsPerPage: this.props.listingsPerPage,
					searchType: this.props.searchType
				})
				.then(this.props.handleSearchResponse);
			}
		}
	})
);
