/* eslint-disable global-require */
import { polyfill } from 'smoothscroll-polyfill';

import React from 'react';
import { hydrate } from 'react-dom';
import Segment from '@sparefoot/segment-react';
import adBlockerCheck from 'utils/adBlockerCheck';
import createStore from './store';
import App from './containers/App';
import { pageTypeSegmentMiddleware } from './store/middleware/segment';

polyfill();

const decodedState = window?.__PRELOADED_STATE__ ? JSON.parse(Buffer.from(window.__PRELOADED_STATE__, 'base64').toString()) : null;

const store = createStore(decodedState);
const { app, user } = store.getState();

Segment.initialize(app.segmentWriteKey, {
	siteId: -1,
	visitId: user.visitId,
	environment: app.environment,
	siteType: 'desktop',
	host: app.baseUrl.replace(/^https?:\/\//i, ''),
	debug: process.env.NODE_ENV === 'development' && process.env.RENDER_ENV === 'client'
});

Segment.getInstance().useTrackMiddleware(pageTypeSegmentMiddleware(store));

adBlockerCheck(Segment);

const render = (AppComponent) => hydrate(
	(<AppComponent store={store} />),
	document.getElementById('js-page-content')
);

render(App);

if (module.hot) {
	module.hot.accept(['./containers/App', './pages/Bundles'], () => {
		const NextApp = require('./containers/App').default;
		render(NextApp);
	});
}
