import { schema } from 'normalizr';
import facilitySchema from './facilities';


export const searchSchema = new schema.Entity(
	'search',
	{
		facilities: [facilitySchema]
	},
	{
		idAttribute: 'searchId'
	}
);

export const searchResponseSchema = {
	search: searchSchema
};

export default searchSchema;
