import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ReduxCAPTCHA({ input, sitekey }) {
	return (
		<ReCAPTCHA
			sitekey={sitekey}
			size="normal"
			{...input}
		/>
	);
}

ReduxCAPTCHA.propTypes = {
	input: PropTypes.object,
	sitekey: PropTypes.string
};
