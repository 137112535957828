/* eslint-disable max-len */
import React from 'react';

export default function TooltipInfo() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			className="svg tooltip-info"
		>
			<g clipPath="url(#a)" opacity=".5">
				<path
					fill="#FE8240"
					d="M8 .731a7.27 7.27 0 1 0 0 14.54A7.27 7.27 0 0 0 8 .73Zm1.515 11.267c-.375.146-.673.26-.897.336-.224.077-.48.116-.776.116-.452 0-.806-.11-1.058-.332-.25-.219-.375-.5-.375-.841 0-.133.01-.267.029-.404.017-.14.048-.293.089-.469l.468-1.654a9.04 9.04 0 0 0 .106-.45c.029-.141.041-.271.041-.389 0-.209-.043-.358-.13-.44-.089-.084-.255-.125-.5-.125-.12 0-.245.02-.372.056a5.815 5.815 0 0 0-.327.108L5.938 7c.305-.125.598-.23.88-.32.278-.088.545-.134.793-.134.45 0 .798.11 1.04.327.243.216.366.5.366.846 0 .072-.007.2-.024.38a2.6 2.6 0 0 1-.094.5l-.466 1.649a4.74 4.74 0 0 0-.103.452 2.493 2.493 0 0 0-.044.384c0 .22.048.37.147.45.096.08.267.118.51.118.112 0 .24-.02.384-.058.142-.04.245-.075.31-.106l-.122.51ZM9.43 5.303a1.109 1.109 0 0 1-.783.303c-.305 0-.57-.101-.789-.303a.962.962 0 0 1-.327-.733c0-.286.111-.534.327-.738.22-.204.484-.305.789-.305a1.1 1.1 0 0 1 .783.305.975.975 0 0 1 .327.738.962.962 0 0 1-.327.733Z"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h16v16H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
