/* eslint-disable import/no-extraneous-dependencies, global-require,  */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';
import middleware from './middleware';


export default function configureStore(initialState = {}, storeConfig) {
	const store = createStore(
		reducers,
		initialState,
		composeWithDevTools(
			applyMiddleware(
				...middleware(storeConfig)
			)
		)
	);

	/* istanbul ignore if  */
	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers').default;

			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
