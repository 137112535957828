/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Search({ className }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			className={classnames('svg search', className)}
		>
			<path d="M11.1693 9.048l3.919 3.919c.5858.5858.5858 1.5356 0 2.1214-.5857.5857-1.5355.5857-2.1213 0l-3.919-3.9191C8.1547 11.697 7.1127 12 6 12c-3.3137 0-6-2.6863-6-6s2.6863-6 6-6 6 2.6863 6 6c0 1.1127-.3029 2.1547-.8307 3.048zM6 9c1.6569 0 3-1.3431 3-3S7.6569 3 6 3 3 4.3431 3 6s1.3431 3 3 3z" />
		</svg>
	);
}

Search.propTypes = {
	className: PropTypes.string
};
