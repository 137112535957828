import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentContext } from 'components/core/library/segment';
import FooterColumn from './FooterColumn';

const links = [
	{ href: 'https://www.storage.com/', text: 'Storage.com', rel: "noopener", target:"_blank" },	
	{ href: 'https://www.selfstorage.com/', text: 'SelfStorage.com', rel: "noopener", target:"_blank" },
	{ href: 'https://www.storable.com/', text: 'Storable.com', rel: "noopener", target:"_blank" },
];

const enhance = withSegmentContext;

export function AffiliatesLinks({ className, location }) {
	const updatedLinks = links.map(link => {return {...link, rel: `${link.rel} ${location.pathname ==="/"? 'follow':'nofollow'}`}})
	return (
		<FooterColumn
			className={className}
			links={updatedLinks}
			title="Storable Brands"
		/>
	);
}

AffiliatesLinks.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object
};

export default enhance(AffiliatesLinks);
