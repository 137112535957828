import { createSelector } from 'reselect';

export const getTrackingPixelData = createSelector(
	(state) => state?.pageMeta,
	(state) => state?.app?.trackingURL,
	(pageMeta, trackingURL, routePageType) => ({
		pagePixelSrc: pageMeta?.pixelData?.pagePixelSrc,
		visitVerifySrc: pageMeta?.visitVerifySrc,
		pageType: routePageType || pageMeta?.pageType,
		trackingId: pageMeta.trackingId,
		trackingURL
	})
);
