/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withHandlers } from 'recompose';
import { Button } from 'components/core/Button';

export const enhance = withHandlers({
	onMouseEnter: (props) => () => props.onMouseEnter(props.value),
	onClick: (props) => () => props.onClick(props.value)
});

export function StarButton({
	className, onClick, onMouseEnter, onMouseLeave, segmentLabel, segmentProperties
}) {
	return (
		<Button
			primary={false}
			className={classnames('star-button', className)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			segmentLabel={segmentLabel}
			segmentProperties={segmentProperties}
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.67 37.05" className="svg star">
				<path d="M38.53 13.95a2.87 2.87 0 0 0-2.32-2l-9.87-1.44-4.42-8.9a2.88 2.88 0 0 0-5.16 0l-4.41 8.94L2.46 12a2.88 2.88 0 0 0-1.59 4.9l7.15 7-1.69 9.79a2.88 2.88 0 0 0 4.18 3l8.83-4.64 8.83 4.64a2.88 2.88 0 0 0 4.18-3l-1.69-9.83 7.15-7a2.88 2.88 0 0 0 .73-3" />
			</svg>
		</Button>
	);
}

StarButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	segmentLabel: PropTypes.string,
	segmentProperties: PropTypes.object
};

export default enhance(StarButton);
