/* eslint-disable no-underscore-dangle, no-undef */
import { handleActions } from 'redux-actions';
import { APP } from './actions';

export const initialState = {
	id: null,
	sfapigw_host: '',
	trackingURL: '',
	baseUrl: ''
};

export default handleActions({
	[APP.SET]: (state, action) => action.payload.app
}, initialState);
/* eslint-enable */
