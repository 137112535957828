import { createAction } from 'redux-actions';
import { API } from '../middleware/api';

export const PAGE_META = {
	SERVER_RENDER_ERROR: 'SSDC/PAGE_META/SERVER_RENDER_ERROR',
	SET: 'SFDC/PAGE_META/SET',
	SET_PAGE_TYPE: 'SFDC/PAGE_META/SET_PAGE_TYPE',
	SET_PHONE: 'SFDC/PAGE_META/SET_PHONE',
	SET_TAGS: 'SFDC/PAGE_META/SET_TAGS',
	GET_LOCAL_NUMBER_BY_ZIPCODE_REQUEST: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_ZIPCODE_REQUEST',
	GET_LOCAL_NUMBER_BY_ZIPCODE_SUCCESS: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_ZIPCODE_SUCCESS',
	GET_LOCAL_NUMBER_BY_ZIPCODE_FAILURE: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_ZIPCODE_FAILURE',
	GET_LOCAL_NUMBER_BY_CITYSTATE_REQUEST: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_CITYSTATE_REQUEST',
	GET_LOCAL_NUMBER_BY_CITYSTATE_SUCCESS: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_CITYSTATE_SUCCESS',
	GET_LOCAL_NUMBER_BY_CITYSTATE_FAILURE: 'SFDC/PAGE_META/GET_LOCAL_NUMBER_BY_CITYSTATE_FAILURE',
	GET_TOLLFREE_NUMBER_REQUEST: 'SFDC/PAGE_META/GET_TOLLFREE_NUMBER_REQUEST',
	GET_TOLLFREE_NUMBER_SUCCESS: 'SFDC/PAGE_META/GET_TOLLFREE_NUMBER_SUCCESS',
	GET_TOLLFREE_NUMBER_FAILURE: 'SFDC/PAGE_META/GET_TOLLFREE_NUMBER_FAILURE'
};

export const setPageMeta = createAction(PAGE_META.SET, (pageMeta) => ({ pageMeta }));
export const setPageType = createAction(PAGE_META.SET_PAGE_TYPE, (pageType) => ({ pageType }));
export const setPhone = createAction(PAGE_META.SET_PHONE, (phone) => ({ phone }));
export const setTags = createAction(PAGE_META.SET_TAGS, (tags, noindex = false) => ({ tags, noindex }));

export function getLocalPhoneNumberByZipCode(zipCode) {
	return {
		[API]: {
			types: [PAGE_META.GET_LOCAL_NUMBER_BY_ZIPCODE_REQUEST,
				PAGE_META.GET_LOCAL_NUMBER_BY_ZIPCODE_SUCCESS,
				PAGE_META.GET_LOCAL_NUMBER_BY_ZIPCODE_FAILURE],
			endpoint: `/phone/numbers/local/zipCodes/${zipCode}`,
			method: 'GET'
		}
	};
}

export function getLocalPhoneNumberByCityState(city, state) {
	return {
		[API]: {
			types: [PAGE_META.GET_LOCAL_NUMBER_BY_CITYSTATE_REQUEST,
				PAGE_META.GET_LOCAL_NUMBER_BY_CITYSTATE_SUCCESS,
				PAGE_META.GET_LOCAL_NUMBER_BY_CITYSTATE_FAILURE],
			endpoint: `/phone/numbers/local/city/${city}/state/${state}`,
			method: 'GET'
		}
	};
}

export function getTollfreePhoneNumber() {
	return {
		[API]: {
			types: [PAGE_META.GET_TOLLFREE_NUMBER_REQUEST,
				PAGE_META.GET_TOLLFREE_NUMBER_SUCCESS,
				PAGE_META.GET_TOLLFREE_NUMBER_FAILURE],
			endpoint: '/phone/numbers/tollfree',
			method: 'GET'
		}
	};
}

export function serverRenderError(error) {
	return {
		type: PAGE_META.SERVER_RENDER_ERROR,
		error
	};
}
