import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, withState, withStateHandlers, lifecycle } from 'recompose';
import classnames from 'classnames';

import { Phone } from 'components/svg';
import { Button } from 'components/core/Button';
import { PhoneNumber, PhoneNumberButton } from 'components/contact/PhoneNumber';

import './ClickToShowNumberButton.scss';

export const enhance = compose(
	withState('fetchingPhoneNumber', 'setFetchingPhoneNumber', false),
	withState('phoneNumberLoaded', 'setPhoneNumberLoaded', false),
	withState('outlineLoadingBtn', 'setOutlineLoadingBtn', ({ outline }) => outline),
	withStateHandlers({
		formattedPhoneNumber: null,
		unformattedPhoneNumber: null
	}, {
		handleFetchPhoneNumberResponse: () => ({ formattedPhoneNumber, unformattedPhoneNumber }) => ({
			formattedPhoneNumber,
			unformattedPhoneNumber
		})
	}),
	withHandlers({
		handleFetchPhoneNumber: ({
			outline,
			fetchPhoneNumber,
			setOutlineLoadingBtn,
			setFetchingPhoneNumber,
			setPhoneNumberLoaded,
			handleFetchPhoneNumberResponse,
			// sf_12700_sfdc_paid_landing_refresh
			parentPhoneLoaded,
			setParentPhoneNumber,
			setParentPhoneLoaded
		}) => () => {
			setOutlineLoadingBtn(false);
			setFetchingPhoneNumber(true);

			return fetchPhoneNumber()
			.then(({ payload }) => {
				setOutlineLoadingBtn(outline);
				setFetchingPhoneNumber(false);

				const { number: formattedPhoneNumber, unformattedNumber: unformattedPhoneNumber } = payload;

				if (formattedPhoneNumber && unformattedPhoneNumber) {
					setPhoneNumberLoaded(true);

					if (parentPhoneLoaded !== undefined) { // sf_12700_sfdc_paid_landing_refresh
						setParentPhoneNumber({ number: formattedPhoneNumber, unformattedNumber: unformattedPhoneNumber });
						setParentPhoneLoaded(true);
					}
				}

				return { formattedPhoneNumber, unformattedPhoneNumber };
			})
			.then(handleFetchPhoneNumberResponse)
			.catch(() => {
				setOutlineLoadingBtn(outline);
				setFetchingPhoneNumber(false);
			});
		}
	}),
	lifecycle({ // sf_12700_sfdc_paid_landing_refresh
		componentDidUpdate(prevProps) {
			if (this.props.parentPhoneLoaded && prevProps.parentPhoneLoaded !== this.props.parentPhoneLoaded) {
				this.props.setPhoneNumberLoaded(true);
			}
		}
	})
);


export function ClickToShowNumberButton({
	className,
	callToActionText,
	showAsLink,
	hideIcon,
	outline,
	outlineLoadingBtn,
	size,
	color,
	dark,
	phoneNumberLoaded,
	fetchingPhoneNumber,
	handleFetchPhoneNumber,
	formattedPhoneNumber,
	unformattedPhoneNumber,
	OMIAppRentalCompleted,
	facilityPhone,
	unformattedFacilityPhone,
	// sf_12700_sfdc_paid_landing_refresh
	label,
	mobileLabel,
	parentPhoneNumber,
	parentPhoneLoaded
}) {
	const PhoneComponent = showAsLink ? PhoneNumber : PhoneNumberButton;
	if (OMIAppRentalCompleted) {
		return (
			<PhoneComponent
				className={classnames('click-to-show-number-button', className)}
				outline={outline}
				size={size}
				dark={dark}
				color={color}
				phoneNumber={facilityPhone}
				unformattedPhoneNumber={unformattedFacilityPhone}
				hideIcon={hideIcon}
				link={showAsLink}
				clickable
			/>
		);
	}

	const useParentPhoneNumber = parentPhoneLoaded && parentPhoneNumber?.number && parentPhoneNumber?.unformattedNumber; // sf_12700_sfdc_paid_landing_refresh
	return !phoneNumberLoaded ? (
		<Button
			className={classnames('click-to-show-number-button', className)}
			onClick={handleFetchPhoneNumber}
			disabled={fetchingPhoneNumber}
			loading={fetchingPhoneNumber}
			size={size}
			dark={dark}
			outline={outlineLoadingBtn}
			segmentLabel="load phone number on click"
			link={showAsLink}
			preContent={!hideIcon &&
			<Phone
				size={size}
			/>
			}
		>
			<div className="phone-number">
				{callToActionText || (
					<Fragment>
						<div className="hide-for-mobile">
							{label || 'Show phone number'}
						</div>
						<div className="hide-for-desktop">
							{ mobileLabel || label || 'Call now'}
						</div>
					</Fragment>
				)}
			</div>
		</Button>
	) : (
		<PhoneComponent
			className={classnames('click-to-show-number-button', className)}
			outline={outline}
			size={size}
			dark={dark}
			color={color}
			phoneNumber={!useParentPhoneNumber ? formattedPhoneNumber : parentPhoneNumber.number}
			unformattedPhoneNumber={!useParentPhoneNumber ? unformattedPhoneNumber : parentPhoneNumber.unformattedNumber}
			hideIcon={hideIcon}
			link={showAsLink}
			clickable
		/>
	);
}

ClickToShowNumberButton.propTypes = {
	callToActionText: PropTypes.string,
	className: PropTypes.string,
	showAsLink: PropTypes.bool,
	hideIcon: PropTypes.bool,
	outline: PropTypes.bool,
	outlineLoadingBtn: PropTypes.bool,
	size: PropTypes.oneOf(['tiny', 'small', 'standard', 'medium', 'large', 'extra-large', 'huge', 'giant']),
	color: PropTypes.string,
	dark: PropTypes.bool,
	phoneNumberLoaded: PropTypes.bool,
	fetchingPhoneNumber: PropTypes.bool,
	handleFetchPhoneNumber: PropTypes.func,
	formattedPhoneNumber: PropTypes.string,
	unformattedPhoneNumber: PropTypes.string,
	OMIAppRentalCompleted: PropTypes.bool,
	facilityPhone: PropTypes.string,
	unformattedFacilityPhone: PropTypes.string,
	// sf_12700_sfdc_paid_landing_refresh
	label: PropTypes.string,
	mobileLabel: PropTypes.string,
	parentPhoneNumber: PropTypes.object,
	parentPhoneLoaded: PropTypes.bool
};

export default enhance(ClickToShowNumberButton);
