import { compose, lifecycle } from 'recompose';
import { get } from 'lodash';
import Segment from '@sparefoot/segment-react';
import formatEcommerceProps from 'lib/segment/ecommerce';
import getSegmentContext from '../context/getSegmentContext';

const trackProductListViewedEvent = (props) => {
	const eCommerceProps = formatEcommerceProps('Product List Viewed', {
		...props.ecommerceProps,
		...props.segmentConfig.ecommerceProps
	});
	Segment.track('Product List Viewed', eCommerceProps);
};

export default compose(
	getSegmentContext,
	lifecycle({
		componentDidMount() {
			trackProductListViewedEvent(this.props);
		},
		componentWillReceiveProps(nextProps) {
			const searchId = 'segmentConfig.ecommerceProps.searchId';
			if (get(nextProps, searchId) !== get(this.props, searchId)) {
				trackProductListViewedEvent(nextProps);
			}
		}
	})
);
