import Segment from '@sparefoot/segment-react';
import { get } from 'lodash';
import { BOOKINGS, trackingSuccess } from 'store/bookings/actions';

export default (store) => (next) => (action) => {
	if (action.type === BOOKINGS.CREATE_SUCCESS) {
		Segment.track(
			'gtmWebReservation',
			{
				booking: {
					confirmationCode: get(action, 'payload.confirmationCode'),
					email: get(action, 'payload.email'),
					emailHash: get(action, 'payload.emailHash') || '',
					npv: get(action, 'payload.npv')
				},
				transaction: action.payload
			},
			null,
			() => {
				store.dispatch(
					trackingSuccess({
						confirmationCode: get(action, 'payload.confirmationCode')
					})
				);
			}
		);
	}

	return next(action);
};
