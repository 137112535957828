/* eslint-disable max-len */
import React from 'react';

export default function UpArrow() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.69 27.69" width="28" height="28" className="svg up-arrow">
			<path d="M13.85 27.69a13.85 13.85 0 1 1 13.84-13.84 13.86 13.86 0 0 1-13.84 13.84zm0-24A10.15 10.15 0 1 0 24 13.85 10.17 10.17 0 0 0 13.85 3.69z" />
			<path d="M13.85 21.68a2 2 0 0 1-2-2v-6.93l-.83.73a2 2 0 0 1-2.65-3l4.15-3.68a2 2 0 0 1 3.33 1.5v11.38a2 2 0 0 1-2 2z" />
			<path d="M18.23 13.69a1.84 1.84 0 0 1-1.16-.41l-4.38-3.54A1.85 1.85 0 0 1 15 6.87l4.38 3.54a1.85 1.85 0 0 1-1.16 3.28z" />
		</svg>
	);
}
