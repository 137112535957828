import { handleActions } from 'redux-actions';
import { get } from 'lodash';
import { EXPERIMENT } from './actions';

export const EXPERIMENT_STORE_NAME = 'experiments';
export const initialState = {
	bucket: {},
	forcedBucket: {},
	definitions: [],
	loggedImpressions: [],
	loading: false,
	loaded: false,
	error: null
};

export default handleActions({
	[EXPERIMENT.SET_EXPERIMENTS_BUCKET]: (state, action) => ({
		...state,
		bucket: { ...action.payload }
	}),
	[EXPERIMENT.CLEAR_EXPERIMENTS_BUCKET]: (state) => ({
		...state,
		loading: true,
		bucket: initialState.bucket,
		forcedBucket: initialState.forcedBucket
	}),
	[EXPERIMENT.INIT_EXPERIMENTS_REQUEST]: (state) => ({
		...state,
		loading: true
	}),
	[EXPERIMENT.INIT_EXPERIMENTS_SUCCESS]: (state) => ({
		...state,
		loading: false
	}),
	[EXPERIMENT.INIT_EXPERIMENTS_FAILURE]: (state) => ({
		...state,
		loading: false,
		error: true
	}),
	[EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_REQUEST]: (state) => ({
		...state,
		loading: true
	}),
	[EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_SUCCESS]: (state, action) => ({
		...state,
		definitions: get(action, 'payload.definitions', []),
		loading: false,
		loaded: true
	}),
	[EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_FAILURE]: (state) => ({
		...state,
		loading: false,
		loaded: false,
		error: true
	}),
	[EXPERIMENT.IMPRESSION_LOGGED]: (state, action) => ({
		...state,
		loggedImpressions: [
			...state.loggedImpressions,
			get(action, 'payload.experimentName', null)
		]
	})
}, initialState);
