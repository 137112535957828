import { get, map, keys, pickBy } from 'lodash';
import Segment from '@sparefoot/segment-react';
import { connect } from 'react-redux';
import { compose, branch, lifecycle } from 'recompose';

import { logImpression } from 'store/experiment/actions';
import mapDispatchToActions from 'store/helpers/mapDispatchToActions';
import experimentSelector from 'store/selectors/experiment';

export const INACTIVE_EXPERIMENT = 'inactive';

export const getExperimentTreatment = (experimentName, bucket, forcedBucket, defaultVariation = 'control') => {
	const buckets = {
		...forcedBucket,
		...(pickBy(bucket, (value) => value !== INACTIVE_EXPERIMENT))
	};
	const variation = get(buckets, experimentName) || INACTIVE_EXPERIMENT;
	if (variation === INACTIVE_EXPERIMENT) {
		return defaultVariation;
	}

	return variation;
};

// eslint-disable-next-line max-len
export const isExperimentActive = (experimentName, bucket) => (get(bucket, experimentName) || INACTIVE_EXPERIMENT) !== INACTIVE_EXPERIMENT;

const _trackSegmentEvent = (eventName, experimentName, experimentVariation) => {
	if (typeof window !== 'undefined') {
		Segment.track(eventName, {
			experiment_name: experimentName,
			experiment_variation: experimentVariation
		}, {
			integrations: {
				All: true,
				'Google Analytics': false
			}
		});
	}
};

export const logExperimentBucketing = (bucket) => {
	map(keys(bucket), (name) => {
		if (isExperimentActive(name, bucket)) {
			_trackSegmentEvent('experiment_bucketing', name, getExperimentTreatment(name, bucket));
		}
	});
};

export const logExperimentImpression = (experimentName, experimentVariation) => {
	_trackSegmentEvent('experiment_impression', experimentName, experimentVariation);
};

// Branches a component tree based on active and forced experiments
export const experimentBranch = (name, variation, render) => compose(
	connect(
		experimentSelector,
		{ logImpression },
		mapDispatchToActions
	),
	lifecycle({
		componentDidMount() {
			if (isExperimentActive(name, this.props.bucket)) {
				this.props.actions.logImpression(name);
			}
		}
	}),
	branch(
		({ bucket, forcedBucket }) => {
			const bucketedVariation = getExperimentTreatment(name, bucket, forcedBucket);
			return typeof variation === 'string' ?
				variation === bucketedVariation :
				variation.includes(bucketedVariation);
		},
		render
	)
);
