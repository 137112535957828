import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';
import isValid from 'date-fns/is_valid';
import classnames from 'classnames';
import { getContext } from 'recompose';
import { isFunction, isUndefined, noop } from 'lodash';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import { Calendar as CalendarSVG } from 'components/svg';
import CSSTransition from 'react-transition-group/CSSTransition';
import { on, off } from 'utils/dom/dom';

import { Calendar } from 'components/core/Calendar';
import { Input } from 'components/core/Input';

import './datepicker.scss';

const enhance = getContext({ segmentConfig: PropTypes.object });

const SEGMENT_LABEL = 'moveindate';

// TODO: Recompose
/**
 * A date picker that can be used to select move-in days. Each date on the calendar has [Segment](#segment-tracking)
 * built in. This component has all of the logic required to send the correct event to Segment based on whether or not
 * the date selected is valid or not.
 */
export class DatePicker extends PureComponent {
	static propTypes = {
		/** The last valid date that can be selected by the user. Instance of `moment`. */
		reservationWindow: PropTypes.instanceOf(Date),
		/** The current selected date */
		selectedDate: PropTypes.instanceOf(Date),
		/** A list of `moment` objects corresponding to days that the facility is closed. */
		closures: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		/** A HOC of the `CoreCalendar` component that will be rendered for the calendar. */
		calendarComponent: PropTypes.func,
		/** Function that will be called when a date is selected.
		 * Will be invoked with an object like: `{ date: `moment`, string: '2017-04-21', type: 'valid' }`
		 * where `date` is the `moment` object of the selected date, `string` is a formatted date, and `type` is the
		 * type of date (`past`, `today`, `valid`, `closed`, `invalid`).
		 * */
		className: PropTypes.string,
		onDateClick: PropTypes.func,
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		onDatepickerToggle: PropTypes.func,
		name: PropTypes.string,
		errorMessage: PropTypes.string,
		hasError: PropTypes.bool,
		segmentConfig: PropTypes.object,
		today: PropTypes.instanceOf(Date),
		validator: PropTypes.func,
		headerLabel: PropTypes.string,
		segmentLabel: PropTypes.string,
		allValid: PropTypes.bool
	};

	static defaultProps = {
		calendarComponent: Calendar,
		today: new Date(),
		onChange: noop,
		onDatepickerToggle: noop,
		onFocus: noop,
		validator: noop
	};

	constructor(props) {
		super(props);
		this.state = {
			today: props.today,
			date: props.selectedDate && isValid(props.selectedDate) ? format(props.selectedDate, 'MM/DD/YYYY') : '',
			calendarOpen: false
		};
	}

	componentDidMount() {
		on('click', this.pageClick);
	}

	componentWillUnmount() {
		off('click', this.pageClick);
	}

	hideCalendar = () => {
		this.setState({ calendarOpen: false });
		this.props.onDatepickerToggle(false);
	};

	handleDateClick = (date) => {
		if (isFunction(this.props.onDateClick)) {
			this.props.onDateClick(date);
		}

		const dateDiff = differenceInCalendarDays(date.date, this.state.today);
		if ((this.props.reservationWindow && !date.type.open) || (!this.props.allValid && dateDiff < 0)) {
			if (!isUndefined(this.props.segmentConfig)) {
				// User clicked a bad date (either closed, in the past, or beyond res window)
				Segment.trackClick(SegmentConfig.buildTrackingProps(
					this.props.segmentConfig,
					{
						segmentLabel: `disabled calendar date-${dateDiff}`,
						segmentProperties: {
							moveInDateSelected: format(date.date, 'MM/DD/YYYY')
						}
					}
				));
			}
			return;
		}

		// Picked a valid date
		if (this.props.segmentConfig) {
			Segment.track('valid', SegmentConfig.buildTrackingProps(
				this.props.segmentConfig,
				{
					segmentLabel: SEGMENT_LABEL,
					segmentProperties: {
						moveInDateSelected: format(date.date, 'MM/DD/YYYY')
					}
				}
			));
		}


		this._handleValidSelection(date);

		// simulate an onChange like a regular input
		this.props.validator(date.string);
		this.props.onChange({
			target: {
				name: this.props.name,
				value: date
			}
		});
	};

	_handleFocus = (e) => {
		this.props.onFocus(e);
		this.datepickerInput.blur();
		this.showCalendar();
	}

	_setInput = (input) => {
		this.datepickerInput = input;
	};

	pageClick = (event) => {
		const domNode = this.ref;

		if ((!domNode || !domNode.contains(event.target))) {
			this.setState({
				calendarOpen: false
			});
		}
	};

	_handleValidSelection(date) {
		this.setState({ date: date.string });
		this.hideCalendar();
	}

	showCalendar() {
		this.setState({ calendarOpen: true });
		this.props.onDatepickerToggle(true);
	}

	_getPosition() {
		if (!this.ref || typeof window === 'undefined') {
			return null;
		}

		const distanceToViewportTop = this.ref.getBoundingClientRect().top;
		const elementHeight = this.ref.offsetHeight;
		const viewportHeight = window.innerHeight;
		const distanceToViewportBottom = viewportHeight - elementHeight - distanceToViewportTop;
		// this is definitely a hack since at this point the calendar has not rendered
		// to the DOM, so this.calendarRef.offsetHeight will equal 0
		const heightGuess = 360;

		const inBounds = (distanceToViewportBottom < heightGuess && distanceToViewportTop < heightGuess);
		if (distanceToViewportBottom > distanceToViewportTop || inBounds) {
			return 'below';
		}
		return 'above';
	}

	render() {
		return (
			<div
				className={classnames(this.props.className, this._getPosition(), 'ph-datepicker')}
				ref={(ref) => { this.ref = ref; }}
			>
				<Input
					id="datepicker"
					value={this.state.date}
					label={(<span><CalendarSVG />{this.props.label}</span>)}
					onFocus={this._handleFocus}
					onBlur={this.pageClick}
					segmentLabel={SEGMENT_LABEL}
					errorMessage={this.props.errorMessage}
					hasError={this.props.hasError}
					validator={this.props.validator}
					getRef={this._setInput}
					readOnly
				/>
				<CSSTransition
					classNames="transition-fade-in"
					in={this.state.calendarOpen}
					mountOnEnter
					unmountOnExit
					timeout={150}
				>
					{React.createElement(
						this.props.calendarComponent,
						{
							closures: this.props.closures,
							headerLabel: this.props.headerLabel,
							reservationWindow: this.props.reservationWindow,
							segmentConfig: this.props.segmentConfig,
							segmentLabel: this.props.segmentLabel,
							calendarRef: (ref) => { this.calendarRef = ref; },
							onMouseDown: () => { this.mouseDownOnCal = true; },
							onMouseUp: () => { this.mouseDownOnCal = false; },
							onCloseClick: this.hideCalendar,
							today: this.state.today,
							onDateClick: this.handleDateClick,
							className: this._getPosition(),
							allValid: this.props.allValid
						}
					)}
				</CSSTransition>
			</div>
		);
	}
}

export default enhance(DatePicker);
