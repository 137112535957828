import { compose, withHandlers } from 'recompose';
import { isFunction, isBoolean } from 'lodash';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from '../context/getSegmentContext';


export default compose(
	getSegmentContext,
	withHandlers(({

		onFocus: (props) => (evt) => {
			const segmentProps = SegmentConfig.buildTrackingProps(
				props.segmentConfig,
				{
					segmentLabel: props.segmentLabel,
					segmentProperties: props.segmentProperties
				}
			);

			Segment.track('focus', segmentProps);

			/* istanbul ignore else */
			if (isFunction(props.onFocus)) {
				props.onFocus(evt);
			}
		},

		onBlur: (props) => (evt) => {
			const segmentProps = SegmentConfig.buildTrackingProps(
				props.segmentConfig,
				{
					segmentLabel: props.segmentLabel,
					segmentProperties: props.segmentProperties
				}
			);

			/* istanbul ignore else */
			if (isBoolean(props.valid) && props.valid) {
				Segment.track('valid', segmentProps);
			} else if (isBoolean(props.valid) && props.value && !!props.value.length) {
				Segment.track('invalid', segmentProps);
			}

			/* istanbul ignore else */
			if (isFunction(props.onBlur)) {
				props.onBlur(evt);
			}
		}

	}))
);
