import { compose, withHandlers, defaultProps, branch } from 'recompose';
import { noop } from 'lodash';
import isMeta from '@segment/is-meta';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import { parse } from 'querystring';

import setWrappedDisplayName from 'decorators/setWrappedDisplayName';
import { setLocation } from 'lib/helpers';
import getEcommerceProps from 'lib/segment/ecommerce';
import getSegmentContext from '../context/getSegmentContext';
import ClickDebugger from './ClickDebugger';

// Parse the query params from the current URL
const queryParams = (
	typeof window !== 'undefined' &&
	window.location &&
	parse(window.location.search.replace(/^\?/, ''))
);

const enhance = compose(
	getSegmentContext,
	defaultProps({
		onClick: noop
	}),
	withHandlers({
		handleClick: ({
			onClick, segmentConfig, segmentLabel, segmentProperties, href, target, productClick
		}) => (evt) => {
			if (segmentLabel) {
				const segmentProps = SegmentConfig.buildTrackingProps(
					segmentConfig,
					{ segmentLabel, segmentProperties }
				);
				Segment.trackClick(segmentProps);

				if (segmentConfig && segmentConfig.trackEcommerceClick && productClick) {
					const ecommerceProps = getEcommerceProps('Product Clicked', segmentConfig.ecommerceProps);
					Segment.track('Product Clicked', ecommerceProps);
				}
			}

			if (href && target !== '_blank' && !isMeta(evt)) {
				evt.preventDefault();
				setLocation(href);
			}
			Promise.resolve(onClick(evt));
		}
	}),
	branch(() => queryParams.debug === 'segment', ClickDebugger),
	setWrappedDisplayName('SegmentClickTracking')
);

export default enhance;
