import React from 'react';
import PropTypes from 'prop-types';
import { mergeUrlQueryParams } from 'utils/url';

const TrackingPixel = ({ id, trackingData }) => {
	const visitVerifyUrl = `${trackingData.trackingURL}/imageVerifyVisit?${trackingData.visitVerifySrc}`;

	// merge the page tracking id with the tracking pixel parameters
	const pagePixelUrl = mergeUrlQueryParams(trackingData.pagePixelSrc, {
		a: trackingData.trackingId
	});

	return (
		<>
			{id === 'visitVerifySrc' ? (
				<img
					alt="tracking-pixel"
					className="tracking-pixel"
					src={visitVerifyUrl}
				/>
			) : (
				<img
					alt="tracking-pixel"
					className="tracking-pixel"
					src={`${trackingData.trackingURL}/pixel/type/${pagePixelUrl}`}
				/>
			)}
		</>
	);
};

TrackingPixel.propTypes = {
	id: PropTypes.string,
	trackingData: PropTypes.object
};

export default TrackingPixel;
