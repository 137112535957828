import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import experiments from './experiment/reducers';
import app from './app/reducers';
import bookings from './bookings/reducers';
import content from './content/reducers';
import facilities from './facilities/reducers';
import pageMeta from './pageMeta/reducers';
import reviews from './reviews/reducers';
import search from './search/reducers';
import units from './units/reducers';
import user from './user/reducers';
import fileUpload from './fileUpload/reducers';

export default combineReducers({
	app,
	bookings,
	content,
	facilities,
	form,
	pageMeta,
	reviews,
	search,
	units,
	user,
	experiments,
	fileUpload
});
