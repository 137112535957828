import { logBucketing } from '../experiment/actions';
import { APP } from '../app/actions';
import { getUserGAClientId } from '../user/actions';

export const readyMiddleware = (store) => (next) => (action) => {
	if (action.type === APP.READY) {
		store.dispatch(logBucketing());
		store.dispatch(getUserGAClientId());
	}

	return next(action);
};
