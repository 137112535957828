/* eslint-disable max-len */
import React from 'react';

export default function MedicalCross() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9" className="svg cross">
			<path d="M2.398 0v2.398H0v4.203h2.399V9h4.203V6.601H9V2.398H6.602V0H2.398zM1.286 3.642h2.356V1.286h1.716v2.356h2.356v1.716H5.358v2.356H3.642V5.358H1.286V3.642z" fill="#FFF" fillRule="evenodd" />
		</svg>
	);
}
