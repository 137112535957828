import { createAction } from 'redux-actions';
import { includes } from 'lodash';
import Cookies from 'universal-cookie';
import { API } from 'store/middleware/api';
import {
	getExperimentTreatment,
	logExperimentBucketing,
	logExperimentImpression,
	isExperimentActive
} from 'utils/experimentUtils';

export const EXPERIMENT = {
	SET_EXPERIMENTS_BUCKET: 'SFDC/EXPERIMENT/SET_EXPERIMENTS_BUCKET',
	CLEAR_EXPERIMENTS_BUCKET: 'SFDC/EXPERIMENT/CLEAR_EXPERIMENTS_BUCKET',
	IMPRESSION_LOGGED: 'SFDC/EXPERIMENT/IMPRESSION_LOGGED',
	BUCKETING_LOGGED: 'SFDC/EXPERIMENT/BUCKETING_LOGGED',
	GET_EXPERIMENTS_DEFINITIONS_REQUEST: 'SFDC/EXPERIMENT/GET_EXPERIMENTS_DEFINITIONS_REQUEST',
	GET_EXPERIMENTS_DEFINITIONS_SUCCESS: 'SFDC/EXPERIMENT/GET_EXPERIMENTS_DEFINITIONS_SUCCESS',
	GET_EXPERIMENTS_DEFINITIONS_FAILURE: 'SFDC/EXPERIMENT/GET_EXPERIMENTS_DEFINITIONS_FAILURE',
	INIT_EXPERIMENTS_REQUEST: 'SFDC/EXPERIMENT/INIT_EXPERIMENTS_REQUEST',
	INIT_EXPERIMENTS_SUCCESS: 'SFDC/EXPERIMENT/INIT_EXPERIMENTS_SUCCESS',
	INIT_EXPERIMENTS_FAILURE: 'SFDC/EXPERIMENT/INIT_EXPERIMENTS_FAILURE'
};

export function getExperimentsDefinitions() {
	return {
		[API]: {
			types: [
				EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_REQUEST,
				EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_SUCCESS,
				EXPERIMENT.GET_EXPERIMENTS_DEFINITIONS_FAILURE
			],
			method: 'GET',
			endpoint: '/experiments/definitions'
		}
	};
}

export function initExperiments(data) {
	return {
		[API]: {
			types: [
				EXPERIMENT.INIT_EXPERIMENTS_REQUEST,
				EXPERIMENT.INIT_EXPERIMENTS_SUCCESS,
				EXPERIMENT.INIT_EXPERIMENTS_FAILURE
			],
			method: 'POST',
			endpoint: '/experiments/init',
			data
		}
	};
}

export const setExperimentsBucket = createAction(EXPERIMENT.SET_EXPERIMENTS_BUCKET);
export const clearExperimentsBucket = createAction(EXPERIMENT.CLEAR_EXPERIMENTS_BUCKET);
export const bucketingLogged = createAction(EXPERIMENT.BUCKETING_LOGGED);
export const impressionLogged = createAction(EXPERIMENT.IMPRESSION_LOGGED, (experimentName, variationName) => ({
	experimentName,
	variationName
}));


export const logBucketing = () => (dispatch, getState) => {
	const { experiments } = getState();
	logExperimentBucketing(experiments.bucket);
	dispatch(bucketingLogged(experiments.bucket));
};

export const logImpression = (experimentName) => (dispatch, getState) => {
	const { experiments } = getState();
	if (!includes(experiments.loggedImpressions, experimentName)) {
		const variationName = getExperimentTreatment(experimentName, experiments.bucket);
		if (isExperimentActive(experimentName, experiments.bucket)) {
			logExperimentImpression(experimentName, variationName);
			dispatch(impressionLogged(experimentName, variationName));
		}
	}
};

export const clearForcedExperiments = () => (dispatch) => {
	const cookies = new Cookies();
	cookies.remove('visit', { path: '/' });
	cookies.remove('visitor', { path: '/' });
	cookies.remove('visitor_id', { path: '/' });
	cookies.remove('sf_forced_experiments', { path: '/' });
	dispatch(clearExperimentsBucket());
};
