/* eslint-disable max-len */
import React from 'react';

export default function Mover() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="29.91" height="37.18" viewBox="0 0 29.91 37.18" className="svg mover">
			<path d="M6.56 8.22a4.11 4.11 0 1 1 4.11-4.11 4.11 4.11 0 0 1-4.11 4.11zm0-6.5a2.39 2.39 0 1 0 2.39 2.39 2.4 2.4 0 0 0-2.39-2.39z" />
			<path d="M12.68 34.57l-.75-6.34-2.31-7.49V18H7.87v3l2.36 7.58.77 6.18a.52.52 0 0 1-.1.39.5.5 0 0 1-.34.2h-.1a.49.49 0 0 1-.47-.43l-.77-6.22L7.11 24l.11-1.4a.86.86 0 1 0-1.71-.14l-.13 1.61-.24 3.03L2.79 35a.53.53 0 0 1-.5.38h-.15a.54.54 0 0 1-.32-.25.51.51 0 0 1 0-.39L4 26.78 3.77 21l-.06-8.55-.05-.77a2.1 2.1 0 0 1 4.21 0v3.14h1.75v-3.11a3.77 3.77 0 1 0-7.55 0l-.07.75-.08 8.6.32 5.5-2.16 7.63A2.21 2.21 0 0 0 .3 35.9 2.26 2.26 0 0 0 1.65 37a2.31 2.31 0 0 0 .64.09 2.25 2.25 0 0 0 2.15-1.61l2.36-7.95.74 1.64.7 6a2.22 2.22 0 0 0 2.22 1.95 1.32 1.32 0 0 0 .27 0 2.24 2.24 0 0 0 1.95-2.48z" />
			<circle fill="none" cx="19.39" cy="33.4" r="2.92" />
			<path d="M19.39 37.18a3.78 3.78 0 1 1 3.78-3.78 3.79 3.79 0 0 1-3.78 3.78zm0-5.85a2.07 2.07 0 1 0 2.07 2.07 2.07 2.07 0 0 0-2.07-2.06zM17 26.08a.86.86 0 0 1-.28-1.67l7.81-2.72a.86.86 0 1 1 .56 1.62L17.28 26a.86.86 0 0 1-.28.08z" />
			<path d="M19.4 31.28h.31l-5.53-16.59a.86.86 0 1 0-1.62.56L18 31.67a2.63 2.63 0 0 1 1.4-.39zM29.87 30.28a.86.86 0 0 0-1.09-.52l-7.36 2.56a2.58 2.58 0 0 1 .48 1.61l7.38-2.57a.9.9 0 0 0 .59-1.08z" />
			<path d="M27.68 31.68a.86.86 0 0 1-.82-.61L22.18 15.7l-7.67 2.49a.86.86 0 0 1-.51-1.63l8.5-2.76a.86.86 0 0 1 1.09.57l4.94 16.21a.86.86 0 0 1-.82 1.11z" />
			<path fill="none" d="M5.08 15.05l3.36 4.1L13 14.8a1.3 1.3 0 0 0 0-1.8 1.3 1.3 0 0 0-1.85-.11L8.79 15l-3.25-3.68" />
			<path d="M8.37 20.41l-4-4.81a.86.86 0 1 1 1.33-1.09l2.77 3.38 3.84-3.7a.44.44 0 0 0-.61-.64l-3 2.69-3.8-4.36a.86.86 0 1 1 1.29-1.13l2.68 3.07 1.73-1.54a2.16 2.16 0 0 1 3 3.13z" />
		</svg>
	);
}
