import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isSameMonth from 'date-fns/is_same_month';
import format from 'date-fns/format';

import { SFIcon } from 'components/core/SFIcon';
import { Button } from 'components/core/Button';
import { Text } from 'components/core/Typography';

import './calendarHeader.scss';

export const enhance = compose(
	withProps((props) => ({
		isPrevDisabled: !props.allValid && isSameMonth(props.today, props.month)
	})),
	withHandlers({
		handlePrevClick: (props) => (evt) => {
			if (!props.isPrevDisabled) {
				props.onPreviousClick(evt);
			}
		}
	})
);

export function CalendarHeader({
	onCloseClick,
	segmentLabel,
	headerLabel,
	isPrevDisabled,
	handlePrevClick,
	month,
	onNextClick
}) {
	return (
		<div className="ph-calendar-header">
			<Button
				primary={false}
				className="ph-calendar-close"
				onClick={onCloseClick}
				segmentLabel={`${segmentLabel}-close`}
			>
				<SFIcon icon="close" />
			</Button>
			<div className="ph-calendar-controls">
				{headerLabel &&
					<div className="header-label">
						{headerLabel}
					</div>
				}
				<div className="header-month">
					<Button
						primary={false}
						disabled={isPrevDisabled}
						className={classnames({ disabled: isPrevDisabled })}
						onClick={handlePrevClick}
						segmentLabel={`${segmentLabel}-prevMonth`}
					>
						<SFIcon icon="chevron-left" />
					</Button>
					<Text
						className="month"
						size="medium"
					>
						{format(month, 'MMMM YYYY')}
					</Text>
					<Button
						primary={false}
						onClick={onNextClick}
						segmentLabel={`${segmentLabel}-nextMonth`}
					>
						<SFIcon icon="chevron-right" />
					</Button>
				</div>
			</div>
		</div>
	);
}

CalendarHeader.propTypes = {
	headerLabel: PropTypes.node,
	isPrevDisabled: PropTypes.bool,
	handlePrevClick: PropTypes.func,
	month: PropTypes.instanceOf(Date),
	onNextClick: PropTypes.func,
	onCloseClick: PropTypes.func,
	segmentLabel: PropTypes.string
};

CalendarHeader.defaultProps = {
	segmentLabel: undefined
};

export default enhance(CalendarHeader);
