import { API } from 'store/middleware/api';
import { get } from 'lodash';
import { createAction } from 'redux-actions';
import { bookingConfirmationSchema, bookingSchema } from 'store/schema/booking';
import { searchSchema, searchResponseSchema } from 'store/schema/search';
import { unitSchema } from 'store/schema/units';
import { facilitySchema } from 'store/schema/facilities';
import { getFacilityByEmailToken } from 'store/facilities/actions';
import { getReviewFacilitySuccess } from 'store/reviews/actions';
import formatSearchFacilities from 'utils/store/formatSearchFacilities';
import formatTruckResponse from 'utils/store/formatTruckResponse';
import { getStateAbbreviation } from 'utils/state';
import {
	PaidLandingTypes,
	PPC_TYPE_STORAGE,
} from '../../pages/PaidTemplatePage/constants';

export const PAGES = {
	HOMEPAGE_REQUEST: 'SFDC/PAGES/HOMEPAGE_REQUEST',
	HOMEPAGE_SUCCESS: 'SFDC/PAGES/HOMEPAGE_SUCCESS',
	HOMEPAGE_FAILURE: 'SFDC/PAGES/HOMEPAGE_FAILURE',
	UNSUBSCRIBE_PAGE_REQUEST: 'SFDC/PAGES/UNSUBSCRIBE_PAGE_REQUEST',
	UNSUBSCRIBE_PAGE_SUCCESS: 'SFDC/PAGES/UNSUBSCRIBE_PAGE_SUCCESS',
	UNSUBSCRIBE_PAGE_FAILURE: 'SFDC/PAGES/UNSUBSCRIBE_PAGE_FAILURE',
	BOOKING_PAGE_REQUEST: 'SFDC/PAGES/BOOKING_PAGE_REQUEST',
	BOOKING_PAGE_SUCCESS: 'SFDC/PAGES/BOOKING_PAGE_SUCCESS',
	BOOKING_PAGE_FAILURE: 'SFDC/PAGES/BOOKING_PAGE_FAILURE',
	LEGAL_PAGE_REQUEST: 'SFDC/PAGES/LEGAL_PAGE_REQUEST',
	LEGAL_PAGE_SUCCESS: 'SFDC/PAGES/LEGAL_PAGE_SUCCESS',
	LEGAL_PAGE_FAILURE: 'SFDC/PAGES/LEGAL_PAGE_FAILURE',
	PPC_LANDING_REQUEST: 'SFDC/PAGES/PPC_LANDING_REQUEST',
	PPC_LANDING_SUCCESS: 'SFDC/PAGES/PPC_LANDING_SUCCESS',
	PPC_LANDING_FAILURE: 'SFDC/PAGES/PPC_LANDING_FAILURE',
	REBATE_CLAIM_PAGE_REQUEST: 'SFDC/PAGES/REBATE_CLAIM_PAGE_REQUEST',
	REBATE_CLAIM_PAGE_SUCCESS: 'SFDC/PAGES/REBATE_CLAIM_PAGE_SUCCESS',
	REBATE_CLAIM_PAGE_FAILURE: 'SFDC/PAGES/REBATE_CLAIM_PAGE_FAILURE',
	REBATE_STATUS_PAGE_REQUEST: 'SFDC/PAGES/REBATE_STATUS_PAGE_REQUEST',
	REBATE_STATUS_PAGE_SUCCESS: 'SFDC/PAGES/REBATE_STATUS_PAGE_SUCCESS',
	REBATE_STATUS_PAGE_FAILURE: 'SFDC/PAGES/REBATE_STATUS_PAGE_FAILURE',
	MOVEIN_INCENTIVE_PAGE_REQUEST: 'SFDC/PAGES/MOVEIN_INCENTIVE_PAGE_REQUEST',
	MOVEIN_INCENTIVE_PAGE_SUCCESS: 'SFDC/PAGES/MOVEIN_INCENTIVE_PAGE_SUCCESS',
	MOVEIN_INCENTIVE_PAGE_FAILURE: 'SFDC/PAGES/MOVEIN_INCENTIVE_PAGE_FAILURE',
	MOVING_SUPPLIES_PAGE_REQUEST: 'SFDC/PAGES/MOVING_SUPPLIES_PAGE_REQUEST',
	MOVING_SUPPLIES_PAGE_SUCCESS: 'SFDC/PAGES/MOVING_SUPPLIES_PAGE_SUCCESS',
	MOVING_SUPPLIES_PAGE_FAILURE: 'SFDC/PAGES/MOVING_SUPPLIES_PAGE_FAILURE',
	PORTABLE_STORAGE_PAGE_REQUEST: 'SFDC/PAGES/PORTABLE_STORAGE_PAGE_REQUEST',
	PORTABLE_STORAGE_PAGE_SUCCESS: 'SFDC/PAGES/PORTABLE_STORAGE_PAGE_SUCCESS',
	PORTABLE_STORAGE_PAGE_FAILURE: 'SFDC/PAGES/PORTABLE_STORAGE_PAGE_FAILURE',
	PORTABLE_STORAGE_CLP_REQUEST: 'SFDC/PAGES/PORTABLE_STORAGE_CLP_REQUEST',
	PORTABLE_STORAGE_CLP_SUCCESS: 'SFDC/PAGES/PORTABLE_STORAGE_CLP_SUCCESS',
	PORTABLE_STORAGE_CLP_FAILURE: 'SFDC/PAGES/PORTABLE_STORAGE_CLP_FAILURE',
	UNIT_PAGE_REQUEST: 'SFDC/PAGES/UNIT_PAGE_REQUEST',
	UNIT_PAGE_SUCCESS: 'SFDC/PAGES/UNIT_PAGE_SUCCESS',
	UNIT_PAGE_FAILURE: 'SFDC/PAGES/UNIT_PAGE_FAILURE',
	REVIEW_PAGE_REQUEST: 'SFDC/PAGES/REVIEW_PAGE_REQUEST',
	REVIEW_PAGE_SUCCESS: 'SFDC/PAGES/REVIEW_PAGE_SUCCESS',
	REVIEW_PAGE_FAILURE: 'SFDC/PAGES/REVIEW_PAGE_FAILURE',
	STATE_LANDING_PAGE_REQUEST: 'SFDC/PAGES/STATE_LANDING_PAGE_REQUEST',
	STATE_LANDING_PAGE_SUCCESS: 'SFDC/PAGES/STATE_LANDING_PAGE_SUCCESS',
	STATE_LANDING_PAGE_FAILURE: 'SFDC/PAGES/STATE_LANDING_PAGE_FAILURE',
	PSP_PAGE_REQUEST: 'SFDC/PAGES/PSP_PAGE_REQUEST',
	PSP_PAGE_SUCCESS: 'SFDC/PAGES/PSP_PAGE_SUCCESS',
	PSP_PAGE_FAILURE: 'SFDC/PAGES/PSP_PAGE_FAILURE',
	PAID_LANDING_TEMPLATE_REQUEST: 'SFDC/PAGES/PAID_LANDING_TEMPLATE_REQUEST',
	PAID_LANDING_TEMPLATE_SUCCESS: 'SFDC/PAGES/PAID_LANDING_TEMPLATE_SUCCESS',
	PAID_LANDING_TEMPLATE_FAILURE: 'SFDC/PAGES/PAID_LANDING_TEMPLATE_FAILURE',
	CAR_PSP_PAGE_REQUEST: 'SFDC/PAGES/CAR_PSP_PAGE_REQUEST',
	CAR_PSP_PAGE_SUCCESS: 'SFDC/PAGES/CAR_PSP_PAGE_SUCCESS',
	CAR_PSP_PAGE_FAILURE: 'SFDC/PAGES/CAR_PSP_PAGE_FAILURE',
	BOAT_PSP_PAGE_REQUEST: 'SFDC/PAGES/BOAT_PSP_PAGE_REQUEST',
	BOAT_PSP_PAGE_SUCCESS: 'SFDC/PAGES/BOAT_PSP_PAGE_SUCCESS',
	BOAT_PSP_PAGE_FAILURE: 'SFDC/PAGES/BOAT_PSP_PAGE_FAILURE',
	RV_PSP_PAGE_REQUEST: 'SFDC/PAGES/RV_PSP_PAGE_REQUEST',
	RV_PSP_PAGE_SUCCESS: 'SFDC/PAGES/RV_PSP_PAGE_SUCCESS',
	RV_PSP_PAGE_FAILURE: 'SFDC/PAGES/RV_PSP_PAGE_FAILURE',
	STORAGE_PAGE_REQUEST: 'SFDC/PAGES/STORAGE_PAGE_REQUEST',
	STORAGE_PAGE_SUCCESS: 'SFDC/PAGES/STORAGE_PAGE_SUCCESS',
	STORAGE_PAGE_FAILURE: 'SFDC/PAGES/STORAGE_PAGE_FAILURE',
	MOVING_TRUCK_PAGE_REQUEST: 'SFDC/PAGES/MOVING_TRUCK_PAGE_REQUEST',
	MOVING_TRUCK_PAGE_SUCCESS: 'SFDC/PAGES/MOVING_TRUCK_PAGE_SUCCESS',
	MOVING_TRUCK_PAGE_FAILURE: 'SFDC/PAGES/MOVING_TRUCK_PAGE_FAILURE',
	MOVING_TRUCK_CLP_REQUEST: 'SFDC/PAGES/MOVING_TRUCK_CLP_REQUEST',
	MOVING_TRUCK_CLP_SUCCESS: 'SFDC/PAGES/MOVING_TRUCK_CLP_SUCCESS',
	MOVING_TRUCK_CLP_FAILURE: 'SFDC/PAGES/MOVING_TRUCK_CLP_FAILURE',
	MOVE_IN_CONFIRM_STATUS_PAGE_REQUEST:
		'SFDC/PAGES/MOVE_IN_CONFIRM_STATUS_REQUEST',
	MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS:
		'SFDC/PAGES/MOVE_IN_CONFIRM_STATUS_SUCCESS',
	MOVE_IN_CONFIRM_STATUS_PAGE_FAILURE:
		'SFDC/PAGES/MOVE_IN_CONFIRM_STATUS_FAILURE',
	VEHICLE_LENGTH_PAGE_REQUEST: 'SFDC/PAGES/VEHICLE_LENGTH_REQUEST',
	VEHICLE_LENGTH_PAGE_SUCCESS: 'SFDC/PAGES/VEHICLE_LENGTH_SUCCESS',
	VEHICLE_LENGTH_PAGE_FAILURE: 'SFDC/PAGES/VEHICLE_LENGTH_FAILURE',
};

export const vehicleLengthPageSuccess = createAction(
	PAGES.VEHICLE_LENGTH_PAGE_SUCCESS
);

export function getHomepage() {
	return {
		[API]: {
			types: [
				PAGES.HOMEPAGE_REQUEST,
				PAGES.HOMEPAGE_SUCCESS,
				PAGES.HOMEPAGE_FAILURE,
			],
			endpoint: '/page/index',
		},
	};
}

export function getUnsubscribePage() {
	return {
		[API]: {
			types: [
				PAGES.UNSUBSCRIBE_PAGE_REQUEST,
				PAGES.UNSUBSCRIBE_PAGE_SUCCESS,
				PAGES.UNSUBSCRIBE_PAGE_FAILURE,
			],
			endpoint: '/page/generic/unsubscribe',
		},
	};
}

export function getBookingPage(confirmationCode) {
	return {
		[API]: {
			types: [
				PAGES.BOOKING_PAGE_REQUEST,
				PAGES.BOOKING_PAGE_SUCCESS,
				PAGES.BOOKING_PAGE_FAILURE,
			],
			endpoint: `/page/booking/${confirmationCode}`,
			schema: bookingConfirmationSchema,
		},
	};
}

export function getLegalPage(pageName) {
	return {
		[API]: {
			types: [
				PAGES.LEGAL_PAGE_REQUEST,
				PAGES.LEGAL_PAGE_SUCCESS,
				PAGES.LEGAL_PAGE_FAILURE,
			],
			endpoint: `/page/generic/${pageName}`,
		},
	};
}

export function getPPCLandingPage() {
	return {
		[API]: {
			types: [
				PAGES.PPC_LANDING_REQUEST,
				PAGES.PPC_LANDING_SUCCESS,
				PAGES.PPC_LANDING_FAILURE,
			],
			endpoint: '/page/ppcLanding',
		},
	};
}

export function getRebateClaimPage() {
	return {
		[API]: {
			types: [
				PAGES.REBATE_CLAIM_PAGE_REQUEST,
				PAGES.REBATE_CLAIM_PAGE_SUCCESS,
				PAGES.REBATE_CLAIM_PAGE_FAILURE,
			],
			endpoint: '/page/generic/rebate',
		},
	};
}

export function getRebateStatusPage() {
	return {
		[API]: {
			types: [
				PAGES.REBATE_STATUS_PAGE_REQUEST,
				PAGES.REBATE_STATUS_PAGE_SUCCESS,
				PAGES.REBATE_STATUS_PAGE_FAILURE,
			],
			endpoint: '/page/generic/rebate',
		},
	};
}

export function getMoveInIncentivePage(confirmationCode, offerId) {
	return {
		[API]: {
			types: [
				PAGES.MOVEIN_INCENTIVE_PAGE_REQUEST,
				PAGES.MOVEIN_INCENTIVE_PAGE_SUCCESS,
				PAGES.MOVEIN_INCENTIVE_PAGE_FAILURE,
			],
			endpoint: `/page/booking/${confirmationCode}/offer/${offerId}`,
			schema: { booking: bookingSchema },
		},
	};
}

export function getMovingSuppliesPage() {
	return (dispatch, getState) => {
		const { app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.MOVING_SUPPLIES_PAGE_REQUEST,
					PAGES.MOVING_SUPPLIES_PAGE_SUCCESS,
					PAGES.MOVING_SUPPLIES_PAGE_FAILURE,
				],
				endpoint: '/page/generic/movingSupplies',
				meta: {
					baseURL: get(app, 'baseUrl', ''),
				},
			},
		});
	};
}

export function getUnitPage(unitId, facilityId) {
	return {
		[API]: {
			types: [
				PAGES.UNIT_PAGE_REQUEST,
				PAGES.UNIT_PAGE_SUCCESS,
				PAGES.UNIT_PAGE_FAILURE,
			],
			endpoint: `/page/unit/${unitId}/${facilityId}`,
			schema: {
				facility: facilitySchema,
				unit: unitSchema,
			},
		},
	};
}

export function getReviewPage() {
	return {
		[API]: {
			types: [
				PAGES.REVIEW_PAGE_REQUEST,
				PAGES.REVIEW_PAGE_SUCCESS,
				PAGES.REVIEW_PAGE_FAILURE,
			],
			endpoint: '/page/generic/review',
		},
	};
}

export function getSupplementalReviewData(query) {
	return (dispatch) => {
		if (query.email_token) {
			return dispatch(getFacilityByEmailToken(query.email_token)).then(
				({ payload }) => {
					dispatch(getReviewFacilitySuccess(payload));
					return payload;
				}
			);
		}
		return Promise.resolve();
	};
}

export function getStateLandingPage(state) {
	const stateAbbr = getStateAbbreviation(state);
	return {
		[API]: {
			types: [
				PAGES.STATE_LANDING_PAGE_REQUEST,
				PAGES.STATE_LANDING_PAGE_SUCCESS,
				PAGES.STATE_LANDING_PAGE_FAILURE,
			],
			endpoint: '/stateContent',
			query: {
				state: stateAbbr,
			},
		},
	};
}

export function getStorageLandingPage() {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.STORAGE_PAGE_REQUEST,
					PAGES.STORAGE_PAGE_SUCCESS,
					PAGES.STORAGE_PAGE_FAILURE,
				],
				endpoint: '/page/genericIp/storage',
				query: {
					listingsPerPage: 4,
					searchType: 'storage-only',
					ip: get(user, 'user_ip'),
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema,
				meta: {
					page: 'storage',
					baseURL: get(app, 'baseUrl', ''),
				},
			},
		});
	};
}

export function getCarStorageLandingPage() {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.CAR_PSP_PAGE_REQUEST,
					PAGES.CAR_PSP_PAGE_SUCCESS,
					PAGES.CAR_PSP_PAGE_FAILURE,
				],
				endpoint: '/page/genericIp/storageCar',
				query: {
					listingsPerPage: 4,
					searchType: 'vehicle',
					ip: get(user, 'user_ip'),
				},
				meta: {
					page: 'storageCar',
					baseURL: get(app, 'baseUrl', ''),
					storageType: 'car',
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema,
			},
		});
	};
}

export function getBoatStorageLandingPage() {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.BOAT_PSP_PAGE_REQUEST,
					PAGES.BOAT_PSP_PAGE_SUCCESS,
					PAGES.BOAT_PSP_PAGE_FAILURE,
				],
				endpoint: '/page/genericIp/storageBoat',
				query: {
					listingsPerPage: 4,
					searchType: 'vehicle',
					vehicle: 'boat',
					ip: get(user, 'user_ip'),
				},
				meta: {
					page: 'storageBoat',
					baseURL: get(app, 'baseUrl', ''),
					storageType: 'boat',
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema,
			},
		});
	};
}

export function getRVStorageLandingPage() {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.RV_PSP_PAGE_REQUEST,
					PAGES.RV_PSP_PAGE_SUCCESS,
					PAGES.RV_PSP_PAGE_FAILURE,
				],
				endpoint: '/page/genericIp/storageRv',
				query: {
					listingsPerPage: 4,
					searchType: 'vehicle',
					vehicle: 'rv',
					ip: get(user, 'user_ip'),
				},
				meta: {
					page: 'storageRv',
					baseURL: get(app, 'baseUrl', ''),
					storageType: 'rv',
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema,
			},
		});
	};
}

export function getPSPLandingPage(storageType = '') {
	return (dispatch, getState) => {
		const page = `storage${storageType
			.charAt(0)
			.toUpperCase()}${storageType.slice(1)}`;
		const { app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.PSP_PAGE_REQUEST,
					PAGES.PSP_PAGE_SUCCESS,
					PAGES.PSP_PAGE_FAILURE,
				],
				endpoint: `/page/generic/${page}`,
				meta: {
					storageType,
					page,
					baseURL: get(app, 'baseUrl', ''),
				},
			},
		});
	};
}

export function getMovingTruckPage(queryParams) {
	return (dispatch, getState) => {
		const { user } = getState();
		const query = {
			contentPass: true,
		};

		if (queryParams.location) {
			query.location = queryParams.location;
		} else {
			query.ip = user.user_ip;
		}

		return dispatch({
			[API]: {
				types: [
					PAGES.MOVING_TRUCK_PAGE_REQUEST,
					PAGES.MOVING_TRUCK_PAGE_SUCCESS,
					PAGES.MOVING_TRUCK_PAGE_FAILURE,
				],
				endpoint: '/page/movingTrucks',
				query,
				adapter: formatTruckResponse,
				schema: {
					truckResults: searchSchema,
					penskeResults: searchSchema,
				},
			},
		});
	};
}

export function getMovingTruckCityLandingPage(params = {}) {
	return {
		[API]: {
			types: [
				PAGES.MOVING_TRUCK_CLP_REQUEST,
				PAGES.MOVING_TRUCK_CLP_SUCCESS,
				PAGES.MOVING_TRUCK_CLP_FAILURE,
			],
			endpoint: '/page/movingTrucks',
			query: {
				city: params.city,
				state: params.state,
				location: `${params.city} ${params.state}`,
				movingTruck: true,
			},
			adapter: formatTruckResponse,
			schema: {
				truckResults: searchSchema,
				penskeResults: searchSchema,
			},
		},
	};
}

export function getPortableStoragePage() {
	return (dispatch, getState) => {
		const { app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.PORTABLE_STORAGE_PAGE_REQUEST,
					PAGES.PORTABLE_STORAGE_PAGE_SUCCESS,
					PAGES.PORTABLE_STORAGE_PAGE_FAILURE,
				],
				endpoint: '/page/generic/portableStorage',
				meta: {
					baseURL: get(app, 'baseUrl', ''),
				},
			},
		});
	};
}

export function getPortableStorageCityLandingPage(city, state) {
	return (dispatch, getState) => {
		const { app } = getState();
		const query = { city, state };

		return dispatch({
			[API]: {
				types: [
					PAGES.PORTABLE_STORAGE_CLP_REQUEST,
					PAGES.PORTABLE_STORAGE_CLP_SUCCESS,
					PAGES.PORTABLE_STORAGE_CLP_FAILURE,
				],
				endpoint: '/page/portableStorageLanding',
				query,
				meta: {
					baseURL: get(app, 'baseUrl', ''),
				},
			},
		});
	};
}

export function getMoveInConfirmStatusPage(confirmationCode, moveInStatus) {
	return {
		[API]: {
			types: [
				PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_REQUEST,
				PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS,
				PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_FAILURE,
			],
			endpoint: `/page/booking/${confirmationCode}/${moveInStatus}`,
			schema: bookingConfirmationSchema,
		},
	};
}

export function getStorageDeals() {
	return (dispatch, getState) => {
		const { user, app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.STORAGE_DEALS_PAGE_REQUEST,
					PAGES.STORAGE_DEALS_PAGE_SUCCESS,
					PAGES.STORAGE_DEALS_PAGE_FAILURE,
				],
				endpoint: '/page/storageDealsLanding',
				query: {
					listingsPerPage: 4,
					searchType: 'storage-only',
					ip: get(user, 'user_ip'),
				},
				meta: {
					page: 'storage',
					baseURL: get(app, 'baseUrl', ''),
				},
				adapter: formatSearchFacilities,
				schema: searchResponseSchema,
			},
		});
	};
}

export function getPaidTemplatePage(queryParams, url) {
	// If we need to select a differnt param based on PPC ad, we can update the 'utm_term' to the correct param
	const { utm_term: keyword, location } = queryParams; // Used if url has queryParams attached

	// verify keyword to confirm valid type, or set to default, handles case sensitivity
	const storageType = PaidLandingTypes[keyword] || PPC_TYPE_STORAGE;

	// If location is present, use location service from SFAPIGW to validate, else load generic page meta
	// meta is handled by reducer for PAID_LANDING_TEMPLATE_SUCCESS
	const endpoint = location ? '/page/searchLanding' : `/page/generic/${storageType}`
	const query = location ? { location, paidLanding: true, storageType } : { storageType }

	return (dispatch, getState) => {
		const { app } = getState();
		return dispatch({
			[API]: {
				types: [
					PAGES.PAID_LANDING_TEMPLATE_REQUEST,
					PAGES.PAID_LANDING_TEMPLATE_SUCCESS,
					PAGES.PAID_LANDING_TEMPLATE_FAILURE,
				],
				endpoint,
				meta: {
					storageType,
					location, 
					url,
					page: `paid-${storageType}-landing`,
					baseURL: get(app, 'baseUrl', ''),
				},
				query
			},
		});
	};
}
