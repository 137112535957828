import { createSelector } from 'reselect';

export default createSelector(
	({ experiments }) => experiments.bucket,
	({ experiments }) => experiments.forcedBucket,
	(bucket, forcedBucket) => ({
		bucket,
		forcedBucket
	})
);
