import { schema } from 'normalizr';
import { pick } from 'lodash';
import facilitySchema from './facilities';
import unitSchema from './units';

export const bookingSchema = new schema.Entity(
	'bookings',
	{
		facility: facilitySchema,
		unit: unitSchema
	},
	{
		idAttribute: 'confirmationCode',

		// Limit the data sent back to the client
		// YOU MUST ALSO UPDATE THE DUPLICATE SERVER SCHEMA IN /server/lib/schema/booking.js
		processStrategy: (booking) => pick(booking, [
			'bookingState',
			'canceled',
			'confirmationCode',
			'facility',
			'unit',
			'moveIn',
			'onlineMoveInUrl',
			'hasOnlineMoveIns',
			'account',
			'customer',
			'bookedFacility',
			'bookedUnit',
			'npv',
			'timestamp',
			'omiFlowEnabled',
			'omiCompleted',
			'omiAttributes',
			'omiUnitId'
		])
	}
);

export const bookingConfirmationSchema = {
	facility: facilitySchema,
	unit: unitSchema,
	booking: bookingSchema
};
