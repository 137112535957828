import React from 'react';
import PropTypes from 'prop-types';

import { IconText } from 'components/core/IconText';
import Info from 'assets/images/icons/info.svg';

export default function SuccessMessage({ successMessage }) {
	return (
		<IconText
			type="urgent"
			className="success-message"
			src={Info}
		>
			<span
				dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
					__html: successMessage || 'Success!'
				}}
			/>
		</IconText>
	);
}

SuccessMessage.propTypes = {
	successMessage: PropTypes.string
};
