import { compose, withHandlers } from 'recompose';
import Segment, { SegmentConfig } from '@sparefoot/segment-react';
import getSegmentContext from '../context/getSegmentContext';

export default compose(
	getSegmentContext,
	withHandlers({
		handleOmiHeroVisible: (props) => (segmentEventProps) => {
			let omiLabel = 'omi hero impression';
			let omiEvent = 'omi_hero_impression';


			const segmentProperties = {
				category: 'Omi hero rendered',
				...segmentEventProps
			};
			const segmentProps = SegmentConfig.buildTrackingProps(
				props.segmentConfig,
				{ segmentLabel: omiLabel, segmentProperties }
			);

			Segment.track(omiEvent, segmentProps);
		}
	})
);
