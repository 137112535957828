import { createAction } from 'redux-actions';
import Segment from '@sparefoot/segment-react';

export const APP = {
	SET: 'SFDC/APP/SET',
	INITIALIZE: 'SFDC/APP/INITIALIZE',
	READY: 'SFDC/APP/READY',
	LOG_IMPRESSION_REQUEST: 'SSDC/APP/LOG_IMPRESSION_REQUEST',
	LOG_IMPRESSION_SUCCESS: 'SSDC/APP/LOG_IMPRESSION_SUCCESS',
	LOG_IMPRESSION_FAILURE: 'SSDC/APP/LOG_IMPRESSION_FAILURE'
};

export const setApp = createAction(APP.SET, (app) => ({ app }));
export const initialize = createAction(APP.INITIALIZE);
export const ready = createAction(APP.READY);

export const initializeApp = () => (dispatch, getState) => {
	Segment.identify(getState().user.visitId);
	dispatch(initialize());
};

export const appReady = () => (dispatch) => {
	dispatch(ready());
};
