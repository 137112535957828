import React from 'react';
import PropTypes from 'prop-types';

import { CoreCalendar } from 'components/core/library/calendar';
import CalendarHeader from './CalendarHeader';
import Day from './Day';

import './calendar.scss';

export default function Calendar({
	allValid,
	headerComponent,
	dayComponent,
	headerSegmentLabel,
	className,
	today,
	closures,
	calendarRef,
	validator,
	selectedDate,
	segmentConfig,
	reservationWindow,
	onMouseUp,
	onMouseDown,
	onFocus,
	onDateClick,
	onCloseClick,
	onChange,
	name,
	label,
	id
}) {
	return (
		<CoreCalendar
			headerComponent={headerComponent}
			dayComponent={dayComponent}
			headerSegmentLabel={headerSegmentLabel}
			className={className}
			today={today}
			closures={closures}
			calendarRef={calendarRef}
			validator={validator}
			selectedDate={selectedDate}
			segmentConfig={segmentConfig}
			reservationWindow={reservationWindow}
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}
			onFocus={onFocus}
			onDateClick={onDateClick}
			onCloseClick={onCloseClick}
			onChange={onChange}
			name={name}
			label={label}
			id={id}
			allValid={allValid}
		/>
	);
}

Calendar.defaultProps = {
	today: new Date(),
	closures: [],
	calendarRef: () => {},
	headerComponent: CalendarHeader,
	dayComponent: Day,
	headerSegmentLabel: 'moveindate controls'
};

Calendar.propTypes = {
	headerComponent: PropTypes.func,
	dayComponent: PropTypes.func,
	headerSegmentLabel: PropTypes.string,
	className: PropTypes.string,
	today: PropTypes.instanceOf(Date),
	closures: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
	reservationWindow: PropTypes.instanceOf(Date),
	calendarRef: PropTypes.func,
	validator: PropTypes.func,
	selectedDate: PropTypes.instanceOf(Date),
	segmentConfig: PropTypes.object,
	onMouseUp: PropTypes.func,
	onMouseDown: PropTypes.func,
	onFocus: PropTypes.func,
	onDateClick: PropTypes.func,
	onCloseClick: PropTypes.func,
	onChange: PropTypes.func,
	name: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	allValid: PropTypes.bool
};
